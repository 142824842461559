.panel {
    background-color: var(--card-background-base);
}
.text-hide {
    font-family: serif;
    font-size: unset;
    line-height: unset;
}
.container-fluid {
    padding-right: 20px;
    padding-left: 20px;
}
.radio,
.checkbox {
  padding-left: 0px !important;
}
@media (min-width: 768px) {
    .form-horizontal .form-group-sm .control-label {
      font-size: 14px;
    }
}
.navbar-collapse {
    border-top: unset;
    -webkit-box-shadow: unset;
            box-shadow: unset;
}

.navbar .dropdown-menu li>a:hover, .dropdown-menu li a:hover {
  color: var(--link-text-hover);
  background-color: var(--gray-200);
}

.icon-primary {
  color: var(--link-icon-primary);
  &:hover {
    color: var(--link-icon-hover);
  }
}

@media (max-width: 979px) {
  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
}
@media (min-width: 1238px) and (max-width: 1470px) {
  .navbar .navbar-right ul.navbar-menus {
    font-size: 12px;
  }
}

/* to collapse the navbar at a custom breakpoint */
@media (max-width: 1090px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
    display: inline-flex;
  }
  .navbar-toggle {
    display: block;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(var(--white),0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}

.text-danger {
  color: var(--red-400);
}
.text-warning {
  color: #FACA15; /* OPL override */
}
.text-success {
  color: var(--green-400);
}
.text-info {
  color: var(--blue-400);
}

hr {
  border-top: 1px solid var(--grid-stroke-primary);
}
