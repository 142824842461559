@-webkit-keyframes uil-default-anim {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes uil-default-anim {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.uil-default-css > div:nth-of-type(1) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(2) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.4166666666666667s;
    animation-delay: -0.4166666666666667s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(3) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.33333333333333337s;
    animation-delay: -0.33333333333333337s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(4) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(5) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.16666666666666669s;
    animation-delay: -0.16666666666666669s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(6) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.08333333333333331s;
    animation-delay: -0.08333333333333331s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(7) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(8) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.08333333333333337s;
    animation-delay: 0.08333333333333337s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(9) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.16666666666666663s;
    animation-delay: 0.16666666666666663s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(10) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(11) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.33333333333333337s;
    animation-delay: 0.33333333333333337s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

.uil-default-css > div:nth-of-type(12) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.41666666666666663s;
    animation-delay: 0.41666666666666663s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 100px;
    height: 100px;
}

div.spinner div {
    top: 80px;
    left: 93px;
    width: 14px;
    height: 40px;
    background: var(--progress-progressdot-background-active);
    border-radius: 10px;
    position: absolute;
}

.hide-spinner {
    display: none;
}

.show-spinner {
    display: '';
}


/* 404 & not authorized page styles */

.body-404 {
    font-size: 1.5em;
    line-height: 1.6;
    font-weight: 400;
    color: var(--text-primary);
    background-color: var(--app-background);
    padding: 0;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
}

.container-404:after, .row-404:after {
    content: "";
    display: table;
    clear: both;
}


/* 404 Style */

.row-404 {
    position: fixed;
    top: 50%;
    left: 35%;
    margin-top: -100px;
    margin-left: -100px;
}

.container-404 {
    position: relative;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.title-404 {
    text-align: center;
}

.sub-heading-404 {
    font-size: 1.5rem;
    text-align: center;
    color: var(--yellow-600);
    margin: 10px 0;
}

hr.hr-style-404 {
    border: 0;
    height: 1px;
    width: 150px;
    background-color: var(--gray-500);
}

.redirect-style-404 {
    color: var(--text-primary);
    text-align: center;
}

.priv-check {
    background: transparent url(../img/role-toggle.png) no-repeat;
    padding-left: 15px;
    margin-left: 5px;
}

.priv-uncheck {
    background: transparent url(../img/role-toggle.png) -31px 0 no-repeat;
    padding-left: 15px;
    margin-left: 5px;
}
