.p-datatable  {
  & .p-sortable-column:not(.p-highlight):hover {
    background: var(--gray-200);
    color: var(--table-text-secondary);
  }
  & .p-sortable-column.p-highlight, & .p-sortable-column.p-highlight:hover {
    background: var(--gray-300);
    color: var(--blue-500);
  }

  & .p-datatable-header {
    border-color: var(--gray-200);
  }
  & .p-datatable-footer {
    background: var(--table-background-header);
    color: var(--table-text-secondary);
    border-color: var(--table-stroke-primary);
    padding: 0.75rem 1rem;
  }
}

.notes-fixed-height {
  & .p-datatable {
    max-height: 500px;
  }
}
