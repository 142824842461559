@import '@oat/stylesheets/src/oat-components.scss';

/*
OPL hacks.  These exist because Eclipse styling (custom or from 3rd party libs like Bootstrap)
conflict with OPL styling.  The styles in this section should be considered temporary and we should
endeavor to remove them as quickly as possible.
 */
.oat-page-header-container {
  padding: 16px;
}

oat-ag-view-item {
  box-sizing: content-box; /* custom Bootstrap sets border-box */
}
oat-ag-views oat-ag-view-item #itemContainer #controls span {
  box-sizing: content-box;
}

i {
  font-size: 1em;
}
input[type=text]:focus, input[type=number]:focus, input[type=password]:focus {
  background-color: inherit;
}

.form-group.is-focused .form-control {
  outline: 0;
  box-shadow: none;
  transition-duration: .3s;
  border-bottom: 1px solid #01acef;
}

select {
  background: var(--list-background-base);
  & option {
    background-color: transparent;
  }
}

select[multiple] {
  background: var(--list-background-base);
  & option {
    background-color: transparent;
    &:checked {
      background-color: var(--list-background-hover);
    }
  }
}

.ag-theme-alpine {
  --ag-alpine-active-color: var(--grid-text-active);
  --ag-foreground-color: var(--grid-text-primary);
  --ag-background-color: var(--grid-background-base);
  --ag-control-panel-background-color: var(--grid-background-base);
  --ag-border-color: var(--grid-stroke-primary);
  --ag-secondary-border-color: var(--grid-stroke-secondary);
  --ag-input-border-color: var(--textfield-stroke-primary);
  --ag-input-disabled-background-color: var(--textfield-background-disabled);
  --ag-header-background-color: var(--grid-background-header);

  & .ag-overlay-loading-center {
    color: var(--gray-700);
  }
}

.ag-theme-alpine .ag-cell-value input {
  background-color: var(--textfield-background-base);
  width: 100%;
}

.ag-theme-alpine .ag-row-group {
  font-weight: inherit; /* tactical rows show bolded due to our dynamic row grouping */
}
.ag-theme-alpine .ag-tool-panel-wrapper {
  min-width: 300px; /* make tool panels wider for custom controls */
}

.ag-theme-alpine {
  height: calc(100vh - 173px);
  min-height: initial !important; /* OPL min-height is too tall for some grids in Eclipse */
}

.ag-theme-alpine .ag-row {
  border-top: var(--ag-borders-row) var(--ag-row-border-color);
}

.oat-grid-with-header .ag-theme-alpine {
  height: calc(100vh - 192px);
}

.modal-xl .ag-theme-alpine {
  height: calc(55vh);
}

.ag-theme-alpine.grid-hw-3 {
  height: 300px;
}
.ag-theme-alpine.gird-hw-4 {
  height: 400px;
}
.ag-theme-alpine.gird-hw-5 {
  height: 500px;
}

a {
  color: var(--link-text-primary);
  text-decoration: none;
  &:hover, &:focus {
    color: var(--link-text-hover);
    outline: none;
  }
}
