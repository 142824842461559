@charset "utf-8";

/* CSS Document */


/*
 * Base structure
 */


/* Move down content because we have a fixed navbar that is 50px tall */

.btn {
    font-weight: 600
}


/* Hide for mobile, show later */


.navbar-search input {
    background: var(--textfield-background-base) url("../img/search-icon.png") no-repeat scroll 5px center !important;
    font-size: 14px;
    font-weight: 300;
    outline: medium none;
    padding: 1px 13px 1px 25px;
    width: 100%;
}

.m-t-5 {
    margin-top: 5px;
}

span.dropdown-search {
    background: url(../img/dd-icon.png);
    height: 31px;
    width: 31px;
    float: left;
    position: absolute;
    right: 0;
    top: 15px;
}

.navbar-search::after {
    border-left: 1px solid var(--navigation-stroke-primary);
    content: "";
    height: 32px;
    position: absolute;
    left: 0;
    top: 15px;
}

.page-header-bar .dropdown-menu {
    right: 0;
    left: inherit;
}

/*************Buttons**************/

.btn {
    padding: 8px 15px;
}

/*
 * Main content
 */

.import, .warnings, .errors {
    color: var(--text-secondary);
    float: left;
    margin-right: 0.6%;
    min-height: 65px;
    padding: 10px 0 0;
    text-align: center;
}

.import {
    background: var(--grid-background-base);
    width: 48.8%;
}

.warnings {
    background: var(--grid-background-base);
    width: 25%;
}

.errors {
    background: var(--grid-background-base);
    width: 25%;
    margin-right: 0px;
}

.errors a {
    color: var(--alert-text-error-link);
    font-size: 18px;
}

.warnings a {
    color: var(--alert-text-error-link);
    font-size: 18px;
}

.line-spacer {
    margin-top: 5px;
}

.graph-container {
    margin-top: 20px;
    display: inline-block;
    width: 100%;
}

.graph img {
    display: table;
    margin: auto;
    text-align: center;
}

.block-spacer {
    margin: 20px 0;
}

/*********************Query Builder**************/

.panel-group {
    margin: 0 -15px;
}

.panel-group .fas, .panel-group .far {
    font-size: 12px;
}

.panel-heading, .panel-group .panel {
    border-radius: 0;
    border: none;
}

.panel-default>.panel-heading {
    background-color: var(--table-background-header);
    border: none;
    color: var(--table-text-secondary);
}

.panel-heading {
    padding: 7px 8px;
}

h4.panel-title {
    font-weight: bold;
    font-size: 14px;
}

.panel-heading.clicked, .panel-heading:focus {
    background: var(--table-background-header);
    color: var(--table-text-secondary);
}

label.label {
    margin-right: 10px;
    color: var(--textfield-text-label);
    font-size: 14px;
    padding-left: 0;
}

.runquery {
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
}

.runquery textarea {
  resize: none;
}

.radius-none {
    border-radius: 0;
}

.dd {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    vertical-align: top;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    line-height: 1.42857143;
    color: var(--select-text-primary);
    background-color: var(--select-background-base);
    background-image: none;
    border: 1px solid var(--select-stroke-primary);
    border-radius: 0;
    -webkit-box-shadow: var(--elevation-200);
    box-shadow: var(--elevation-200);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.tags {
    width: 100%;
}

.tags a {
    padding: 3px 5px;
    background: var(--grid-background-base);
    color: var(--grid-text-primary);
    border: solid 1px var(--grid-stroke-primary);
    font-size: 14px;
    display: inline-block;
    margin-right: 10px;
}

.tags a span {}

.tags a:hover, .tags a.active {
    background: var(--button-background-primary);
    color: var(--button-text-primary);
    border: solid 1px var(--buttongroup-stroke-primary);
}

.panel-group .dd {
    margin-bottom: 10px;
    width: 100%;
}

.panel-group .panel+.panel {
    margin-top: 15px;
}

.submenu {
    list-style: outside none none;
    margin: 0 0 0;
    padding: 0;
    position: relative;
    width: 100%;
    display: none;
}

.submenu li a {
    text-align: left;
    display: inline-block;
    color: var(--text-secondary);
    padding: 5px 10px;
    text-indent: 10px;
}

.open .submenu {
    display: block;
}

.nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
    border-color: var(--navigation-stroke-primary)
}

h4.modal-title {
  color: var(--modal-text-secondary);
}
.modal-header {
  background-color: var(--modal-background-base);
}
.modal-body {
  background-color: var(--modal-background-base);
}
.modal-content {
  background-color: var(--modal-background-base);
}

.main .page-header {
    margin-top: 0;
}

.placeholder {
    margin-bottom: 20px;
}

.placeholder img {
    display: inline-block;
    border-radius: 50%;
}

.tom-dashboard-main {
    padding: 0% 5px;
}

.tom-dashboard-main .panel {
    margin-bottom: 10px;
}

.tom-dashboard-main .col-md-6, .tom-dashboard-main .col-md-4 {
    padding: 0px 5px;
}

.text-50 span {
    font-size: 20px;
    line-height: 22px;
    padding: 0 10px;
    position: relative;
    top: -11px;
}

.controls {
    display: block;
    padding: 6px 10px;
    background: rgba(255, 255, 255, .3);
    margin-left: -12px;
    margin-right: -12px;
    overflow: hidden;
    min-height: 65px;
}

.order-buy-sell {
    background-color: rgba(255, 255, 255, 0.25);
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 10px;
}

.order-buy-sell a {
    color: var(--text-primary);
    text-align: center;
    font-size: 16px;
    display: inline-block;
    padding: 5px 15px;
    line-height: 20px;
    border-left: 1px solid rgba(var(--grid-stroke-secondary), .3);
    cursor: text;
    text-decoration: none;
}

.date-bar {
    border-bottom: solid 1px #444440;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.pointer-cursor {
    cursor: pointer;
}

.progress-bar {
    background-color: var(--progress-progressbar-background-primary);
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
    color: var(--progress-progressbar-text-primary);
    float: left;
    font-size: 12px;
    height: 100%;
    line-height: 20px;
    text-align: center;
    transition: width 0.6s ease 0s;
    width: 0;
}

.sr-only {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.actionBtn > li {
    list-style-type: none;
    display: block;
    float: left;
}

.actionBtn > li > a {
    line-height: 25px;
}

.actionBtn > li .dropdown-submenu > .dropdown-menu label {
    line-height: 25px;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
}

.actionBtn > li.open>.dropdown-toggle.btn.btn-primary {
    background-color: var(--button-background-primary);
    border-radius: 0;
}

.tree-table-grid .actionBtn > a.action-drop {
    padding: 0px 0px;
}

.actionBtn > li > .dropdown-menu > li.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    font-size: 12px;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: var(--select-stroke-primary);
    margin-top: 5px;
    margin-right: -10px;
}

.form-group {
    padding-bottom: 7px;
    margin: 0px 0 0 0;
}

.table {
    font-family: 'Inter', 'Open Sans', sans-serif;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    border-bottom: solid 1px var(--table-stroke-primary);
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    padding: 10px 5px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid var(--table-stroke-primary);
    color: var(--table-background-base);
    font-weight: 600;
    white-space: nowrap;
}

.table>thead>tr>th {
    font-size: 14px;
    font-weight: normal;
    color: var(--table-text-secondary);
    border-top: 2px var(--table-stroke-primary);
}

.table .form-group {
    margin: 0;
    padding: 0;
}

.table-hover>tbody>tr:hover {
    background-color: var(--table-background-hover);
}

.blue {
    color: var(--blue-400);
}

.table .form-group .form-control {
    margin: 0;
    padding: 0;
}

.button-container button:hover .blue {
    color: var(--button-text-primary);
}

.checkbox label, .radio label, label, .form-group .checkbox label, .form-group .radio label, .form-group label {
    color: var(--textfield-text-label);
}

label.primar-label {
    font-size: 16px;
    font-weight: bold;
    color: var(--textfield-text-label);
    width: 100%;
}

.form-container {
    margin: 0 0 20px 20px;
    padding-bottom: 20px;
}

.radio label span {
    top: 4px;
}

.form-group.tabs-data {
    margin: 20px 0 0 0;
}

.m-l-15 {
    margin-left: 15px;
}

.navbar-menus .badge {
    font-family: 'Inter', 'Open Sans', sans-serif;
    font-weight: 300;
    left: 5px;
    padding: 2px 5px;
    position: absolute;
    top: -5px;
}

.navbar-menus .badge.badge-default {
    background-color: var(--badge-background-error);
    color: var(--badge-text-primary)
}

.navbar .dropdown-menu li>a, .navbar.navbar-default .dropdown-menu li>a {
    font-size: 13px;
    background-color: var(--link-text-primary);
}

input.disabled, .btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none;
}

p.active {
    background: var(--green-400);
}

.role-spacer {
    min-height: 80px;
}


/*******role detail page****/

.rowhd {
    background: var(--table-stroke-primary);
}

.premissionTable.table tr.rowhd>td {
    padding: 5px;
}

.premissionTable.table tr>td, .premissionTable.table tr>th {
    padding: 8px;
    vertical-align: middle;
    font-weight: normal;
}

.premissionTable td a {
    width: 15px;
    height: 15px;
    display: inline-block;
}

.button-footer {
  text-align: right;
  border: 1px solid var(--menu-stroke-primary);
  box-shadow: var(--elevation-400);
  padding: 10px;
  margin: 0;
  background: var(--menu-background-base);
  position: fixed;
  bottom: 0;
  width: 78.5%;
  z-index: 99;
}
.sm-wrapped-page .button-footer {
  width: 71.5% /* fixed button footer should move over for Glia chat window */
}

.sm-wrapped-page .admin-page-right .button-footer {
  width: 58.5%; /* fixed button footer should move over for Glia chat window */
}

#querybuilder .button-footer {
  left: 259px;
  width: 85%;
}

.expanded-content #querybuilder .button-footer {
  left: 74px;
  width: 95%;
}
/********Main dashboard*******/

.conditions .form-group {
    margin-top: 0;
}

.conditions .dropdown-plug {
    float: left;
    margin-right: 10px;
    margin-top: -7px;
    width: 100px;
}


/*************/

@media all and (min-width: 1000px) and (max-width: 1280px) {
    .import {
        width: 46%;
    }
    .aum-box {
        width: 98%;
    }
    .btn {
        padding: 8px 11px;
    }
}

.dropdown-menu.submenu-list li a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: var(--list-text-primary);
    white-space: nowrap;
}

.autocomplete-block input {
    border: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#01acef), to(#01acef)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#01acef, #01acef), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#01acef, #01acef), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#01acef, #01acef), linear-gradient(#D2D2D2, #D2D2D2);
    -webkit-background-size: 0 2px, 100% 1px;
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center -webkit-calc(100% - 1px);
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: background 0s ease-out;
    -o-transition: background 0s ease-out;
    transition: background 0s ease-out;
    float: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
}

.autocomplete-block input:focus {
    border: 0;
    outline: none;
    box-shadow: none;
}

.ui-multiselect.ui-state-default {
    border: 0px;
    border-bottom: 1px solid #d6d6d6;
    width: 100%;
}

.spinner-loading {
    position: fixed;
    top: 0px;
    z-index: 10999;
}

.spinner-loading-icon {
    width: 35px;
    height: 35px;
    margin: 0px auto;
    /*border-radius: 120px;*/
    position: relative;
    /*top: 50%;*/
}

.spinner-loading-icon .uil-default-css {
    position: relative;
    background: none;
    width: 17px !important;
    height: 12px!important;
}

.margin-top-minus {
    margin-top: -15px;
}

.margin-top-minus-1x {
    margin-top: -10px;
}

.admin-page-right {
  position: relative;
  top: 94px;
}

.category-fieldset {
    width: 100%;
    border-radius: 3px;
    margin: 0px 0px;
    padding: 0px 10px;
}

.category-form .form-group {
    margin: 10px 0px;
    padding: 0px;
}

.category-form .form-control {
    margin: 0px;
    padding: 0px;
    height: 30px;
}

.btn-reset {
    display: inline-block;
    float: left;
    margin-left: 5px;
    margin-top: 8px;
}

.securityDataGrid .btn-reset {
    position: absolute;
    right: 60px;
    top: 15px;
    color: var(--link-text-primary) !important;
}

.field-control {
    float: left;
    width: 40%;
    margin-left: 10px;
}

.grid-control {
    width: 93%;
    float: left;
}

.preferences-category {
  background: var(--table-background-base);
  box-shadow: var(--elevation-400);
  padding: 10px 0px;
  margin: 10px 0px;
}

.preferences-category h4 {
    padding: 0px 30px;
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 400;
}

.preferences-category .col-md-1, .preferences-category .col-md-7, .preferences-category .col-md-4 {
    padding-right: 5px;
/* Changes done for Mozilla overlap issue */
    /* padding-left: 5px; */


}

.preferences-category .col-md-7 .form-control, .preferences-category .col-md-7 .list-drag-group {
    width: 100%;
}

.preferences-category .col-md-7 .form-group .form-control {
    width: inherit;
}

.input-group .btn-reset {
    right: -35px;
}

.btn-prnt-inhrt {
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    color: var(--yellow-500);
    position: relative;
    top: 5px;
    right: -10px;
}

.btn-prnt-inhrt-com-str {
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    color: var(--yellow-500);
    position: absolute;
    right: 250px;
    top: 13px;
}

.btn-prnt-inhrt-com-secpref-popup {
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    color: var(--yellow-500) !important;
    position: absolute;
    right: 30px;
    top: 20px;
}

.category-form .form-group .control-label {
    padding-top: 10px;
    margin: 0px;
    font-size: 14px;
}

.tabel-bg-white {
    background: var(--table-background-base);
}

.showall-tab {
    overflow: hidden;
}

.tab-header .error {
    top: 36px;
    font-size: 13px;
}

p-calendar ~ .error, select ~ .error, input ~ error {
  top: 66px;
}

.validationerror {
    color: var(--textfield-text-error);
}

.ui-dialog .ui-dialog-titlebar {
    padding: 0em 0em 0em 0em !important;
    margin: 10px 20px 5px 20px !important;
}

/* for calendars with dropdowns for months/years */
.input-calendar .ui-datepicker .ui-datepicker-title {
    color: var(--text-primary) !important;
    display: inline-flex;
}

.ui-state-active {
  background-color: var(--select-background-base) !important;
}

.error-label {
    color: var(--textfield-text-error);
    font-weight: bold;
    margin-top: 23px;
}

.grid-block {
    width: 100%;
    height: 350px;
}

.actions-block {
    margin: -48px 0px 28px;
}

.securityError {
    color: var(--textfield-text-error);
}

.error-msg .securityError {
    position: absolute;
}

.gridColorPicker {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    float: left;
    margin-right: 5px;
    margin-top: 4px;
}

.submitButton {
    background: none;
    border: none;
    font-size: 32px;
    float: left;
    margin: 10px 0 0 0;
}

a.addAsset {
    font-size: 32px;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.21);
    float: left;
    margin: 26px 0 0 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    line-height: 30px;
}

.pop-footer {
    display: inline-block;
    width: 100%;
    margin: 217px 0 0 0;
}

.securityLeftNav {
    font-size: 27px;
}

.securityForm h4 {
    font-weight: 300;
    margin: 35px 0 0 0;
}

.symbol-text {
    position: relative;
}

.symbol-text span {
    position: absolute;
    left: -15px;
    top: 8px;
    font-size: 17px;
}

.btn-mr-0 {
    margin: 0px 0px 10px 0px !important;
}

/*--- 12/07/2016 Css---*/

.product-tabpane-searchArea {
    background: var(--gray-100);
    padding: 10px;
}

.divider {
    height: 1px;
    width: 100%;
}

.ProductTickerDetail-header {
    overflow: hidden;
}

.ProductTickerDetail-title {
    font-size: 20px;
}

.ProductTickerDetail-sections button.btn-edit {
    margin: 0px 10px 0px 0px;
    padding: 3px 6px;
}

.ProductTickerDetail-sections {
    padding: 10px 0px 30px;
}

.ProductTickerDetail-sections .col-lg-3 {
    padding-left: 2px;
    padding-right: 2px;
}

.ProductTickerDetail-sections .checkbox {
    margin: 14px 0px;
}

.ProductTickerDetail-sections .checkbox .checkbox-material {
    margin-right: 10px;
}

.td-btn {
    margin: 0px;
    padding: 6px;
}

.ProductTickerDetail-sections table td {
    padding: 5px;
}

.ProductTickerDetail-sections .table>tbody>tr:last-child>td {
    border-bottom-color: transparent;
}

.btn-help i {
    position: absolute;
    top: 10px;
    margin-left: 2px;
}

.product-tabpane-searchArea .form-control {
    margin: 0px 10px;
    padding: 0px;
}

.margin-top-025 {
    margin-top: 0.20em;
}

.margin-top-05 {
    margin-top: 0.5em;
}

.margin-top-10 {
    margin-top: 1.0em;
}

.margin-top-15 {
    margin-top: 1.5em;
}

.margin-top-18 {
    margin-top: 1.8em;
}

.margin-top-20 {
    margin-top: 2.0em;
}

.margin-top-25 {
    margin-top: 2.5em;
}

.margin-top-30 {
    margin-top: 3.0em;
}

.margin-top-40 {
    margin-top: 4.0em;
}

.margin-top-50 {
    margin-top: 5.0em;
}

.margin-top-70 {
  margin-top: 7.0em;
}

.margin-top-15x {
    margin-top: 15.0em;
}

.margin-left-05 {
    margin-left: 0.5em;
}

.margin-left-10 {
    margin-left: 1em;
}

.marging-bottom-2x {
    margin-bottom: 20px;
}

.marging-bottom-3x {
    margin-bottom: 30px;
}

.marging-bottom-4x {
    margin-bottom: 40px;
}

.marging-bottom-5x {
    margin-bottom: 50px;
}

.marging-bottom-6x {
    margin-bottom: 60px;
}

.marging-top-2x {
    margin-top: 20px;
}

.marging-top-3x {
    margin-top: 30px;
}

.marging-top-4x {
    margin-top: 40px;
}

.marging-top-5x {
    margin-top: 50px;
}

.marging-top-6x {
    margin-top: 60px;
}

.marging-top-10x {
    margin-top: 100px;
}

.marging-left-2x {
    margin-left: 20px;
}

.marging-left-3x {
    margin-left: 30px;
}

.marging-left-4x {
    margin-left: 40px;
}

.marging-left-5x {
    margin-left: 50px;
}

.add-plus-btn {
    margin: 0px 20px;
    padding: 0px;
    font-size: 16px;
}

a.add-plus-btn:hover, a.add-plus-btn:focus {
    color: var(--button-background-primary-hover) !important;
    background-color: transparent !important;
}

.text-warning>td, .text-warning>.add-plus-btn {
    color: var(--alert-text-warning-message) !important;
}

.text-info>td {
    color: var(--alert-text-info-message) !important;
}

tr.collapse.in>td {
    padding: 25px;
}

.text-white {
    color: var(--text-primary) !important;
}

.portfolio-detail {
    background: var(--grid-background-base);
    color: var(--text-primary);
}

.checkbox-ali {
    min-height: 30px;
    padding-top: 8px;
}

.searchsecurity {
    float: left;
}

i.addEquivalenceClass {
    font-size: 18px;
}

.ag-header-group-cell-label {
    text-align: center;
}

/*-----End Portfolio-Dashboard---*/


/*-----Portfolio Details---*/

.portfoliodetails-search-add {
    width: 100%;
}

.product-search-add .btn {
    padding: 6px 6px;
    margin: 4px 1px;
}

.error-table {
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    width: 100%;
    color: var(--table-text-primary);
}

.error-table-listgroup {
    overflow: auto;
    max-height: 150px;
}

.error-table tr td {
    padding: 10px 8px;
    line-height: 1.1;
    vertical-align: top;
    border-bottom: 1px solid var(--table-stroke-primary);
    font-weight: 400;
    font-size: 14px;
}

.portfolio-aum-info {
    margin: 0px;
    padding: 0px;
}

.portfolio-aum-info .count-box {
    margin: 0px;
    padding: 0px;
}

.portfolio-aum-info .count-box {
    font-size: 18px;
    color: var(--text-primary);
    float: left;
    padding: 0px 7%;
    margin: 10px 0px;
}

.portfolio-aum-info .count-box span {
    font-size: 20px;
    color: var(--text-primary);
    font-weight: 600;
    display: block;
    line-height: 22px;
}

.portfolio-aum-info .count-box small {
    font-size: 12px;
    line-height: 1em;
    display: block;
    margin-top: 0px;
}

.portfolio-aum-info .count-box-right-brd {
    border-right: 1px solid rgba(255, 255, 255, .3);
}

.portfolio-aum-tiles .col-lg-3 {
    margin-right: 2px;
    margin-top: 5px;
}

.portfolio-aum-title {
    margin: 0px;
    padding: 0px;
}

.portfolio-aum-title-clount {
    margin: 0px;
    padding: 0px;
    text-align: right;
    margin-right: 20px;
    /*margin-top: -15px;*/
    position: relative;
}

.portfolio-aum-title-clount h2 {
    font-weight: 600;
    font-size: 31px;
    color: var(--text-primary);
}

.portfolio-aum-title-clount h2 sub {
    font-size: 50%;
}

.portfolio-aum-title-clount h2 .text-danger {
    font-size: 14px;
    position: absolute;
    top: -10px;
    right: -10px
}

.portfolio-aum-info-wrap {
    position: relative;
    margin: 0px 0px 50px 0px;
    overflow: hidden;
}

.portfolio-aum-info .fa {
    font-size: 13px;
    position: absolute;
    top: 8px;
    right: 8px;
}

.portfolio-aum-info .fa-arrow-up {
    color: var(--grid-icon-success);
}

.text-erorr-warning {
    color: var(--grid-icon-warning);
}

.table-responsive-y {
    overflow: hidden;
    min-height: 100px;
    max-height: 200px;
    overflow-y: auto;
    padding: 5px;
}

.portfolio-aum-tiles .col-lg-3 {
    margin-right: 2px;
    margin-top: 5px;
    height: 105px;
}

/*-----End Portfolio Details---*/

.customBtn {
    padding: 5px 20px;
    margin-top: 20px;
}

.linkdisabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.ui-dialog-content .form-control[type="number"]::-webkit-input-placeholder {
    color: var(--textfield-text-placeholder);
    font-size: 14px !important;
}


/* Firefox 4-18 */

.ui-dialog-content .form-control[type="number"]:-moz-placeholder {
    color: var(--textfield-text-placeholder);
    font-size: 14px !important;
}


/* Firefox 19+ */

.ui-dialog-content .form-control[type="number"]::-moz-placeholder {
    color: var(--textfield-text-placeholder);
    font-size: 14px !important;
}


/* IE10+ */

.ui-dialog-content.form-control[type="number"]:-ms-input-placeholder {
    color: var(--textfield-text-placeholder);
    font-size: 14px !important;
}

.preferences-alert {
    bottom: 55px;
    color: var(--grid-icon-success);
    left: -18%;
    margin: auto;
    position: absolute;
    right: 0;
    width: 25%;
}

.custom-alert {
    padding: 8px 15px !important;
}

.list-drag-group {
    margin: 0px;
    padding: 0px;
}

.list-drag-group-item {
    background: var(--gray-100);
    border: 1px solid var(--menu-stroke-primary);
    list-style-type: none;
    margin: 3px 0px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    padding: 5px 5px;
    cursor: move;
}

.list-drag-group-item .fa {
    margin-right: 5px;
}

.grid-height {
    height: 100% !important;
    width: 100%;
}

.grid-height-80 {
    height: 80% !important;
    width: 100%;
}

.grid-height-70 {
    height: 70% !important;
    width: 100%;
}

.grid-height-150 {
    height: 150% !important;
    padding-bottom: 300px !important;
}

.grid-height-230 {
    height: 230% !important;
    padding-bottom: 700px !important;
}

.margin-lr-30 {
    margin-left: -30px;
    margin-right: -30px;
}

.row-grid-height {
    height: 100% !important;
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 1024px) {
    .grid-height {
        height: 80%;
        width: 100%;
    }
}

@media (min-width: 1365px) {
    .grid-height {
        height: 80%;
        width: 100%;
    }
}

.firm-security-setting-header {
    padding: 0px;
}

.account-realized-gain-box {
  padding: 8px;
  margin: 0 0 20px;
}

.account-portfolioAUM-table {
    margin: 0px;
    padding: 0px;
}

.account-portfolioAUM-table>thead>tr>th {
    margin: 0px;
    background: var(--table-background-header);
    font-weight: 700;
    border-bottom: 0px !important;
}

.account-portfolioAUM-table>tbody>tr>td {
    padding: 4px 5px;
    border-bottom: 1px solid #f2f2f2;
    border-top: none !important;
    font-size: 12px;
    font-weight: normal;
}

.account-portfolioAUM-table>tbody>tr>td>span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0px 1.5% 0px 0px;
    margin-bottom: -2px;
}

.account-portfolioAUM-table>tfoot>tr>th {
    margin: 0px;
    padding: 10px 0px;
    font-weight: 700;
    border-bottom: 0px !important;
}

.input-group-addon-symbol {
    position: absolute;
    left: 20px;
    top: 10px;
}

.form-control-addon-symbol {
    position: absolute;
    left: 45px;
    top: 0px;
}

.cat-pref-error {
    color: var(--textfield-text-error);
    position: absolute;
    top: 6px;
    right: -200px;
}

.ui-dialog .ui-dialog-content {
    min-height: 120px;
}

.modelError {
    color: var(--textfield-text-error);
}

.placeholder-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--textfield-text-placeholder) !important;
    font-style: italic;
}

.placeholder-input::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--textfield-text-placeholder) !important;
    font-style: italic;
}

.placeholder-input:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--textfield-text-placeholder) !important;
    font-style: italic;
}

.placeholder-input:-moz-placeholder {
    /* Firefox 18- */
    color: var(--textfield-text-placeholder) !important;
    font-style: italic;
}

.placeholder-select option {
    font-style: normal !important;
}

.empty {
    color: var(--emptystate-text-secondary) !important;
    font-style: italic !important;
    font-weight: 500;
}

.holdings-search-head {
    padding: 10px 20px;
}

.holdings-search-head .form-group {
    padding: 0px;
    margin: 0px;
}

.form-inline .form-group .portfoliodetails-search-add {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.holdings-search-head-dispaly {
    padding: 10px 20px;
}

.fl-right-btn {
    margin: .5em .4em .5em 0;
    cursor: pointer;
    float: right;
}

.global-search .p-autocomplete .p-autocomplete-panel{
    width: max-content !important;
    max-height: 400px !important;
}

@media (min-width: 1024px) {
  .global-search .p-autocomplete .p-autocomplete-panel{
    max-width:1024px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .global-search .p-autocomplete .p-autocomplete-panel{
    max-width:768px;
  }
}

.dialog-error {
    color: var(--textfield-text-error);
    position: relative;
    left: 0px;
    margin: auto;
    right: 0px;
    width: 100%;
}

.dialog-warning {
    color: var(--yellow-500);
    position: relative;
    left: 0px;
    margin: auto;
    right: 0px;
    width: 100%;
}

ul.show-hollding-dropdown {
    margin: 0;
    padding: 0;
}

ul.show-hollding-dropdown li {
    margin: 0 -5px;
    padding: 8px;
    list-style-type: none;
    border-bottom: 1px solid #FFF;
    overflow: hidden;
    position: relative;
}
li:last-child > ul.show-hollding-dropdown li {
  border-bottom: 0;
}

ul.show-hollding-dropdown li span {
    margin-left: 25px;
    display: block;
}


/*Added this class for positioning search items in modelDetails page*/

.searchModels {
    line-height: 0.3em !important;
}

.searchModelSpan {
    position: relative;
    top: -8px;
}

ul.show-hollding-dropdown li .fas,
ul.show-hollding-dropdown li .far {
    position: absolute;
    width: 18px;
}

ul.show-hollding-dropdown li .lm {
    position: absolute;
    width: 18px;
    top: 4px;
}

.padding-0 {
    padding: 0px !important;
}

.portfoliodetails-assign-popup {
    margin-bottom: 5px;
    padding-bottom: 5px;
    width: 100%;
}

.form-control-width-5x {
    width: 50%;
}

.grid-context-link {
    display: block;
    background: rgba(0, 0, 0, .3);
    text-align: left;
}

.tab-header {
  background-color: var(--tab-dashboard-background-primary);
  padding: 0px 20px 0 20px;
}

.tab-header .form-group {
    margin-top: 10px;
}

.tab-header .button-space {
    margin-top: 20px;
}

.label-right-space {
    padding-right: 10px;
}

.account-summary .col-lg-3 {
    height: 105px;
    margin-right: 0;
    margin-top: 5px;
    padding: 0px 1px;
}

.account-summary .count-box {
    color: var(--text-primary);
    float: left;
    font-size: 16px;
    margin: 3px 0;
    padding: 0 4%;
}

.account-summary .panel-body {
    height: 103px;
}

.account-summary .count-box small {
    display: block;
    font-size: 13px;
    line-height: 11px;
}

.account-summary hr {
    border-top: 1px solid #444444;
}

.tile-click {
    cursor: pointer;
}

.label-fontsize {
    font-size: 14px !important;
}

.tabledisabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
}

.navbar-search .search-dropdown {
    position: absolute;
    top: 32px;
    left: 165px;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 15px;
    margin: 2px 0 0;
    background-color: var(--gray-100);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.navbar-search .search-dropdown>span {
    text-transform: uppercase;
}

.select-form-group {
    margin: 0px 13px;
    padding: 0px;
    width: 30%;
    float: left;
}

.select-form-group .space-0 {
    margin: 0px;
    padding: 0px;
}

.select-form-group .form-group {
    margin: 12px 0px;
    padding: 0px;
    display: block;
}

.select-form-group .form-group label {
    color: var(--textfield-text-label);
    font-size: 13px;
    font-weight: bold;
    margin-right: 5px;
}

.select-form-group .form-group select {
    color: var(--select-text-primary);
    font-size: 14px;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid var(--select-stroke-primary);
    padding: 0px 50px 0px 2px;
}

.select-form-group .form-group select:focus {
    border-bottom-color: var(--select-stroke-primary);
}

.product-tabpane-searchArea {
    background: var(--grid-background-base);
}

.product-tabpane-searchArea .form-group {
    margin: 0px;
    padding: 0px;
}

.product-tabpane-searchArea .form-control {
    margin: 0px 10px;
    padding: 0px;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.simple-notification.success {
    background-color: transparent !important;
}

.toaster {
    background: var(--toast-background-base);
    padding: 10px;
    border: 1px solid var(--toast-stroke-primary);
    border-radius: 3px;
    margin: 0px;
}

.toaster p.toaster-body {
    white-space: nowrap;
    width: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.toaster i.toaster-type {
    font-size: 32px;
    position: absolute;
    right: 35px;
}

.toaster h4 {
    font-size: 15px;
    color: var(--toast-text-primary);
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    margin-bottom: 3px;
}

.toaster h5 {
    width: 80%;
}

.notification-cat {
    font-size: 14px;
    color: var(--text-primary);
    font-weight: 600;
    word-break: normal;
}

.toaster-icon {
    position: relative;
    top: -13px;
}

.toaster>h4>i.fa {
    position: relative;
    top: 0px;
    left: 0px;
    font-size: 24px;
    width: 287px;
    float: left;
    height: 100px;
}

.toaster>h4>a>i.fa {
    font-size: 14px;
}

.toaster>h4>a {
    font-size: 14px;
    position: absolute;
    right: 10px;
    color: var(--text-secondary);
}

.toaster .click-link {
    margin-left: 45px;
}

#nodeNameSpace {
    width: 250px;
}

.modal-table table thead {
    width: 97.8%;
}

.modal-table table tbody {
    height: 300px;
    overflow-y: auto;
    width: 100%;
}

.modal-table table thead, .modal-table table tbody, .modal-table table tr, .modal-table table td, .modal-table table th {
    display: block;
}

.modal-table table tbody td, .modal-table table thead > tr> th {
    float: left;
    border-bottom-width: 0;
}

.label-height {
    height: 35px;
}

.btn-reset-community {
    position: absolute;
    right: 200px;
    top: 8px;
}

.form-control-autosize {
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    float: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #c5c5c5;
    font-size: 16px;
    display: block;
    height: auto;
    -webkit-transition: background 1ms ease-in;
    -o-transition: background 1ms ease-in;
    transition: background 1ms ease-in;
    width: 100%;
}


/*input[type='number'] {
    -moz-appearance:textfield;
    -moz-appearance:textfield;
}*/


/*input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number']::-moz-outer-spin-button,
input[type='number']::-moz-inner-spin-button {
    -moz-appearance: none;
}*/

.gridHeader {
    font-size: 14px !important;
}
.gridHeaderShrink{
    font-size:10px !important;
}

.error-relative{
    color: var(--textfield-text-error);
    position: relative;
    display: block;
    left: 0px;
    margin: auto;
    right: 0px;
    width: 100%;
}

/* ---IE-11-- */

.excelResult{
    width:100%;
}

.excelResult thead  tr th{
    white-space: initial;
}

.excelResult_id{
    width:25%;
}

.excelResult_name{
    width:50%;
}

.excelResult_amount{
    width:25%;
}

.excelResult tbody tr td{
    white-space: initial;
    width:33%;
}

.customPopUp > div {
    z-index:  1000 !important;
}
.mar-l-10{
    margin-left:24px !important;
}
small, .small {
    font-size: 85% !important;
}

.mac-error {
    color: var(--red-400);
    font-size: 18px !important;
}

/* PrimeNG override */
.contract-accounts-container .ui-accordion-content {
  background-color: unset;
}

.contract-accounts-container .ui-accordion-header {
  border-radius: 0;
}
.contract-accounts-container .ui-accordion-header > a {
  text-decoration: none;
}

.ui-dropdown-item-group:not(:first-child) {
  border-top: 1px solid;
}

.editBlock-modal-body hr {
  margin-right: 15px;
}

.opacity-03 {
    opacity: 0.3;
}
