@charset "utf-8";

body {
    padding-top: 40px;
}

html, body, .container, .container-fluid, .container-fluid-row {
    height: 100%;
    min-height: 100%;
    font-size: 100%;
}

.text-50 {
    font-size: 50px;
    text-align: center;
    color: var(--text-primary);
}

.panel-body {
    background: var(--card-background-base);
    padding: 12px;
}

.panel-body-height {
    height: 262px;
}

/*-------------Header-------------*/

.navbar {
  color: var(--text-primary);
  z-index: 992;
  margin-bottom: 0;
  border-radius: 0;
  min-height: 40px;
  max-height: 40px;
  background: var(--app-background) fixed;
  border: 0;
  box-shadow: var(--elevation-300);
}

.navbar-nav {
    margin: 0px;
    padding: 0px;
}

.navbar .navbar-nav>li>a.nav-item {
  max-width: 150px;
  padding: 0;
  display: block;
}
@media (max-width: 1090px) {
  .navbar .navbar-nav>li>a.nav-item {
    max-width: unset; /* stop truncating text at 1090px */
  }
}

.logo {
  max-width: 165px;
  height: 36px;
  padding: 5px 0;
  margin: 4px 15px 4px 0;
}

.logo img {
    height: 100%;
}

.navbar-search {
    display: flex;
    align-items: center;
    float: left;
    height: 40px;
    padding: 2px 12px;
    position: relative;
    width: 300px;
}

.navbar-search .p-autocomplete input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 1.75rem;
}
.navbar-search .p-autocomplete .p-button {
  border: 0;
  height: 1.75rem !important;
}

.navbar-menus>li>a {
    color: var(--list-text-primary);
    position: relative;
    line-height: 40px;
}

.navbar-menus>li {
    padding: 0px 10px 0px;
    display: block;
    margin: 0px;
}

.navbar-menus>li a:hover, .navbar-menus>li>a:focus {
    text-decoration: none;
    color: var(--list-text-hover);
    background: transparent;
}

.navbar-menus>li:last-child>a {
    border: none;
    padding-right: 0;
}

.user-avatar {
    height: 26px;
    margin-right: 20px;
}

.noti-badge {
    left: -10px;
    padding: 2px 5px;
    position: absolute;
    top: 6px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 2px;
}

.noti-label {
    display: inline-block;
}

.navbar-user-dropdown-menu li a {
    margin: 3px 0px;
    padding: 5px 10px;
    background-color: transparent;
}

.page-header-container {
  display: flex;
  align-items: center;
  height: 88px;
  position: relative;
  z-index: 1023;
}

.page-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 10px 24px;
}

.page-header-subtitle {
  margin-top: 8px;
}

/*-----------End Header---------*/

.menu-label {
  font-size: 16px;
}

.leftnav-image-icon {
  width: 1.28571429em !important; /* to match fontawesome fa-fw width */
  text-align: center;
}

/* --- End Left Menu Navbar --- */

/* --- Left Nav Favorite Query --- */

.searchquery {
  height: 47px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid var(--table-stroke-primary);
}

.searchquery>input {
  background: var(--textfield-icon-primary) url(../img/search-icon.png) no-repeat scroll 5px center;
  height: 36px;
  margin: 5px 0 0 0;
  padding: 1px 13px 1px 25px;
  width: 82%;
}

.searchquery .btn {
  float: right;
  margin: 5px 0px;
  padding: 8px;
  border-radius: 0px;
}

/* --- End Left Nav Favorite Query --- */

/*---------------Main Elements--------------*/

.main {
    height: 100%;
    padding: 0px;
    position: relative;
    -webkit-transition: all 1ms ease-in-out;
    -moz-transition: all 1ms ease-in-out;
    -o-transition: all 1ms ease-in-out;
    transition: all 1ms ease-in-out;
}

body.expanded-content .main {
    margin-left: 64px;
}

.dashboard-content {
  position: relative;
  top: 94px;
}

.dashboard-content.admin-dashboard {
  top: 0;
}

#grid-height-top-70 {
    height: 70%;
}

.form-pad-0 {
    margin: 0 !important;
    padding: 0 !important;
}

#grid-height-top-85 {
    height: 85%;
}

/*---All Dashboard CSS---*/

.mfBanner {
    margin-bottom: 20px;
}

.mfBanner>div {
    padding-left: 100px;
}

.mfBanner>div>h3 {
    color: var(--text-primary);
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.mfBanner>div>h4 {
    color: var(--text-primary);
    text-align: center;
    font-size: 17px;
}

.mfnav {
    background-color: var(--navigation-background-base);
    display: flex;
}

.mfnav>li>a {
    color: var(--list-text-primary);
}
.mfnav>li {
    border: 0.1px solid var(--menu-stroke-primary);
}

.mfnav>li.active>a {
    background-color: var(--list-background-hover);
}

.mfnav>li.active>a>span {
    background-color: var(--list-background-hover);
}

.mfnav>li>a:hover {
    background-color: var(--list-background-hover);
}

/*----Blue Tabs Section---*/

.display-inline-block {
  display: inline-block;
}

.actionBtn {
    position: relative;
    right: 0;
    margin: 0;
    padding: 0;
}

.actionBtn a.action-drop {
    color: var(--gray-900) !important;
    font-size: 15px;
    padding: 12px 10px;
}

/*-----Tab Content------*/

.tab-content {
    height: 100%;
}

.tab-content-margin-top {
    padding-top: 35px;
}

.tab-content .tab-pane {
  padding: 10px 0 0 0;
  width: 100%;
  height: auto;
  float: left;
}
.grid-container .tab-content .tab-pane, .tab-content > .tab-pane {
  padding-top: 0;
}

.tab-content .tab-pane[role="tabpanel"] {
  padding: 15px 15px 0px 15px;
}
/*--Portfolio-Dashboard---*/

.row-no-padding>[class*="col-"], .row-no-padding .panel {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}


.dash-bg-color-7, .dash-bg-color-13, .dash-bg-color-19 {
  background: var(--gray-200);
}

.dash-bg-color-8, .dash-bg-color-14, .dash-bg-color-20 {
  background: var(--gray-200);
}

.dash-bg-color-9, .dash-bg-color-15 {
  background: var(--gray-200);
}

.dash-bg-color-10, .dash-bg-color-16 {
  background: var(--gray-200);
}

.dash-bg-color-11, .dash-bg-color-17 {
  background: var(--gray-200);
}

.dash-bg-color-12, .dash-bg-color-18 {
  background: var(--gray-200);
}

.portfolio-tiles .panel-body {
    height: 140px;
    min-height: 100%;
    line-height: initial; /* OPL hack */
}

.portfolio-tiles .lead {
    margin: 0px;
    padding: 0px;
    min-height: auto;
}

.portfolio-tiles .textcaret {
    color: var(--text-primary);
    display: block;
}

.portfolio-tiles .textcaret>.fa {
    color: var(--textfield-icon-primary);
    font-size: 24px;
    margin-right: 5px;
}

.panel .panel-actions-fly {
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 9;
}

.dif-controls {
    margin: 0px;
    padding: 0px;
}

.dif-controls .count-box {
    font-size: 18px;
    color: var(--text-primary);
    float: left;
    padding: 0 5%;
    margin: 3px 0 0 0;
}

.dif-controls .count-box span {
    font-size: 24px;
    color: var(--text-primary);
    font-weight: normal;
    display: block;
}

.count-box sub, .text-50 sub {
    font-size: 55%;
}

.dif-controls .count-box-right-brd {
    border-right: 1px solid var(--table-stroke-primary);
}

@media (max-width: 768px) {
    [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
    }
    .container-fluid {
        margin: 0;
        padding: 0;
    }
}

.text-62 {
    color: var(--text-primary);
    font-size: 62px;
    text-align: center;
    margin: 14.5% 0px;
}

.text-62 span {
    color: var(--text-primary);
    display: block;
    font-size: 22px;
}

.issues-controls {
    margin: 0px;
    padding: 0px;
}

.issues-controls .count-box {
    font-size: 18px;
    color: var(--text-primary);
    float: left;
    padding: 0px 7%;
    margin: 16px 0px;
    width: 50%;
    text-align: center;
}

.issues-controls .count-box span {
    font-size: 30px;
    font-weight: normal;
    display: block;
}

.issues-controls .error-color {
    color: var(--red-400)
    ;
}

.issues-controls .warning-color {
    color: var(--yellow-500);
}

.issues-controls .count-box-right-brd {
    border-right: 1px solid var(--table-stroke-primary);
}

.col-xs-15, .col-sm-15, .col-md-15, .col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}

.portfolio-aum-tiles .panel-body {
    height: 115px;
    margin-right: 2px;
    margin-bottom: 2px;
}

.portfolio-issues-tile {
    background: var(--card-background-base);
    height: 280px;
    margin-left: -15px;
    line-height: initial; /* OPL hack */
}

.portfolio-issues-tile .lead {
    font-size: 44px;
    min-height: 0px;
    margin-bottom: 0px;
    color: var(--text-primary);
    line-height: 1em;
}

.portfolio-report-component {
    margin: 0px;
    padding: 0px;
}

.portfolio-report-component .title {
    font-size: 16px;
    color: var(--table-text-secondary);
}

.portfolio-report-component .count {
    font-size: 16px;
    color: var(--table-text-secondary);
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    float: left;
    margin-right: 5px;
    padding-top: 5px;
    width: 30px;
}

.portfolio-report-component .progress {
    height: 27px;
    background: var(--slider-background-base);
    padding: 3px;
    float: left;
    width: 80%;
}

.portfolio-report-component .total-count {
    font-size: 16px;
    color: var(--table-text-secondary);
    display: inline-block;
    font-size: 12px;
    float: right;
    margin-left: 5px;
    padding-top: 5px;
    font-weight: 800;
}

.progress .tolerance {
    background: var(--blue-500);
}

.progress .model {
    background: var(--blue-500);
}

.progress .cashneed {
    background: var(--blue-500);
}

.progress .blocked {
    background: var(--blue-500);
}

.progress .rebalance {
    background: var(--blue-500);
}

.progress .harvesting {
    background: var(--blue-500);
}

.progress .contributions {
    background: var(--blue-500);
}

.progress .dataerrors {
    background: var(--blue-500);
}

.progress .distribution {
    background: var(--blue-500);
}

.progress .trades {
    background: var(--blue-500);
}

.lead {
    font-size: 24px;
    min-height: 50px;
    margin-bottom: 0px;
}

.lead span {
    font-size: 20px;
    display: block;
}

.lead-value {
    text-align: center;
    font-size: 42px;
    font-weight: bold;
    line-height: 30px;
}

.lead-desc {
    text-align: center;
    font-weight: normal;
}

.lead-desc-count {
    text-align: center;
    font-weight: normal;
    font-size: 20px;
    padding: 0px;
    margin: 0px;
}

.portfolio-team-listgroup {
    overflow: auto;
    height: 155px;
}

.portfolio-team-list {
    margin: 0px;
    padding: 0px;
}

.portfolio-team-list li {
    margin: 0px 0px 5px 0px;
    padding: 0px 5px;
    width: 100%;
    height: 35px;
    background: var(--gray-200);
    color: var(--text-primary);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    list-style-type: none;
    line-height: 35px;
}

.portfolio-team-list li a {
    color: var(--list-text-primary);
    text-decoration: none;
    margin: 0px 2px;
    float: left;
}

.portfolio-team-list li span {
    text-decoration: none;
    margin: 0px 2px;
}

.portfolio-team-list-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40%;
    padding: 0px;
    display: inline-block;
}

.portfolio-team-list li span i {
    margin: 5px;
}

.portfolio-team-list li label {
    margin: 0px;
    padding: 6px;
}

.portfolio-team-list li a i.fa {
    color: var(--alert-icon-error);
}

/*-----End Portfolio-Dashboard---*/

/* -------Admin Dashboard------- */

div[class*="dash-bg-color"] label {
  color: var(--card-text-title);
  font-size: 20px;
}

div[class*="dash-bg-color"] .value {
  color: var(--card-text-title);
  font-size: 30px;
  padding-bottom: 15px;
}

div[class*="dash-bg-color"], div[class*="dash-bg-color"] .panel-body {
  padding-bottom: 0px;
  cursor:pointer;
}

.dash-bg-color1, .dash-bg-color1 .panel-body {
  background: var(--gray-200);
}

.dash-bg-color2, .dash-bg-color2 .panel-body {
  background: var(--gray-200);
}

.dash-bg-color3, .dash-bg-color3 .panel-body {
  background: var(--gray-200);
}

.dash-bg-color4, .dash-bg-color4 .panel-body {
  background: var(--gray-200);
}

.dash-bg-color5, .dash-bg-color5 .panel-body {
  background: var(--gray-200);
}

.dash-bg-color6, .dash-bg-color6 .panel-body {
  background: var(--gray-200);
}

.grid-container {
    height: 100%;
}

.form-inline .radio label, .form-inline .checkbox label {
    padding-left: 30px;
}

.form-group label.control-label {
  text-align: right;
  color: var(--textfield-text-label);
}

/* Cutom Styles for Margin, Padding, Postions */

.position-a {
    position: absolute
}

.position-f {
    position: fixed
}

.position-r {
    position: relative
}

.t-0 {
    top: 0!important
}

.r-0 {
    right: 0!important
}

.b-0 {
    bottom: 0!important
}

.l-0 {
    left: 0!important
}

.t-5 {
    top: 5px!important
}

.r-5 {
    right: 5px!important
}

.b-5 {
    bottom: 5px!important
}

.l-5 {
    left: 5px!important
}

.t-10 {
    top: 10px!important
}

.r-10 {
    right: 10px!important
}

.b-10 {
    bottom: 10px!important
}

.l-10 {
    left: 10px!important
}

.t-15 {
    top: 15px!important
}

.r-15 {
    right: 15px!important
}

.b-15 {
    bottom: 15px!important
}

.l-15 {
    left: 15px!important
}

.t-20 {
    top: 20px!important
}

.r-20 {
    right: 20px!important
}

.b-20 {
    bottom: 20px!important
}

.l-20 {
    left: 20px!important
}

.t-30 {
    top: 30px!important
}

.r-30 {
    right: 30px!important
}

.b-30 {
    bottom: 30px!important
}

.l-30 {
    left: 30px!important
}

.t-40 {
    top: 40px!important
}

.r-40 {
    right: 40px!important
}

.b-40 {
    bottom: 40px!important
}

.l-40 {
    left: 40px!important
}

.t-50 {
    top: 50px!important
}

.r-50 {
    right: 50px!important
}

.b-50 {
    bottom: 50px!important
}

.l-50 {
    left: 50px!important
}

.t-60 {
    top: 60px!important
}

.r-60 {
    right: 60px!important
}

.b-60 {
    bottom: 60px!important
}

.l-60 {
    left: 60px!important
}

.m-a-0 {
    margin: 0!important
}

.m-t-0 {
    margin-top: 0!important
}

.m-r-0 {
    margin-right: 0!important
}

.m-b-0 {
    margin-bottom: 0!important
}

.m-l-0 {
    margin-left: 0!important
}

.m-x-0 {
    margin-left: 0!important;
    margin-right: 0!important
}

.m-y-0 {
    margin-bottom: 0!important;
    margin-top: 0!important
}

.m-a-5 {
    margin: 5px!important
}

.m-t-5 {
    margin-top: 5px!important
}

.m-r-5 {
    margin-right: 5px!important
}

.m-b-5 {
    margin-bottom: 5px!important
}

.m-l-5 {
    margin-left: 5px!important
}

.m-x-5 {
    margin-left: 5px!important;
    margin-right: 5px!important
}

.m-y-5 {
    margin-bottom: 5px!important;
    margin-top: 5px!important
}

.m-a-10 {
    margin: 10px!important
}

.m-t-10 {
    margin-top: 10px!important
}

.m-r-10 {
    margin-right: 10px!important
}

.m-b-10 {
    margin-bottom: 10px!important
}

.m-l-10 {
    margin-left: 10px!important
}

.m-x-10 {
    margin-left: 10px!important;
    margin-right: 10px!important
}

.m-y-10 {
    margin-bottom: 10px!important;
    margin-top: 10px!important
}

.m-a-15 {
    margin: 15px!important
}

.m-t-15 {
    margin-top: 15px!important
}

.m-r-15 {
    margin-right: 15px!important
}

.m-b-15 {
    margin-bottom: 15px!important
}

.m-l-15 {
    margin-left: 15px!important
}

.m-x-15 {
    margin-left: 15px!important;
    margin-right: 15px!important
}

.m-y-15 {
    margin-bottom: 15px!important;
    margin-top: 15px!important
}

.m-a-20 {
    margin: 20px!important
}

.m-t-20 {
    margin-top: 20px!important
}

.m-r-20 {
    margin-right: 20px!important
}

.m-b-20 {
    margin-bottom: 20px!important
}

.m-l-20 {
    margin-left: 20px!important
}

.m-x-20 {
    margin-left: 20px!important;
    margin-right: 20px!important
}

.m-y-20 {
    margin-bottom: 20px!important;
    margin-top: 20px!important
}

.m-a-30 {
    margin: 30px!important
}

.m-t-30 {
    margin-top: 30px!important
}

.m-r-30 {
    margin-right: 30px!important
}

.m-b-30 {
    margin-bottom: 30px!important
}

.m-l-30 {
    margin-left: 30px!important
}

.m-x-30 {
    margin-left: 30px!important;
    margin-right: 30px!important
}

.m-y-30 {
    margin-bottom: 30px!important;
    margin-top: 30px!important
}

.m-a-40 {
    margin: 40px!important
}

.m-t-40 {
    margin-top: 40px!important
}

.m-r-40 {
    margin-right: 40px!important
}

.m-b-40 {
    margin-bottom: 40px!important
}

.m-l-40 {
    margin-left: 40px!important
}

.m-x-40 {
    margin-left: 40px!important;
    margin-right: 40px!important
}

.m-y-40 {
    margin-bottom: 40px!important;
    margin-top: 40px!important
}

.m-a-50 {
    margin: 50px!important
}

.m-t-50 {
    margin-top: 50px!important
}

.m-r-50 {
    margin-right: 50px!important
}

.m-b-50 {
    margin-bottom: 50px!important
}

.m-l-50 {
    margin-left: 50px!important
}

.m-x-50 {
    margin-left: 50px!important;
    margin-right: 50px!important
}

.m-y-50 {
    margin-bottom: 50px!important;
    margin-top: 50px!important
}

.m-a-60 {
    margin: 60px!important
}

.m-t-60 {
    margin-top: 60px!important
}

.m-r-60 {
    margin-right: 60px!important
}

.m-b-60 {
    margin-bottom: 60px!important
}

.m-l-60 {
    margin-left: 60px!important
}

.m-x-60 {
    margin-left: 60px!important;
    margin-right: 60px!important
}

.m-y-60 {
    margin-bottom: 60px!important;
    margin-top: 60px!important
}

.p-a-0 {
    padding: 0!important
}

.p-t-0 {
    padding-top: 0!important
}

.p-r-0 {
    padding-right: 0!important
}

.p-b-0 {
    padding-bottom: 0!important
}

.p-l-0 {
    padding-left: 0!important
}

.p-x-0 {
    padding-left: 0!important;
    padding-right: 0!important
}

.p-y-0 {
    padding-bottom: 0!important;
    padding-top: 0!important
}

.p-a-5 {
    padding: 5px!important
}

.p-t-5 {
    padding-top: 5px!important
}

.p-r-5 {
    padding-right: 5px!important
}

.p-b-5 {
    padding-bottom: 5px!important
}

.p-l-5 {
    padding-left: 5px!important
}

.p-x-5 {
    padding-left: 5px!important;
    padding-right: 5px!important
}

.p-y-5 {
    padding-bottom: 5px!important;
    padding-top: 5px!important
}

.p-a-10 {
    padding: 10px!important
}

.p-t-10 {
    padding-top: 10px!important
}

.p-r-10 {
    padding-right: 10px!important
}

.p-b-10 {
    padding-bottom: 10px!important
}

.p-l-10 {
    padding-left: 10px!important
}

.p-x-10 {
    padding-left: 10px!important;
    padding-right: 10px!important
}

.p-y-10 {
    padding-bottom: 10px!important;
    padding-top: 10px!important
}

.p-a-15 {
    padding: 15px!important
}

.p-t-15 {
    padding-top: 15px!important
}

.p-r-15 {
    padding-right: 15px!important
}

.p-b-15 {
    padding-bottom: 15px!important
}

.p-l-15 {
    padding-left: 15px!important
}

.p-x-15 {
    padding-left: 15px!important;
    padding-right: 15px!important
}

.p-y-15 {
    padding-bottom: 15px!important;
    padding-top: 15px!important
}

.p-a-20 {
    padding: 20px!important
}

.p-t-20 {
    padding-top: 20px!important
}

.p-r-20 {
    padding-right: 20px!important
}

.p-b-20 {
    padding-bottom: 20px!important
}

.p-l-20 {
    padding-left: 20px!important
}

.p-x-20 {
    padding-left: 20px!important;
    padding-right: 20px!important
}

.p-y-20 {
    padding-bottom: 20px!important;
    padding-top: 20px!important
}

.p-a-30 {
    padding: 30px!important
}

.p-t-30 {
    padding-top: 30px!important
}

.p-r-30 {
    padding-right: 30px!important
}

.p-b-30 {
    padding-bottom: 30px!important
}

.p-l-30 {
    padding-left: 30px!important
}

.p-x-30 {
    padding-left: 30px!important;
    padding-right: 30px!important
}

.p-y-30 {
    padding-bottom: 30px!important;
    padding-top: 30px!important
}

.p-a-40 {
    padding: 40px!important
}

.p-t-40 {
    padding-top: 40px!important
}

.p-r-40 {
    padding-right: 40px!important
}

.p-b-40 {
    padding-bottom: 40px!important
}

.p-l-40 {
    padding-left: 40px!important
}

.p-x-40 {
    padding-left: 40px!important;
    padding-right: 40px!important
}

.p-y-40 {
    padding-bottom: 40px!important;
    padding-top: 40px!important
}

.p-a-50 {
    padding: 50px!important
}

.p-t-50 {
    padding-top: 50px!important
}

.p-r-50 {
    padding-right: 50px!important
}

.p-b-50 {
    padding-bottom: 50px!important
}

.p-l-50 {
    padding-left: 50px!important
}

.p-x-50 {
    padding-left: 50px!important;
    padding-right: 50px!important
}

.p-y-50 {
    padding-bottom: 50px!important;
    padding-top: 50px!important
}

.p-a-60 {
    padding: 60px!important
}

.p-t-60 {
    padding-top: 60px!important
}

.p-r-60 {
    padding-right: 60px!important
}

.p-b-60 {
    padding-bottom: 60px!important
}

.p-l-60 {
    padding-left: 60px!important
}

.p-x-60 {
    padding-left: 60px!important;
    padding-right: 60px!important
}

.form-inline .autocomplete-search {
    display: inline-block;
    margin-left: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .logo {
        width: 165px;
    }
    .portfolio-issues-tile {
        margin-left: 0px;
    }
    .text-62 {
        margin: 0px;
    }
    .model-details-info .panel {
        margin-bottom: 5px;
    }
    .dash-bg-color1, .dash-bg-color1 .panel-body {
        padding-bottom: 0px;
      margin-top: 10px;
    }

    .btn.btn-sm, .input-group-btn .btn.btn-sm {
        padding: 3px 6px;
        font-size: 10px;
    }
    .btn-bar i {
        font-size: 16px;
    }
    .btnnone {
        padding: 0px;
    }
    .noti-per-content a {
        right: 5px;
    }
    .form-group label.control-label {
        text-align: left;
    }
    .right-hbox-container {
        width: 348px;
    }
    .tree-container {
        overflow-y: scroll;
        width: 97% !important;
    }
    .tree-table-grid {
        width: 1280px;
    }
}

@media (max-width: 767px) {
    .loginwindow {
        margin: 30px 30px;
    }
    .logo {
        width: auto;
    }
    .navbar-search {
        width: 90%;
        margin-right: 10px;
    }
    .navbar-menus>li>a {
        line-height: 30px;
    }
    .navbar .navbar-collapse, .navbar .navbar-form {
        background: var(--menu-background-base);
    }
    .navbar-menus {
        clear: left;
    }
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }
    .navbar-nav .open .dropdown-menu>li>a, .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px
    }
    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 20px
    }
    .navbar-nav .open .dropdown-menu>li>a:hover, .navbar-nav .open .dropdown-menu>li>a:focus {
        background-image: none
    }
    .navbar-search .search-dropdown {
        left: 105px;
    }
    .portfolio-report-component .progress {
        width: 70%;
    }
    .progress {
        margin-bottom: 0px;
    }
    .warnings, .import, .errors {
        height: 70px;
    }
    .nav-tab-fixed {
        left: 0;
    }
    .navbar-nav {
        margin: 8px -15px;
        padding: 0px 10px;
    }
    .portfolio-issues-tile {
        margin-left: 0px;
    }
    .text-62 {
        margin: 0px;
    }
    .form-group {
        margin: 10px 0 0 0;
    }
    label.primar-label {
        margin-top: 0;
    }
    .nav-tabs-container {
        height: 48px;
    }
    .nav-tabs>li>a {
        padding: 14px 3px;
        font-size: 11px;
    }
    .actionBtn {
        /* right: -30px;
        top: -45px; */
    }
    .clearfix {
        margin: 0px;
        padding: 0px;
        height: 0px;
    }
    .marging-top-5x {
        margin: 0px;
    }
    .grid-container {
        margin-left: 0px;
    }
    .admin-btn-collapse {
        display: block;
    }
    .form-group label.control-label {
        text-align: left;
    }
    .btn-reset-community {
        top: 40px;
    }
    .field-control {
        width: 75%;
    }
    .category-form .form-group .control-label {
        padding-top: 10px;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .model-details-info .panel {
        margin-bottom: 5px;
    }
    .dash-bg-color1, .dash-bg-color1 .panel-body {
        padding-bottom: 0px;
    }
    .btn-group-sm .btn, .btn-group-sm .input-group-btn .btn, .btn.btn-sm, .input-group-btn .btn.btn-sm {
        padding: 3px 6px;
        font-size: 10px;
    }
    .btn-bar i {
        font-size: 16px;
    }
    .btnnone {
        padding: 0px;
    }
    .compress-container {
        width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 1090px) {
  .navbar .navbar-collapse, .navbar .navbar-form {
    background: var(--menu-background-base);
    border: var(--menu-stroke-primary);
  }
  .navbar-search {
    width: 90%;
  }
}

@media (max-width: 839px) {
    .main {
        margin: 0 0 0 0px;
    }
    .firm-menu {
        display: none;
    }
    .topbar {
        padding-left: 0px;
    }
    .nav-tab-fixed {
        left: 0px;
    }
    .select-form-group {
        width: auto;
    }
    .grid-container .nav-tab-fixed {
        left: 248px;
    }
    .expanded-content .grid-container .nav-tab-fixed {
      left: 64px;
    }
    .field-control {
        margin-top: 10px;
    }
    .form-group label {
        text-align: left;
        display: block;
    }
    .form-group label.radio-inline {
        display: inline-block;
    }

    .input-3x-width {
        width: 55% !important;
    }
    .notifications-wrapper {

        height: 48vh;
    }
}

@media (max-width: 60em) {
    .ui-dialog-lg .ui-dialog, .ui-dialog-xl .ui-dialog, .ui-dialog-md .ui-dialog, .ui-dialog-sm .ui-dialog, .ui-dialog-xs .ui-dialog {
        width: 85%;
    }
}

@media (max-width:766px) {
    .selection-content-container .content-box-left {
        min-height: 200px;
    }
    .trade-search .col-sm-7, .trade-search .col-sm-10 {
        width: 70%;
        float: left;
        padding-right: 0px;
    }
    .trade-search .col-sm-2 {
        width: 30%;
        float: left;
    }
    .excel-import-content {
        padding: 4px 6px;
    }
    .agcontainer {
        padding: 5px;
    }
    .trade-search1 .col-sm-7 {
        width: 50%;
        float: left;
    }
    .trade-search1 .col-sm-3 {
        width: 30%;
        float: left;
    }
    .trade-search1 .col-sm-2 {
        width: 20%;
        float: left;
    }
    .actionBtn {
        right: 0px;
        top: 3px;
    }
    .tree-container {
        overflow-y: scroll;
    }
    .tree-table-grid {
        width: 1300px;
    }
}

@media (max-width:450px) {
    .dropdown-style {
        background: none;
    }
    .dropdown-style {
        height: auto;
    }
    .dropdown-style .nav>li {
        width: 100%;
    }
    .dropdown-style ul {
        width: 100%;
    }
    .dropdown-style .nav-tabs>li>a {
        display: block;
    }
    .icon-addon.addon-md .form-control, .icon-addon .form-control {
        padding-left: 20px;
        padding-right: 0px;
    }
    .trade-search1 .col-sm-7, .trade-search1 .col-sm-2, .trade-search1 .col-sm-3 {
        width: 100%;
        float: left;
    }
    .tab-tswap-pane h4 {
        margin-top: 140px;
    }
    .agcontainer .ag-material {
        margin-top: 20px;
    }
    .right-hbox-container {
        width: 268px;
    }
}

@media (max-width:320px) {
    .page-header-bar {
        height: 48px;
    }
    .trade-search .col-sm-2 {
        padding-left: 6px;
    }
}

.ui-dialog-tabs .nav-tabs-container{
    height: 35px;
}
.ui-dialog-tabs .nav-tabs>li>a{
    padding: 6px 20px;
}

a.panel-details-heading{
    display: block;
    width: 100%;
    text-decoration: none;
}

li.contact-us ul.dropdown-menu {
  min-width: 215px;
}
li.contact-us span.advisor-numb {
  font-size:10px;
  margin:5px;
  text-align: center;
}
.dropdown-menu [class^="fa-"], .nav-list [class^="fa-"], .nav-list [class*=" fa-"] {
  margin-right: 5px;
}

.splitWarning {
    font-weight: bold;
    color: var(--text-secondary);
}

.splitInfo {
    font-weight: bold;
}

.splitInfoLabel {
    font-weight: bold;
    font-size: 18px;
}

.splitMessage {
    color: var(--text-secondary);
}

.splitSpan {
    display: inline-block;
    margin-left: 40px;
}

.splitQuantity {
    display: inline-block;
    width: 120px;
    margin-left: 10px;
}

.analytics-warning-message {
    text-align: right;
    color: var(--textfield-text-error);
}

.a-pointer {
    cursor: pointer;
}

.disable-text {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.m-h-110 {
  min-height: 110px;
}
