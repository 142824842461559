// Orion Product Langauge Colors
// These colors have been sourced from the Orion Product Language.
// Please do not adjust the values of these colors without first reaching out the Orion Design and UI Development team.
// Any color(s) referenced in the code must use a variable from the list below.

//White
$white: #FFFFFF;

//Black
$black: #000000;

//Gray
$gray-50: #F9FAFB;
$gray-100: #F4F5F7;
$gray-200: #E5E7EB;
$gray-300: #D1D4DC;
$gray-400: #9FA6B2;
$gray-500: #6C727F;
$gray-600: #494F5A;
$gray-700: #383C42;
$gray-800: #26282B;
$gray-900: #1D1E20;

 //Red
$red-50: #FFEDED;
$red-100: #FFCED0;
$red-200: #FEAEB6;
$red-300: #F97386;
$red-400: #EF4268;
$red-500: #E01E5A;
$red-600: #C7094D;
$red-700: #A60048;
$red-800: #7E0042;
$red-900: #7E0042;

//Orange
$orange-50: #FFF8F1;
$orange-100: #FEECDC;
$orange-200: #FCD9BD;
$orange-300: #FDBA8C;
$orange-400: #FF9457;
$orange-500: #EB6A37;
$orange-600: #D03801;
$orange-700: #B43403;
$orange-800: #8A2C0D;
$orange-900: #771D1D;

//Yellow
$yellow-50: #FDFDEA;
$yellow-100: #FDF6B2;
$yellow-200: #FCE96A;
$yellow-300: #FACA15;
$yellow-400: #E3A008;
$yellow-500: #C27803;
$yellow-600: #9F580A;
$yellow-700: #8E4B10;
$yellow-800: #723B13;
$yellow-900: #633112;

//Green
$green-50: #F3FAF7;
$green-100: #DEF7EC;
$green-200: #BCF0DA;
$green-300: #84E1BC;
$green-400: #31C48D;
$green-500: #0E9F6E;
$green-600: #007A5A;
$green-700: #036D55;
$green-800: #0A5C4D;
$green-900: #014737;

//Teal
$teal-50: #EDFAFA;
$teal-100: #D5F5F6;
$teal-200: #AFECEF;
$teal-300: #7EDCE2;
$teal-400: #16BDCA;
$teal-500: #0694A2;
$teal-600: #047481;
$teal-700: #036672;
$teal-800: #05505C;
$teal-900: #014451;

//Blue
$blue-50: #F3FBFF;
$blue-100: #E5F5FF;
$blue-200: #CCE7FF;
$blue-300: #7EC4F6;
$blue-400: #009FF9;
$blue-500: #0078D4;
$blue-600: #1960A6;
$blue-700: #104C8B;
$blue-800: #093970;
$blue-900: #052452;

//Indigo
$indigo-50: #F0F5FF;
$indigo-100: #E5EDFF;
$indigo-200: #CDDBFE;
$indigo-300: #B4C6FC;
$indigo-400: #8DA2FB;
$indigo-500: #6875F5;
$indigo-600: #5850EC;
$indigo-700: #5145CD;
$indigo-800: #42389D;
$indigo-900: #4A1D96;

//Purple
$purple-50: #F0F5FF;
$purple-100: #EDEBFE;
$purple-200: #DCD7FE;
$purple-300: #CABFFD;
$purple-400: #AC94FA;
$purple-500: #9061F9;
$purple-600: #7E3AF2;
$purple-700: #6C2BD9;
$purple-800: #5521B5;
$purple-900: #4A1D96;

//Pink
$pink-50: #FDF2F8;
$pink-100: #FCE8F3;
$pink-200: #FAD1E8;
$pink-300: #F8B4D9;
$pink-400: #F17EB8;
$pink-500: #E74694;
$pink-600: #D61F69;
$pink-700: #BF125D;
$pink-800: #99154B;
$pink-900: #751A3D;


.red {
  color: var(--red-400);
}

.green {
  color: var(--green-400);
}

.gray {
  color: var(--gray-400);
}
