.p-sidebar {
  color: var(--slideover-text-primary);
}

p-sidebar p-footer {
  padding: 24px;
}

p-sidebar p-footer .p-button {
  margin: 0 0.5rem 0 0;
}

.notification-sidebar {
  .p-sidebar-content {
    padding: 0;
    margin: 0;
    overflow: hidden;

    .form-group {
      padding: 0;
      margin: 0 0 15px;
    }
  }
}

.p-sidebar-no-header .p-sidebar-header {
  display: none;
}
