.p-splitter {
  background: var(--appdock-background-base);
  border-color: var(--appdock-stroke-app-default);
  &.no-border {
    border: none;
  }
}

.p-splitter .p-splitter-gutter {
  background: var(--appdock-background-base);
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: var(--appdock-stroke-app-default);
}
