p-sidebar.opl-editor .p-sidebar-full {
  background: var(--app-background);

  & .p-sidebar-header {
    display: block;
    padding: 0;
    height: auto;
  }
}

.opl-editor-content-container {
  display: flex;
  flex-direction: row;
}

.opl-editor-footer {
  padding: 1rem 1.5rem;
}

.opl-editor-content {
  flex: 1;
  color: var(--text-primary);
  max-width: 100%;
  margin: 0;
}

.opl-editor-header-icon {
  background: var(--editor-background-default);
  color: var(--editor-icon-primary);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;

  & i {
    font-size: 1.5rem;
  }

  &.portfolio {
    background: var(--gray-500);
  }

  &.account {
    background: var(--editor-background-account);
  }
}

.opl-editor-sidebar-menu .p-menu {
  height: 100%;
  border-right: 1px solid var(--editor-stroke-primary) !important;

  & .p-menuitem-badge {
    margin-left: 0.5rem;
  }
}
