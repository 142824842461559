.p-selectbutton .p-button {
  background: var(--button-background-secondary);
  border: var(--button-stroke-secondary);
  color: var(--button-text-secondary);
}

.p-selectbutton.p-selectbutton-sm .p-button {
  padding: 0.25rem 0.5rem;
  height: 1.5rem;
  font-size: 0.75rem;
}

.p-selectbutton .p-button.p-highlight {
  background: var(--button-background-primary);
  border-color: var(--button-background-primary);
  color: var(--button-text-primary);
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--button-background-secondary-hover);
  border-color: var(--button-stroke-secondary);
  color: var(--button-text-secondary);
}

.p-selectbutton .p-button.p-highlight:hover {
  background: var(--button-background-primary-hover);
  color: var(--button-text-primary);
}
