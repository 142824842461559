.p-fieldset {
  border: 1px solid var(--accordion-stroke-primary);
  background: var(--card-background-base);
  color: var(--accordion-text-primary);
}

.p-fieldset .p-fieldset-legend {
  border: 1px solid var(--accordion-stroke-primary);
  color: var(--card-text-title);
  background: var(--card-background-base);
}
