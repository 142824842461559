.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background-color: var(--lozenge-background-default);
  color: var(--lozenge-text-primary);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
  white-space: unset;
}

/*For wrapping chips in multi-select instead of showing the ellipsis */
.p-multiselect.p-multiselect-wrap .p-multiselect-label {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  white-space: unset;
  overflow-y: auto;
  max-height: 10rem;

  & .p-multiselect-token {
    margin-right: 0;
    max-width: 100%;
    min-width: 0;
    word-break: break-all;
  }
}

.p-multiselect-items-wrapper .p-scroller {
  min-height: 200px;
}

.p-multiselect.p-disabled {
  opacity: unset;
  background-color: var(--textfield-background-disabled);
}
