.cell-wrap-text {
  white-space: normal !important;
}

option {
  color: var(--list-text-primary) !important;
  text-indent: 15px!important;
  background: var(--list-background-base) !important;
  padding-left: 10px;
}

select[multiple] option:hover, option:hover {
  background: var(--list-background-hover) !important;
  color: var(--list-text-hover) !important;
}

span.dropdown-search {
  background: url(../img-dark/search-icon.png) no-repeat center center;
  height: 31px;
  width: 31px;
  float: left;
  position: absolute;
  right: 18px;
  top: 16px;
}


/****************/

.p-autocomplete-dropdown {
  height: auto !important;
}

.navbar-search .p-button-icon-only .fa {
  margin-left: -12px;
}

.navbar-search::after {
  border-left: none;
}

.form-group.tabs-data {
  padding: 0 0 10px 20px;
}

.button-container {
  padding: 10px 0 0 5px;
}

.agcontainer {
  height: 100%;
}

.agcontainer-auto {
  height: auto;
}

.gridTab form, .tab-content form {
  padding: 15px 15px 0px 15px;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
  color: var(--table-text-primary);
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
  border-bottom: solid 1px var(--table-stroke-primary);
}

.field-control .p-multiselect {
  z-index: 9 !important;
}

.table-hover>tbody>tr:hover {
  background-color: var(--gray-100);
}

.form-container {
  border-bottom: solid 1px var(--grid-stroke-primary);
}

.form-control {
  color: var(--textfield-text-active);
}

label.primar-label {
  color: var(--textfield-text-label);
  margin-top: 25px;
}

.import {
  background: var(--gray-100);
}

.import, .warnings, .errors {
  color: var(--textfield-text-active);
}

.warnings {
  background: var(--gray-100);
  width: 25%;
  font-size: 90%;
}

.runquery {
  background: var(--card-background-base);
  box-shadow: var(--elevation-400);
  color: var(--card-text-title);
}

.admin-page-right .panel {
  margin-bottom: 20px;
  background-color: var(--gray-100);
}

.admin-accordion .panel {
  box-shadow: none;
  margin-bottom: 57px; /* offset so panel doesn't go underneath bottom save button bar */
}
.admin-accordion .panel-heading {
  border-bottom: dotted 1px var(--accordion-stroke-primary);
  background: var(--accordion-background-base);
}
.admin-accordion .panel-title>a {
  color: var(--accordion-text-primary);
}

.category-fieldset .col-md-1, .category-fieldset .col-md-5 {
  padding-right: 0px;
  padding-left: 5px;
}

.category-fieldset .col-md-5 {
  right: -25px;
}

.bs-component {
  margin-right: 15px;
  width: 100%;
  display: inline-block;
}

.input-group-addon {
  color: var(--textfield-icon-primary);
}

.table-bordered, .table-bordered>thead>tr>th, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>tbody>tr>td, .table-bordered>tfoot>tr>td {
  border: 1px solid var(--table-stroke-primary);
}

.tab-content .p-multiselect .p-multiselect-trigger {
  width: 30px;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.customsearch input {
  width: 330px;
  height: 36px;
  margin-top: 30px;
}

.button-spacer {
  display: inline-block;
}

.button-spacer button {
  margin-top: 7px;
}

.customsearch .p-autocomplete-panel {
  top: 66px!important;
}

.popupContainer {
  min-height: 250px;
  width: 100%;
}

.ui-dialog .ui-dialog-titlebar {
  padding: 10px 15px!important;
  margin: 0 !important;
}

.ui-widget-overlay {
  background: hsla(209, 15%, 32%, .6) !important;
  opacity: .60!important;
  filter: Alpha(Opacity=60)!important;
}

table.datatable {
  width: 100%;
  border: solid 1px var(--table-stroke-primary);
}

.datatablehd {
  background: var(--table-background-base);
}

.datatablehd th {
  padding: 3px 10px;
  text-align: center;
  border-bottom: solid 1px var(--table-stroke-primary) !important;
}

table.datatable td {
  padding: 0px 2px;
  border-right: dotted 1px var(--table-stroke-primary);
}

table.datatable.clarified td {
  padding: 2px 5px;
  border-right: dotted 1px var(--table-stroke-primary);
  border-bottom: dotted 1px var(--table-stroke-primary);
}

td.toggle {
  width:1px;
  text-align: center;
  color: rgba(0,0,0,0.2);
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}

td.toggle.active {
  text-shadow: 1px 1px rgba(0,0,0,.25);
  color: rgba(255,255,255,.8);
  background: rgba(3, 169, 244, 0.5);
}

td.toggle:hover {
  text-shadow: 1px 1px rgba(0,0,0,.25);
  color: rgba(255,255,255,1);
}

.dataheading {
  background: var(--table-background-header);
}

tr.dataheading:hover, tr.datachild:hover {
  background: var(--table-background-hover);
}

.datachild {
  background: var(--table-background-base);
}

.inputpopup {
  padding: 10px;
}

.cursor {
  cursor: pointer;
}

.picklist ul {
  height: 200px;
  background-color: rgb(95, 101, 110);
  background-image: none;
}

.addbtn {
  padding: 6px 20px;
  margin: 8px 0 0 8px;
}

.addbtnpopup {
  padding: 5px 20px;
  margin: 8px 0 10px 8px;
}

p.securitysetid {
  height: 22px;
  margin-top: 10px;
}

.warning-msg p {
  color: var(--textfield-text-error);
}

.assetSubmit {
  margin-top: 32px !important;
}

.form-group .ui-multiselect .ui-multiselect-trigger {
  width: 30px;
  padding: 6px 0 0 6px;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.toltype {
  padding: 10px;
  background: var(--gray-100);
  display: inline-block;
  min-height: 60px;
  width: 100%;
}

.toltype p {
  margin: 12px 0 0 0
}

.inputholder {
  display: inline-block;
  margin: 10px 0 0 15px;
}

.toltype .form-control {
  margin: 0 0 0 15px;
  height: 23px;
  display: inline-block;
}

.search-security input {
  width: 100%;
  height: 30px;
}

.seccontainer .form-group {
  margin: 12px 0 0 5px;
}

.seccontainer .customBtn {
  margin: 12px -15px 0 -15px;
}

.seccontainer p, .seccontainer span, .sdcontainer p, .sdcontainer span {
  display: inline-block;
  width: 100%;
}

.sdcontainer .sdinput {
  display: inline-block;
}

.sdcontainer input {
  width: 340px;
  height: 30px;
}

.sdcontainer label {
  display: inline-block;
  margin-right: 15px;
  width: auto;
}

.checkbox label::before {
  content: "";
  display: none !important;
}

.footer-spacer {
  margin-top: 282px;
}

.divider, .dropdown-menu .divider {
  background: var(--menu-stroke-primary);
}

.divider {
  margin: 25px 0 0 0;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  color: var(--black);
}

.toltype {
  padding: 10px;
  background: var(--gray-100);
  display: inline-block;
  min-height: 60px;
  width: 100%;
}

.toltype p {
  margin: 12px 0 0 0
}

.inputholder {
  display: inline-block;
  margin: 10px 0 0 15px;
}

.toltype .form-control {
  margin: 0 0 0 15px;
  height: 23px;
  display: inline-block;
}

.security-preferences-fieldset {
  padding: .35em .625em .75em;
  margin: 0 2px;
  border: solid 1px var(--grid-stroke-primary);
  margin-top: 20px;
}

.security-preferences-fieldset legend {
  display: block;
  padding: 0px 10px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: inherit;
  color: var(--grid-text-primary);
  border: 0;
  font-weight: 500;
  width: auto;
}

.security-preferences-modal-body {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}

.assetEditError {
  position: absolute;
  top: 65px;
  left: 0;
}

.boldFont {
  font-weight: bold
}

.preference-indicator-select {
  position: absolute;
  left: -28px;
  top: 0px;
}

/*-------------Portfolio Assigned and Teams Access css code start ---------------*/

.search-add-input {
  width: 240px;
}

ul.portfolio {
  margin: 2px 0 0 0;
  padding: 0px;
}

ul.portfolio li {
  margin: 0;
  padding: 0px;
  display: block;
}

ul.portfolio li a,
ul.portfolio li span{
  margin: 2px 0;
  background-color: var(--menu-background-base);
  text-decoration: none;
  height: 30px;
  line-height: 30px;
  float: left;
  text-align: left;
  color: var(--menu-text-primary);
  border-radius: 3px;
  padding: 0 10px;
  width: calc(100% - 50px);
}

ul.portfolio li i {
  width: 50px;
  color: var(--menu-icon-primary);
  float: right;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}

ul.portfolio li a.active,
ul.portfolio li span.active{
  background-color: var(--menu-background-hover);
  color: var(--menu-text-active);
}

ul.portfolio li .btn {
  width: 20px;
  color: var(--black);
  float: right;
  text-align: center;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  margin-top: 10px;
}

ul.portfolio li .btn i {
  width: 100%;
  color: var(--black);
  float: none;
  text-align: center;
  margin: 0px;
}

ul.team-access-list li span {
  margin: 2px 0;
  background-color: var(--menu-background-base);
  text-decoration: none;
  height: 30px;
  line-height: 30px;
  float: left;
  text-align: left;
  color: var(--menu-text-primary);
  border-radius: 3px;
  padding: 0 10px;
  width: calc(100% - 30px);
  cursor: pointer;
}

ul.team-access-list li span.active {
  background-color: var(--lozenge-background-success-bold);
  color: var(--lozenge-text-bold);
}

ul.team-access-list {
  margin: 2px 0 0 0;
  padding: 0px;
}

ul.team-access-list li {
  margin: 0;
  padding: 0px;
  display: block;
}

.portfolio-list-group {
  margin: 2px -10px;
  padding: 0px;
  max-height: 273px;
  overflow: hidden;
  overflow-y: auto;
}

.portfolio-list-disc {
  font-size: 12px;
  margin: 5px 0px;
  margin-bottom: 10px;
  clear: left;
}

.portfolio-list-disc a {
  font-size: 12px;
  position: absolute;
  right: 10px;
}

.panel-details-info .panel {
  box-shadow: none;
  background-color: transparent;
}

.panel-details-info .collapse.in {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.panel-details-info .divider {
  margin: 20px 0;
}

.panel-details-heading {
  font-size: 24px;
  color: var(--accordion-text-primary);
  margin: 0 0 0px 0px;
  font-weight: 300;
}

.panel-details-heading i {
  position: relative;
  top: -2px;
  border-radius: 2px;
  cursor: pointer;
  padding: 0px 3px;
  color: var(--accordion-icon-secondary);
}


/*-----End Portfolio Details---*/

.right-tab-box .tab-content {
  min-height: 260px;
}

.right-tab-box .tab-content>.tab-pane {
  background-color: transparent;
  padding: 0 10px;
}

.dropdown-style .dropdown-menu {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 0;
}

.custom-search-bar {
  background: none;
  font-size: 14px;
  height: 35px;
  color: var(--text-primary);
  border-radius: 3px;
  padding: 3px 40px;
}

.custom-icon-addon {
  position: absolute;
  left: 10px;
  top: 7px;
  height: 16px;
}

.custom-icon-close {
  position: absolute;
  right: 10px;
  top: 7px;
}

.right-tab-box .form-control, .right-tab-box .form-group .form-control {
  background: none;
}

.text-select-multiple {
  text-align: center;
  width: 100%;
  padding: 10px 0;
  font-size: 12px;
}

.custom-tab-container {
  background-color: var(--tab-horizontal-background-base);
  width: 100%;
  float: left;
  height: auto !important;
}

.custom-tab-container.fullList-scroll {
  height: 83vh;
}

.custom-tab-container.custom-height-style {
  height: 235px !important;
}

.fullList-container {
  height: 233px;
  background: var(--menu-background-base);
  overflow-y: auto;
  overflow: hidden;
  float: left;
}

.fullList-container.list-scroll-box {
  height: 188px !important;
  overflow: hidden;
  overflow-y: auto;
}

.p-tabview-nav .fa-heart {
  color: #ea4a43 /* OPL override */
}

.model-heading {
  font-size: 24px;
  float: left;
  color: var(--link-text-primary);
  margin: 8px 0 0 0;
}

.model-heading i {
  position: relative;
  top: -2px;
  border-radius: 2px;
  background-color: var(--button-background-primary);
  cursor: pointer;
  font-size: 11px;
  padding: 3px 5px;
  color: var(--button-icon-primary);
}

.model-heading .model-id-info {
  font-size: 18px;
  font-weight: 600;
  margin: 0px 10px;
}

.model-heading .model-name-info {
  font-size: 18px;
  font-weight: 600;
  margin: 0px 10px;
}

.model-note {
  color: var(--yellow-600);
  margin-right: 15px;
}

.money-market-model .modal-content .modal-header {
  border-bottom: none;
  padding: 10px;
}

.money-market-model .title-bar {
  color: var(--grid-text-secondary);
  background-color: var(--grid-background-header);
  border-bottom: solid 1px var(--grid-stroke-primary);
  padding: 0px 15px 0 15px;
}

.money-market-model .title-bar .form-group label {
  color: var(--textfield-text-label) !important;
  font-weight: 600;
}

.money-market-model .modal-content .modal-body {
  padding: 0px;
}

.money-market-model .modal-footer {
  background-color: var(--modal-background-base);
}

.money-market-model .title-bar .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px;
}

.label-top-space {
  padding-top: 10px;
}

.money-market-model .form-group input {
  padding: 5px 10px;
  height: 30px;
  margin-top: 5px;
}

.money-market-model .top-space .form-group {
  margin-top: -9px;
}

.money-market-model .form-group input.disabled {
  background-color: var(--textfield-background-disabled) !important;
}

.money-market-model .modal-content .modal-body+.modal-footer {
  padding-top: 10px;
}

.tree-container {
  width: 100%;
  float: left;
  margin-top: 30px;
}

.model-information-form {
  padding: 0 15px;
}

.model-information-form label {
  text-align: left;
  padding-top: 10px;
}

.text-top-space {
  padding-top: 10px;
}

.model-height-200 {
  height: 150px;
}

.models-container {
  padding: 0 0 100px 0;
  float: left;
  width: 100%;
}

.checkbox-space {
  margin-top: 15px;
}

.fullList-container.portfolio-style {
  height: 260px;
  overflow-y: auto;
  float: left;
}

.toggle-side-bar {
  margin-right: -335px;
}

.toggle-content-box {
  width: 100%;
}

.space-top {
  margin-top: 20px;
}

.portfolio-aum {
  border: solid 1px var(--grid-stroke-primary);
  clear: both;
  margin-top: 25px;
  width: 100%;
  float: left;
  background: var(--grid-background-base);
}

.portfolio-aum .responsive-table {
  overflow: auto;
  max-height: 250px;
}

.portfolio-aum.show-hide {
  display: none;
}

.right-tab-box {
  width: 100%;
  position: relative;
  right: 0;
  padding: 0px;
  background-color: var(--slideover-background-base);
  float: right;
}

.portfolio-aum .table {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.table-heading {
  background-color: var(--table-background-header);
  border-top: solid 1px var(--table-stroke-primary);
}

.table-total {
  background-color: var(--table-background-header);
  border-top: solid 1px var(--table-stroke-primary);
  font-size: 11px;
  color: var(--table-text-primary);
}

.portfolio-aum .table>thead>tr>th, .portfolio-aum .table>tbody>tr>th, .portfolio-aum .table>tfoot>tr>th, .portfolio-aum .table>thead>tr>td, .portfolio-aum .table>tbody>tr>td, .portfolio-aum .table>tfoot>tr>td {
  text-align: right;
  padding: 2px 5px;
  font-size: 11px;
  font-weight: 100 !important;
}

.portfolio-aum .table>tbody>tr>td.text-left {
  text-align: left !important;
}

.responsive-table {
  overflow: hidden;
  overflow-x: auto;
  min-width: 100%;
}

.text-aum {
  font-size: 12px;
  text-align: left;
  position: absolute;
  top: 7px;
}

.space-top-10 {
  margin-top: 10px;
}

.select-custom-view {
  background-color: var(--gray-100);
  padding: 15px 5px 10px 5px;
  margin-top: 0px;
}

.select-custom-view label {
  padding-top: 10px;
}

.btn-box {
  margin-top: -10px;
}

.top-space-70 {
  margin-top: 70px;
}

.model-details-info .collapse.in {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.model-details-info .divider {
  margin: 20px 0;
}

.text-50.text-left {
  text-align: left;
}

.model-details-info .panel {
  margin-bottom: 20px;
}

.tags {
  background: var(--gray-100);
  padding: 3px 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 11px;
  line-height: 15px;
  margin: 0 5px 0 0;
}


/*-------------Portfolio Assigned and Teams Access css code end ---------------*/

p.model-dataAvailable {
  margin: 15px 0;
}

.tree-outer {
  position: relative;
  max-width: 400px;
  margin: auto;
}

.tree-outer .mm, .tree-outer .sm1, .tree-outer .sm2, .tree-outer .sm3, .tree-outer .sm4, .tree-outer .sm5, .tree-outer .sm6, .tree-outer .sm7, .tree-outer .level1, .tree-outer .level2, .tree-outer .level3 {
  position: absolute;
}

.tree-outer .mm {
  top: 0;
  left: 50%;
  margin-left: -50px;
}

.tree-outer .sm1 {
  top: 130px;
  left: 0;
}

.tree-outer .sm2 {
  top: 130px;
  right: 0;
}

.tree-outer .sm3 {
  top: 260px;
  left: -55px;
}

.tree-outer .sm4 {
  top: 260px;
  left: 55px;
}

.tree-outer .sm5 {
  top: 260px;
  right: -110px;
}

.tree-outer .sm6 {
  top: 260px;
  right: 0;
}

.tree-outer .sm7 {
  top: 260px;
  right: 110px;
}

.tree-outer .level1 {
  top: 100px;
  left: 50px;
}

.tree-outer .level2 {
  top: 230px;
  left: -10px;
}

.tree-outer .level3 {
  top: 230px;
  right: -70px;
}

.hbox-container {
  position: relative;
  overflow: hidden;
  min-height: 800px;
}

.left-hbox-container {
  min-height: 765px;
}

.right-hbox-container {
  width: 368px;
  min-height: 425px;
  background: var(--slideover-background-base);
  border: 2px solid var(--slideover-stroke-primary);
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  padding: 2px;
  right: 0px;
  position: absolute;
  top: 50px;
}

a.right-hbox, a.right-hbox:focus {
  background: var(--modal-stroke-primary);
  padding: 8px 1px;
  position: absolute;
  top: 35vh;/* locate 1/3'ish of the way down the viewable page */
  left: -21px;
  color: var(--gray-800);
  border-left: 1px solid var(--gray-400);
  border-top: 1px solid var(--gray-400);
  border-bottom: 1px solid var(--gray-400);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tom-right-tab-box .p-tabview-panel {
  padding: 0 8px;
}

.toggle-box-width {
  margin-right: 0;
  width: 100%;
}

.ease {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.btn-bar {
  background: var(--gray-100);
  padding: 0 10px;
}

.btn-bar i {
  font-size: 20px;
  cursor: pointer;
  position: relative;
  top: 3px;
  margin: 0 5px;
}

.btnnone {
  background: none!important;
  border: none!important;
  color: var(--menu-text-primary);
}

.btnnone.active {
  color: var(--menu-icon-active);
}

.right-hbox-container.clone {
  top: -16px;
}

.gridspacer {
  margin: 10px 0
}

.fl-none {
  float: none;
}

.tab-header {
  padding: 10px;
  min-height: 50px;
}

.tab-header .form-group {
  margin-top: 10px;
}

.tab-header .button-space {
  margin-top: 20px;
}

.label-right-space {
  padding-right: 10px;
}


/****************** Table/Grid View **************/

.tree-table-grid .grid {
  max-width: 1140px;
  margin-bottom: 5px;
  margin-right: 5px;
  height: 50px;
  float: left;
}

@media (max-width: 1920px) {
  .tree-table-grid .grid {
    max-width: 1710px;
  }
  .tree-table-grid .level1 .grid {
    width: 94% !important;
  }
}

@media only screen and (min-width:2000px) {
  .tree-table-grid .grid {
    max-width: 100%;
  }
  .tree-table-grid .level1 .grid {
    width: 96% !important;
  }
}

.tree-table-grid .level1 .grid {
  width: 94%;
}

.tree-table-grid .level2 .grid {
  width: 94%;
}

.tree-table-grid .level3 .grid {
  width: 94%;
}

.tree-table-grid .level4 .grid {
  width: 96%;
}

.tree-table-grid .level5 .grid {
  width: 94%;
}

.tree-table-grid [class*='level-'] {
  color: red
}

.tree-table-grid .panel-heading, .tree-table-grid .panel-body {
  padding: 0!important
}

.tree-table-grid .panel-default>.panel-heading {
  color: var(--accordion-icon-secondary)!important;
}

.tree-table-grid .panel-group .panel, .tree-table-grid .panel, .tree-table-grid .panel-default>.panel-heading+.panel-collapse>.panel-body, .tree-table-grid .panel-default>.panel-heading {
  float: left;
  width: 100%;
  border-radius: 0!important;
  border: none!important
}

.tree-table-grid .panel-default>.panel-heading, .tree-table-grid .panel-body {
  background: none!important;
  border: none!important;
}

.tree-table-grid .level1, .tree-table-grid .level2, .tree-table-grid .level3, .tree-table-grid .level4, .tree-table-grid .level5 {
  min-height: 50px;
}

.tree-table-grid .level1 {
  padding: 5px 0 0 20px!important;
  background: var(--gray-50)!important;
}

.tree-table-grid .level2 {
  padding: 5px 0 0 20px!important;
  background: var(--gray-100)!important;
}

.tree-table-grid .level3 {
  padding: 5px 0 0 20px!important;
  background: var(--gray-200)!important;
}

.tree-table-grid .level4 {
  padding: 5px 0 0 20px!important;
  background: var(--gray-300)!important;
}

.tree-table-grid .level5 {
  padding: 5px 0 0 20px!important;
  background: var(--gray-400)!important;
}

.tree-table-grid .panel-title {
  float: left;
}

.tree-table-grid .panel-title>a {
  display: block;
  position: relative;
  text-indent: 20px;
  margin: 5px 0px;
  padding: 0px;
  margin-right: 15px;
}

.tree-table-grid .panel-title>a:focus, .tree-table-grid .panel-title>a:hover {
  color: var(--link-text-hover);
}

.tree-table-grid .row-panel {
  clear: both;
  display: table;
  content: " ";
  width: 100%;
}

.tree-table-grid .panel-title>a:before {
  content: "\f055";
  /* fa-chevron-down */
  font-family: 'Font Awesome 6 Pro';
  position: absolute;
  left: 0;
  text-indent: 0px;
}

.tree-table-grid .panel-title>a[aria-expanded="true"]:before {
  content: "\f056";
  /* fa-chevron-up */
  color: var(--button-background-primary);
}

.tree-table-grid .panel-group {
  margin: 0;
}

.tree-table-grid .panel-group .panel+.panel {
  margin-top: 5px;
}

.tree-table-grid .table.treeview p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  padding: 3px 0;
}

.table.treeview>thead>tr>th, .table.treeview>tbody>tr>th, .table.treeview>tfoot>tr>th {
  background: var(--table-background-header);
  padding: 0 5px;
}

.table.treeview>thead>tr>td, .table.treeview>tbody>tr>td, .table.treeview>tfoot>tr>td {
  background: var(--table-background-base);
  padding: 0 5px;
}

.table.treeview input.form-control {
  height: 25px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
}

.table.treeview select.form-control {
  height: 25px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
}

.w200 {
  width: 170px;
}

.w150 {
  width: 130px;
}

.w100 {
  width: 70px;
}

.w300 {
  width: 270px;
}

.tree-table-grid .panel {
  margin: 0;
}

.hideme {
  display: none;
}

.showme {
  width: 100%;
}

.newsubmodel {
  display: none;
  margin: 0;
}

.newsubmodelshow {
  display: block;
}

.maintenance-pending.tree-table-grid .grid {
  max-width: 755px;
}


/*-------------Global trade tool css code start ---------------*/

.selection-content-container {
  border: solid 1px var(--grid-stroke-primary);
  margin-bottom: 30px;
  margin-top: 20px;
}

.selection-content-container .content-box-left {
  background-color: var(--grid-background-base);
  min-height: 450px;
  overflow: auto;
  padding: 10px;
  border-right: solid 1px var(--grid-stroke-primary);
  position: relative;
}

.selection-content-container .content-box-left label:not(.p-checkbox-label) {
  margin-bottom: 20px;
  width: 100%;
  padding-left: 45px;
}

.table-header {
  background-color: var(--table-background-header);
}

.selection-content-container .content-box-right .search-bar-input {
  position: relative;
}

.selection-content-container .content-box-right .search-bar-input input {
  padding-left: 30px;
}

.selection-content-container .content-box-right .search-bar-input i {
  position: absolute;
  left: 0;
  top: 13px;
}

.data-display-none {
  display: none;
}

.selection-content-container .content-container .content-box-right {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 30px 0 0px;
}

.choose-file-small-box {
  max-width: 300px;
  width: 100%;
  height: 250px;
  color: var(--grid-text-secondary);
  line-height: 30px;
  border: dotted 2px var(--grid-stroke-primary);
  padding: 30px 0;
  margin: 0 0 20px 0;
  text-align: center;
}

.choose-file-small-box i {
  font-size: 80px;
  color: var(--grid-icon-primary);
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

/*.line-space {*/
  /*width: 150px;*/
  /*margin: auto;*/
  /*background: url(../../img/line-space.png) no-repeat left;*/
  /*font-weight: 900;*/
  /*padding: 0 0 0 0px;*/
/*}*/

.top-bottom-30 {
  padding: 30px 0 0 0;
}

.tab-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.icon-addon {
  position: relative;
  color: var(--textfield-icon-primary);
  display: block;
}

.icon-addon.addon-md .form-control, .icon-addon .form-control {
  padding-left: 40px;
  float: left;
  font-weight: normal;
  padding-right: 40px;
}

.icon-addon.addon-md .fas, .icon-addon.addon-md .far{
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -12px;
  text-align: center;
  padding: 10px 0;
  top: 26px;
}

.addon-top-space {
  top: 0px !important;
}

.btn-add {
  margin-top: 20px;
}

.input-menu-style {
  height: 30px;
  font-weight: 100;
}


/*-------------Global trade tool css code end ---------------*/


.compare-icon {
  color: var(--link-icon-primary);
}

.compare-icon-outOfTolerance {
  color: var(--red-400);
}
.model-compare-content {
  max-height: 450px;
  overflow-y: auto;
}

.model-compare-content-footer {
  background: var(--modal-background-base);
  padding: 20px;
  margin: 0px;
}

.trade-order-import-listGrid {
  height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
}


/*------------------Trade Order----------------*/

.tom-right-hbox-container {
  min-height: 555px;
  background: var(--modal-background-base);
  right: -600px;
  position: absolute;
  top: 0;
  z-index: 970; /* appear under Actions popup */
  box-shadow: var(--elevation-700);
  border-radius: 4px;
  border: 1px solid var(--modal-stroke-primary);
}

.tom-right-hbox-container.toggle-box {
  margin-right: -375px;
}

.tom-right-tab-box {
  width: 100%;
  position: relative;
  right: 0;
  padding: 0;
  overflow: hidden;
}


.tom-right-tab-box .tab-content {
  padding: 20px 10px;
}

.tom-right-tab-box .tom-tab-content>.tab-pane {
  background-color: transparent;
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  min-height: 500px;
  height: 80vh;
}

.custom-form-group .addon-group>.custom-icon-addon {
  position: absolute;
  left: 0px;
  top: 28px;
  color: var(--textfield-icon-primary);
  font-size: 14px;
}

.custom-form-group .addon-group>.form-control {
  padding: 7px 25px;
  font-size: 14px;
  height: 30px;
}

.custom-form-group>.form-control {
  height: 30px;
  padding: 0px;
  font-size: 14px;
}

.custom-form-group .checkbox label, .custom-form-group .radio label {
  font-size: 14px;
}

.tom-right-hbox-container .form-group {
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
}

.custom-form-group>label {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}


/* Webkit */

.custom-form-group .form-control::-webkit-input-placeholder {
  color: var(--textfield-text-placeholder);
  font-size: 14px;
}


/* Firefox 4-18 */

.custom-form-group .form-control:-moz-placeholder {
  color: var(--textfield-text-placeholder);
}


/* Firefox 19+ */

.custom-form-group .form-control::-moz-placeholder {
  color: var(--textfield-text-placeholder);
}


/* IE10+ */

.custom-form-group .form-control:-ms-input-placeholder {
  color: var(--textfield-text-placeholder);
}

.form-group.is-focused .custom-form-group .radio label:hover {
  color: var(--textfield-text-label);
}

.form-group.is-focused .radio label {
  color: var(--radio-text-label);
}

.custom-form-group .radio label .circle {
  border: 2px solid var(--radio-stroke-primary);
  height: 15px;
  width: 15px;
  border-radius: 100%;
}

.custom-form-group .radio label {
  padding-left: 25px;
}

.custom-form-group .radio label span {
  left: 0px;
}

.custom-form-group .radio, .custom-form-group .checkbox {
  margin: 0px;
  padding: 0px;
}

.custom-form-group .checkbox .checkbox-material {
  position: relative;
  top: 0px;
  margin-right: 5px;
}

.custom-form-group .checkbox .checkbox-material .check {
  border: 1px solid var(--checkbox-stroke-primary);
  width: 15px;
  height: 15px;
  position: relative;
  top: 0px;
}

.custom-form-group label.font-size {
  font-size: 13px !important;
}

.custom-form-group label {
  margin-right: 10px;
  font-size: 14px;
}

.form-group.is-focused .custom-form-group label:hover {
  color: var(--textfield-text-label);
}

.form-group.is-focused .custom-form-group label {
  color: var(--textfield-text-label);
}

.custom-form-group .btn-reload {
  padding: 5px 8px;
  margin: 0px;
  position: absolute;
  right: 0px;
}

.height10x {
  height: 1000px;
}

#QuickTrade {
  width: 580px;
}

#ModelTolerance {
  width: 535px;
}

#ModelAnalysis {
  width: 610px;
}

#ExcelImport {
  width: 500px;
}

#context-dd-menu-list {
  position: fixed;
  display: none;
}

#context-dd-menu {
  position: absolute;
  display: none;
}

#context-dd-menu>ul.dropdown-menu, #context-dd-menu-list>ul.dropdown-menu {
  display: block !important;
}

#context-dd-menu>ul.dropdown-menu>li, #context-dd-menu-list>ul.dropdown-menu>li {
  overflow: inherit !important;
}

#context-dd-menu>ul.dropdown-menu>li.dropdown-submenu ul.dropdown-menu {
  position: absolute;
  left: -230px;
  top: 0px;
}

#context-dd-menu>ul.dropdown-menu>li.dropdown-submenu:hover ul.dropdown-menu {
  display: block;
}

.subDropDownmenu {
  left: -224px !important;
  height: 60px;
  width: 224px !important;
}


/*----- Notifications ---*/

.notification-right-hbox-container {
 min-height: 500px;
  background: var(--gray-100);
  right: -406px;
  position: absolute;
  margin-right: 0;
  top: 0px;
  z-index: 993;
  height: 100%;
}
.sm-wrapped-page .notification-right-hbox-container {
  right: -135px;
}


.notification-right-tab-box .tab-content>.tab-pane {
  padding: 0px;
  height: 75.8vh;
}

.noti-box-header {
  height: 40px;
  background-color: var(--slideover-background-header);
  margin: 0px;
  padding: 0px 20px;
}

.noti-box-header h1 {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  line-height: 40px;
  color: var(--slideover-text-primary);
}

.notibar-menu {
  margin: 0px;
  padding: 0px;
  float: right;
  display: block;
}

.notibar-menu ul>li {
  list-style-type: none;
  float: left;
  font-size: 14px;
  color: var(--textfield-text-label);
  line-height: 36px;
  padding: 0px 15px;
}

.notibar-menu ul>li>a {
  color: var(--link-text-primary);
}

.notibar-menu .form-group {
  margin: 0px;
  padding: 0px;
}

.notibar-menu .form-group.is-focused select.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

.notibar-menu select.form-control {
  color: var(--select-text-primary);
  font-size: 14px;
  margin: 0px;
  border: none;
}

.notibar-menu ul>li>a:hover, .notibar-menu ul>li>a:focus {
  color: var(--link-text-hover);
  text-decoration: none;
}

.notibar-menu .dropdown-menu {
  border-radius: 0px;
  min-width: 100px;
  left: 30px;
}

.notibar-menu .dropdown-menu li {
  padding: 0px;
  margin: 0px;
  float: none;
}

.notibar-menu .dropdown-menu li a:hover {
  color: var(--link-text-hover);
}

.custom-search-bar {
  background-image: none !important;
}

.form-group.is-focused .custom-search-bar {
  background-image: none;
  border: none;
}

.notifications {
  min-width: 420px;
}

.notifications-wrapper {
  overflow: auto;
  padding: 10px 10px;
  height: 72vh;
}

.notification-footer {
  text-align: center;
}

.notification-footer a {
  color: var(--link-text-primary);
  font-size: 14px;
  display: inline-block;
  margin: 10px 0px 0px;
  font-weight: 500;
}

.notification-footer a:hover, .notification-footer a:focus {
  color: var(--link-text-hover);
  text-decoration: none;
}

.fa-arrow-alt-circle-right {
  margin-left: 10px;
}

.notification-heading, .notification-footer {
  padding: 2px 10px;
}

.notifications-wrapper a.notification-item-list {
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.notifications-wrapper a.notification-item-list:hover {
  text-decoration: none;
}

.notification-item {
  padding: 5px;
  position: relative;
}

.notifications-wrapper .p-card {
  margin: 0 .1rem .25rem .1rem;
}

.notifications-wrapper .p-card:hover {
  background-color: var(--list-background-hover);
}

.notifications-wrapper .p-card .p-card-content {
  padding: 0;
}

.item-title {
  font-size: 14px;
  color: var(--list-text-secondary);
  font-weight: 500;
  margin: 0px;
  padding: 0px;
}


/*unread Notification*/

.item-title.unread {
  font-weight: 600;
  color: var(--list-text-primary);
}

.item-title i.fa {
  position: relative;
  top: 0px;
  left: 0px;
  font-size: 18px;
  height: 30px;
  width: 30px;
  float: left;
}

.item-time-stamp {
  color: var(--list-text-secondary);
  font-size: 13px;
  margin-left: 25px;
}

.chat-box-single-line {
  border-bottom: 1px solid var(--gray-100);
  height: 12px;
  margin: 7px 0 15px;
  position: relative;
  text-align: center;
}

abbr.items-date-stamp {
  color: var(--list-text-primary);
  padding: 0 11px;
}

.item-slide-menu {
  margin: 0px;
  padding: 0px;
  position: absolute;
  z-index: 99;
  right: -90px;
  top: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.notification-item-full .item-slide-menu{
  top: 22px;
}
.item-slide-menu button {
  margin: 0px 12px;
  padding: 0px;
  font-size: 16px;
  background: none;
  border: none;
}

.item-slide-menu .btn-read {
  color: var(--button-background-primary);
}

.item-slide-menu .btn-trash {
  color: var(--button-background-destructionary);
}

.notification-item-list:hover .item-slide-menu {
  opacity: 1;
  transform: translateX(-90px);
  -webkit-transform: translateX(-90px);
  -moz-transform: translateX(-90px);
  -ms-transform: translateX(-90px);
  -o-transform: translateX(-90px);
}

.simple-notification-wrapper{
  z-index: 9999 !important;
}
.notification-item-full {
  padding: 10px 18px 5px;
  margin: 1px 0px;
  position: relative;
}

.notification-item-full h4 {
  font-size: 15px;
  color: var(--text-primary);
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  margin-bottom: 3px;
}

.notification-cat {
  font-size: 14px;
  color: var(--text-secondary);
  font-weight: 600;
  width: 80%;
  word-wrap: break-word;
}

.notification-item-full h4 i.fa {
  position: relative;
  top: 0px;
  left: 0px;
  font-size: 32px;
  width: 50px;
  float: left;
}

.notification-item-full .item-time-stamp {
  float: right;
  margin-bottom: 3px;
}

.notification-item-full .item-time-stamp span {
  margin-left: 5px;
}

.notification-item-full p {
  margin-top: 5px;
  word-break: normal;
  color: var(--text-primary);
}

.notification-preferences {
  padding: 10px 18px 5px;
  margin: 1px 0px;
  position: relative;
}

.noti-per-row {
  margin-bottom: 20px;
}

.noti-per-content {
  margin: 0px;
  padding: 0px;
  position: relative;
}

.noti-per-content a {
  margin: 0px;
  padding: 0px;
  position: absolute;
  display: block;
  top: 0px;
  right: -15px;
}

.add-task-btn {
  display: block;
  text-align: right;
  margin-bottom: -7px;
}

.notification-list-actions {
  background: var(--gray-100);
  margin: -10px -10px 0px;
  padding: 4px 0px;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

/*-------------Model Import PopUp css code Start ---------------*/

.choose-file-small-box.file-upload-input-style {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.model-width-style {
  width: 700px;
}

.file-successfully-imported {
  border: none;
  font-weight: bold;
  padding: 60px 0;
}

.file-successfully-imported i.fa-check-circle {
  color: var(--green-300);
  font-size: 20px;
  position: relative;
  top: 3px;
}

.import-log {
  border: none;
  font-weight: bold;
  color: var(--red-500);
}

.import-log i.fa-exclamation-triangle {
  color: var(--red-500);
  font-size: 20px;
  position: relative;
  top: 3px;
}

.download-format {
  font-weight: 600;
}

.import-log-box {
  background-color: var(--white);
  padding: 10px;
  color: var(--red-500);
  max-height: 100px;
  overflow: auto;
}

.import-log-box p {
  margin: 0px;
  padding: 0px!important;
}

.btn-list-outer {
  min-height: 220px;
  background-color: var(--gray-100);
  overflow: auto;
  width: 100%;
  padding: 10px;
}


/*---------------- Model Import PopUp css code Start -----------------*/

.custom-checkbox input[type="checkbox"] {
  margin-left: -38px;
  margin-right: 20px;
}

.agcontainer {
  width: 100%;
  height: 100%;
}

/* PROGRESS BAR - OVERRIDES*/

.running-bar {
  background: var(--toast-background-base);
  color: var(--toast-text-primary);
  padding: 15px;
  border: 1px solid var(--toast-stroke-primary);
  border-radius: 3px;
  position: fixed;
  right: 0px;
  left: 0px;
  bottom: 20px;
  max-width: 600px;
  margin: 0px auto;
  transition: all 10ms;
  -webkit-transition: all 10ms;
  -moz-transition: all 10ms;
  z-index: 9999;
  width: 90%;
}

.running-bar .progress {
  margin-bottom: 0px;
}

.running-bar .progress {
  width: 100%;
  margin-top: 5px;
}

.running-bar-circle {
  left: 320px !important;
  right: inherit;
}

.running-bar-circle {
  width: 32px !important;
  height: 32px !important;
  overflow: hidden;
  border-radius: 50%;
  transition: all 10ms;
  -webkit-transition: all 10ms;
  -moz-transition: all 10ms;
  z-index: 9999;
}

.progress-close {
  position: absolute;
  top: 6px;
  right: 10px;
  font-size: 12px;
  color: var(--yellow-500);
}

.progress-close:hover {
  color: var(--yellow-300);
}

.progress-close:focus {
  color: var(--yellow-600);
}

.running-bar-circle .row {
  display: none;
}

.running-bar-circle .progress-close {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.running-bar .p-progressbar {
  height: 4px;
  margin-top: 4px;
}

.running-bar .p-progressbar-label {
  display: none !important;
}

/*Bottom Progressbar*/


/*-------------SMA-wightings-popup PopUp css code Start ---------------*/

.sma-wightings-popup {
  max-width: 800px;
  width: 100%;
}

.sma-wightings-popup .modal-body {
  padding-top: 0px;
}

.add-row-text {
  font-size: 17px;
  margin-top: 20px;
  display: inline-block;
  max-width: 300px;
  float: left;
}

.add-row-text i.fa-plus-circle {
  font-size: 30px;
  position: relative;
  top: 5px;
  left: 10px;
}

.cate-lable {
  position: relative;
  top: 0;
  right: 20px;
}

.fixed-input-width {
  max-width: 100px;
  padding: 5px 0 0 0 !important;
}

.fixed-select-width {
  max-width: 250px;
}

/*-------------SMA-wightings-popup PopUp css code Start ---------------*/

.team-access {
  max-height: 230px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

table.table-small>tbody>tr>td, table.table-small>thead>tr>th {
  padding: 0px 5px !important;
  font-weight: normal !important;
  border: none !important;
}


/****************tactical tool new styles start**************/

.tactical-custom-table > thead > tr > th, .tactical-custom-table > tbody > tr > th, .tactical-custom-table > tfoot > tr > th, .tactical-custom-table > thead > tr > td, .tactical-custom-table > tbody > tr > td, .tactical-custom-table > tfoot > tr > td {
  border-top: none !important;
  border-bottom: none !important;
}

.targetu {
  width: 927px;
}

.targetv1 {
  width: 1010px;
}

.targetv2 {
  width: 1005px;
}

.targetv3 {
  width: 1000px;
}

.targetv4 {
  width: 995px;
}

.targetv5 {
  width: 1115px;
}

.mu-target1 {
  width: 798px;
}

.mu-target2 {
  width: 793px;
}

.mu-target3 {
  width: 788px;
}

.mu-target4 {
  width: 783px;
}

.mu-target5 {
  width: 1310px;
}

.mu-target6 {
  width: 1072px;
}

.mu-targetu {
  width: 817px;
}

.targetv6 {
  width: 1072px;
}

td.current {
  min-width: 1015px !important;
}

td.current span {
  width: 106px;
  float: left;
}

.td-left-padding-none {
  padding-left: 0px !important;
}

.tactical-custom-table > thead > tr > th {
  padding: 2px 15px;
  line-height: 1.42857143;
}

.tactical-custom-table > tbody > tr > td {
  padding: 3px 15px;
  line-height: 1.42857143;
  font-weight: 400;
  font-size: 14px;
  font-family: arial serif;
  min-width: 110px;
}

.tactical-custom-table > tbody > tr > td.icon-setting {
  min-width: 10px;
  width: 10px;
  padding-right: 5px;
}

.tactical-custom-table span.actionicon-setting {
  background: none;
  margin: 0 5px;
  border: solid 1px rgba(255, 255, 255, .4);
  padding: 0 5px;
  border-radius: 2px;
  height: 20px;
  width: 20px;
}

.tactical-custom-table span.actionicon-setting i{color: rgba(255, 255, 255, .4);}
.tactical-custom-table span.actionicon-setting:hover i{ color: var(--textfield-icon-primary);}

.tactical-custom-table span.actionicon-setting:hover {
  border: solid 1px var(--grid-icon-hover);
}

.actionicon-setting .action-drop {
  padding: 0px !important;
  margin: 0px;
}

.tactical-custom-table > tbody > tr > th {
  padding: 5px 10px;
  line-height: 1.42857143;
  font-weight: normal;
  font-size: 12px;
}
.tactical-custom-table > tbody > tr > td small{color: var(--table-text-primary); font-size: 10px;}

.table-cat-title {
  font-size: 13px;
  font-weight: bold;
  margin: -12px 0px 8px 0px;
  padding: 0px;
  color: var(--table-text-secondary);
}

.table-row-bg-3, .table-row-bg-3 > td {
  background: var(--table-background-base);
}

.table-row-bg-5, .table-row-bg-5 > td {
  background: var(--table-background-base);
}

.text-warning-light td {
  color: var(--textfield-text-error) !important;
}

.text-warning-light {
  color: var(--textfield-text-error) !important;
}

.table-td-border-left {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.table-td-border-bottom {
  border-bottom: 1px solid var(--table-stroke-primary);
}

.table-td-border-left-dark {
  border-left: 1px solid var(--table-stroke-primary);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.add-plus-btn {
  margin: 0px 0px 0px -8px;
  padding: 0px;
  font-size: 13px;
  color: var(--table-text-primary);
  cursor: pointer;
}

a.add-plus-btn:hover, a.add-plus-btn:focus {
  color: var(--button-background-primary-hover) !important;
  background-color: transparent !important;
}

.text-warning > td, .text-warning > .add-plus-btn {
  color: var(--button-background-destructionary) !important;
}

.tactical-custom-table .collapse.inbox {
  background: var(--table-background-base);
}

.table-step-1 .inboxactive {
  background: var(--table-background-header);
}

.table-step-1 .inboxactive, .table-step-1 .inboxactive > td {
  background: var(--table-background-base);
}

.tactical-custom-table .collapsing.inbox {
  background: var(--table-background-base);
}

.tactical-custom-table tr.collapse.in>td {
  padding: 5px 0 0px 5px !important;
}

.tactical-custom-table tr.collapsing>td {
  padding: 5px 0 5px 5px;
}

.tactical-custom-table tr td .form-control {
  height: 20px;
  font-size: 13px;
  font-weight: 600;
}

.collapse.in > td > table > thead > tr > th {
  padding: 2px 15px;
  line-height: 1.42857143;
  font-size: 13px;
  font-weight: bold;
}

.collapsing > td > table > thead > tr > th {
  padding: 7px 15px;
  line-height: 1.42857143;
  font-size: 13px;
  font-weight: bold;
}

.tactical-custom-table {
  margin: 0px;
  padding: 0px;
}

.left-offset-col-2 {
  margin-left: -15px;
}

.right-offset-col-4 {
  margin-right: -30px;
}

.tactical-wrap-header {
  margin: 0px;
  padding: 0px;
  position: relative;
}

.tactical-wrap-header a {
  height: 24px;
}

.tactical-action-group .btn {
  margin: 0px;
  padding: 0px;
}

.account-realized-gain-box {
  padding: 8px;
  margin: 0px;
  margin-bottom: 20px;
}

.account-portfolioAUM-table {
  margin: 0px;
  padding: 0px;
}

.account-portfolioAUM-table > thead > tr > th {
  margin: 0px;
  padding: 10px 15px;
  background: var(--table-background-header);
  font-weight: 700;
  border-bottom: 0px !important;
}

.account-portfolioAUM-table > tbody > tr > td {
  padding: 4px 5px;
  border-bottom: 1px solid var(--table-stroke-primary);
  border-top: none !important;
  font-size: 12px;
  font-weight: normal;
}

.account-portfolioAUM-table > tbody > tr > td > span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0px 1.5% 0px 0px;
  margin-bottom: -2px;
}

.account-portfolioAUM-table > tfoot > tr > th {
  margin: 0px;
  padding: 10px 0px;
  font-weight: 700;
  border-bottom: 0px !important;
  color: var(--table-text-secondary) !important;
}

.tactical-custom-table tr.collapse.in>td {
  padding: 5px 0 5px 5px;
}

.view-model-btn {
  float: left;
  text-align: center;
}

.tactical-custom-table > tbody > tr > td.no-left-padding {
  padding-left: 0px;
}

.choose-file-small-box.file-upload-input-style {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.model-width-style {
  width: 700px;
}

.label-top-space {
  padding-top: 30px;
}

.ellipsis-value {
  position: absolute;
  text-overflow: ellipsis;
  overflow: hidden;
  right: 15px;
  white-space: nowrap;
  width: 60px;
}

.ellipsis-value1 {
  position: absolute;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 60px;
}

.td-relative {
  position: relative;
}

.td-relative .tooltip {
  top: 10px;
  margin-top: 10px;
  white-space: nowrap;
}

input[type="radio"].checkbox-left-space, input[type="checkbox"].checkbox-left-space {
  margin: 4px 2px 0;
  margin-top: 1px \9;
  line-height: normal;
}

.dashboard-content.fixed-width {
  width: calc(100% - 250px);
}

.panel-heading.fixed-height {
  padding: 4px 20px !important;
}

.top-space-15 {
  padding-top: 15px;
}

.bottom-space-15 {
  padding-bottom: 15px;
}

.min-div-width {
  min-width: 100px;
  width: auto;
  padding: 0 20px;
  float: left;
  padding-left: 10px;
  font-size: 12px;
}

.custom-td-width {
  width: 80px;
}


/****************tactical tool new styles end**************/

.height-auto {
  height: auto !important;
  padding-bottom: 50px;
}

.torder-excellist-table {
  border: 1px solid #302e2e;
}

.torder-excellist-table>thead>tr>th, .torder-excellist-table>tbody>tr>th, .torder-excellist-table>tfoot>tr>th, .torder-excellist-table>thead>tr>td, .torder-excellist-table>tbody>tr>td, .torder-excellist-table>tfoot>tr>td {
  border: 1px solid #302e2e;
  padding: 1px 10px;
}

.torder-excellist-table>tbody>tr>td {
  font-weight: normal;
}

.torder-excellist-table>thead>tr>th {
  font-weight: 600;
}

.torder-excellist-table>tbody>tr>td.danger {
  background-color: var(--alert-background-error);
}


/*Model right hand side panel styles */

.overflow-hidden {
  overflow: hidden !important;
  padding-bottom: 30px !important;
}

.tab-tswap-pane {
  padding: 20px 20px 0px 20px;
}

.text-gray {
  color: var(--gray-600);
}

.word-wrap {
  word-wrap: break-word
}

.dialog-body .closeChild {
  float: left;
  margin-right: 6px;
  margin-top: 2px;
}

#modelview_container {
  min-height: 600px !important;
}

.rebalance_lambda_access {
  margin-top: 18px;
  float: left;
}

/*-------------Query builder css code start ---------------*/

input.full-width {
  width: 100%;
}

.input-2x-width {
  width: 23% !important;
}

.input-3x-width {
  width: 35% !important;
}

.input-calendar {
  width: 35%;
}

.tab-ellipsis {
  width: 175px;
}

.tab-ellipsis a {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: -4px !important;
}

.querybuilder-list {
  overflow: auto;
  overflow-x: hidden;
  max-height: 414px;
  flex: 0 0 25vw;
}

.choose-file-small-box.file-upload-input-style {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 15px;
}

.model-width-style {
  width: 700px;
}

.label-top-space {
  padding-top: 30px;
}

.inner-addon input {
  padding-left: 30px;
}

.modal-footer .btn + .btn {
  margin: 10px 1px;
  margin-left: 5px;
}

#QuickColumnSelector .modal-dialog {
  max-width: 100%;
  width: 750px;
}

.available-column, .selected-column {
  height: 200px;
  overflow: auto;
  padding: 10px;
}

.available-column select, .selected-column select {
  margin: 0px;
  padding: 0px;
  height: 99%;
  overflow: auto;
  border: 1px solid var(--select-stroke-primary);
  width: 100%;
}

.selected-column ul {
  margin: 0px;
  padding: 0px;
  min-height: 175px;
  overflow: auto;
}

.available-column select {
  margin: 0px;
  padding: 0px;
  min-height: 136px;
  overflow: auto;
  width: 100%;
}

.available-column ul li, .selected-column ul li {
  list-style: none;
  display: block;
  margin: 0px;
  padding: 3px 10px;
}

.arrow-box {
  margin-top: 45px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.available-column .custom-search-bar {
  border: solid 1px var(--textfield-stroke-primary);
}

.available-column .top-group-space {
  margin-top: 0px;
}

.arrow-updown {
  margin-top: 45px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
}

.table-header {
  background-color: var(--table-background-header);
}

.tree {
  min-height: 20px;
}

.tree .QB-list-group {
  margin: 0px;
  padding: 0px;
}

.tree .QB-list-group-list {
  list-style-type: none;
  margin: 10px 4px 0 0;
  padding: 5px 0px 10px 5px;
  border-radius: 5px;
  position: relative;
  border: solid 1px var(--grid-stroke-primary);
  border-left: solid 20px var(--grid-stroke-primary);
}

.tree .QB-list-group-list:first-child {
  border-right: solid 1px var(--grid-stroke-primary);
}

.tree .QB-list-group-list::after {
  border-top: 1px solid #999;
  height: 20px;
  top: 25px;
  width: 25px
}

.tree > .QB-list-group >.QB-list-group-list::before, .tree > .QB-list-group > .QB-list-group-list::after {
  border: 0
}

.tree .QB-list-group-list:last-child::before {
  height: 30px
}

.add-Data-Query {
  background-color: var(--table-background-base);
  padding: 0 15px !important;
  border: solid 1px var(--table-stroke-primary);
  margin: 10px 4px 0 0 !important;
  border-radius: 5px;
}

.toggle-heart-icon {
  color: var(--gray-400);
  cursor: pointer;
}

.toggle-heart-icon.top-space {
  padding-top: 20px;
}

.heart-color-toggle {
  color: var(--red-400);
  cursor: pointer;
}

.conditions {
  background: var(--gray-200);
}

.conditions label.top-space {
  padding-top: 15px;
}

.conditions .form-group {
  margin-top: 10px;
}

/*-------------Query builder css code end ---------------*/

.table-fixed thead {
  width: 97%;
}

.table-fixed tbody {
  max-height: 195px;
  overflow-y: auto;
  width: 100%;
  height: auto;
}

.table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
  display: block;
}

.table-fixed tbody td, .table-fixed thead > tr> th {
  float: left;
  border-bottom-width: 0;
}

.grid-striped {
  padding: 0px;
  border: 1px solid var(--table-stroke-primary);
}

.grid-striped > thead > tr > th {
  background: var(--table-background-header);
  color: var(--table-text-secondary);
  margin: 0px;
  padding: 3px 15px;
  border-left: 1px solid var(--table-stroke-primary);
  border-bottom: 1px solid var(--table-stroke-primary);
}

.grid-striped > thead > tr > th:first-child {
  border-left: none;
}

.grid-striped > tbody > tr > td {
  color: var(--table-text-primary);
  margin: 0px;
  padding: 2px 15px;
  border: none;
  font-weight: 500;
  border-left: 1px dotted var(--table-stroke-primary);
}

.grid-striped > tbody > tr > td:first-child {
  border-left: none;
}

.grid-striped > tbody > tr:nth-child(odd) > td {
  color: var(--table-text-primary);
  background: var(--table-background-base);
}

.grid-striped > tbody > tr:nth-child(even) > td {
  background: var(--table-background-base);
  color: var(--table-text-primary);
}

a.ui-menuitem-link.ui-corner-all.ng-star-inserted {
  text-decoration: none;
}

.form-group-radio {
  margin: 0px;
  padding: 0px;
}

.form-group-radio .radio {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

.account-list {
  list-style: none;
}

.label-top-space {
  padding-top: 15px;
}


/*------TOM Allocations----*/

label.allocation-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary);
}

.form-control-width .form-control {
  width: 80px;
}

.selection-Header {
  font-size: 16px;
  font-weight: 400;
}

.editBlock-modal-body {
  height: 380px;
  overflow-x: hidden;
  overflow-y: auto;
}

.white-space {
  white-space: nowrap;
}

.text-yellow {
  color: var(--yellow-400);
}

.form-group input.ui-state-default[type="text"], .form-group input.ui-state-default[type="text"] {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  color: var(--text-primary) !important;
}


/*custom P-Dialog*/

.custom-dialog .ui-dialog-content {
  min-height: auto !important;
  overflow: visible !important;
}

/* vertical panel */

.panel-container-vertical {
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  position: relative;
  bottom: -2px;
}

.panel-top {
  padding: 0 0 0 0px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

.panel-bottom {
  flex: 1 1 auto;
  padding: 0px;
  max-height: 400px;
  min-height: 50px;
  z-index: 1;
}

/*Toggle hide-show and Hover */

.custom-label {
  margin: 0px;
  padding: 0px;
}

.col-pad-0 [class*="col-"] {
  padding: 0px !important;
}

.col-pad-0 .col-first {
  padding-left: 15px !important;
}

.authenticate {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.form-horizontal .custom-form-group {
  margin-right: -15px;
  margin-left: -15px;
}

.custom-form-group label.control-label {
  font-size: 14px;
  line-height: 1.07142857;
  color: var(--textfield-text-label);
  font-weight: 400;
  margin: 16px 0 0 0;
}

.custom-form-group input.form-control {
  margin-top: 10px;
}

.form-multiSelect .ui-multiselect {
  margin-top: 8px;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  background: transparent !important;
}

.ui-dialog .ui-dialog-content, .ui-dialog {
  overflow: initial !important;
}

.ui-dialog-scrollable .ui-dialog-content {
  overflow: auto !important;
}

#tradetoolPopup > .ui-dialog {
  top: 30px !important;
}

.available-community-list {
  max-height: 280px;
  overflow: auto;
}

.status-icon {
  margin: 0px;
  padding: 0px;
  display: block;
}

.status-icon .fas, .status-icon .far {
  font-size: 16px;
}

.account-status-icon {
  margin: 0px;
  padding: 0px;
}

.account-status-icon .fas, .account-status-icon .far {
  font-size: 16px;
}

.export-error {
  position: relative;
  text-align: center;
  left: 0px;
  right: 0px;
  margin-bottom: -12px;
  color: var(--textfield-text-error);
}

.db-message-bar {
  background: var(--white);
  padding: 15px;
  border: 1px solid var(--toast-stroke-primary);
  border-radius: 3px;
  position: fixed;
  right: 0px;
  left: 0px;
  top: 20px;
  width: 510px;
  margin: 0px auto;
  transition: all 10ms;
  -webkit-transition: all 10ms;
  -moz-transition: all 10ms;
  z-index: 9999;
}

.db-message-bar ul {
  position: absolute;
  top: 6px;
  right: 10px;
  font-size: 12px;
  color: var(--orange-500);
}

.db-message-bar ul li {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  float: left;
}

.db-message-bar ul li a {
  padding: 0px;
  text-decoration: none;
  font-size: 13px;
  margin: 0px 6px;
}

.db-message-bar ul li a.bar-close {
  color: var(--red-400);
}

.db-message-bar-circle {
  width: 32px !important;
  height: 32px !important;
  overflow: hidden;
  border-radius: 100%;
  transition: all 10ms;
  -webkit-transition: all 10ms;
  -moz-transition: all 10ms;
  right: 0;
  z-index: 9999;
  top: 13px;
  left: 720px;
}

.db-message-bar-circle .row {
  display: none;
}

.progress-close:hover {
  color: var(--menu-icon-active);
}

.progress-close:focus {
  color: var(--menu-icon-hover-active);
}

.modelstructure_audit {
  height: 50px;
}

.modelStructure_auditlabel {
  position: relative;
  top: 30px;
}

.modelHistory_audit {
  height: 30px;
}

.modelHistory_auditlabel {
  position: relative;
  top: 10px;
}


.accountHistory_audit {
  height: 30px;
}

.accountHistory_auditlabel {
  position: relative;
  top: 10px;
}

.fifty_px_audit {
  height: 50px;
}

.thirty_px_auditlabel {
  position: relative;
  top: 30px;
}


.portfolioHistory_audit {
  height: 30px;
}

.portfolioHistory_auditlabel {
  position: relative;
  top: 10px;
}

.add-btn-link {
  margin: 0px;
  padding: 0px;
  background: transparent;
}

.trade-parameters {
  height: 400px;
  overflow: auto;
  overflow-x: hidden;
}

.security-names {
  text-decoration: none !important;
}

.submodel-child-form {
  margin-right: -14px;
  margin-left: -14px;
  overflow: hidden;
}

.submodel-child-form .form-group {
  margin: 0px;
}

.submodel-child-form label {
  width: 150px;
  padding-right: 15px;
}

.submodel-child-table {
  margin-right: -14px;
  margin-left: -14px;
  overflow: auto;
  max-height: 300px;
  overflow-x: hidden;
}

.submodel-child-custom-table, .submodel-child-custom-table table {
  margin: 0px;
  width: 100%;
  padding: 0px;
}

.tabletr-step-1 {
  background: var(--table-background-base);
}

.tabletr-step-2 {
  background: var(--table-background-base);;
}

.tabletr-step-3 {
  background: var(--table-background-base);;
}

.submodel-child-custom-table > thead > tr > th {
  padding: 3px 15px;
  line-height: 1.42857143;
  font-weight: 400;
  font-size: 14px;
  font-family: arial serif;
  min-width: 110px;
  background: var(--table-background-header);
}

.submodel-child-custom-table > tbody > tr > td {
  padding: 3px 5px;
  line-height: 1.42857143;
  font-weight: 400;
  font-size: 14px;
  font-family: arial serif;
  min-width: 110px;
}

.submodel-child-table-step2 {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.submodel-child-custom-table tbody tr.collapse.in td {
  padding: 3px 5px;
}

.submodel-child-custom-table td.tdlabel, .submodel-child-custom-table th.tdlabel {
  width: 250px;
}

.submodel-child-table-step2 td.tdlabel {
  width: 244px;
}

.submodel-child-custom-table > tbody > tr > td a.icon-expand {
  margin: 0px;
  margin-right: 5px;
}

.submodel-child-custom-table > tbody > tr > td .form-control {
  padding: 0px;
  height: 20px;
  width: 80px;
  font-size: 14px;
}

/* for chrome */

.disable-scroll::-webkit-inner-spin-button, .disable-scroll::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* for mozilla */

.disable-scroll {
  -moz-appearance: textfield;
}

.text-warning-grid {
  color: var(--textfield-text-error) !important;
}

.update-equivalence {
  margin: 0px;
  padding: 0px;
}

.update-equivalence .form-inline label {
  font-size: 14px;
  display: inline;
  margin-right: 10px;
}

.update-equivalence .form-control {
  padding: 0px 10px 0px 0px;
  font-size: 14px;
  width: 100%;
}

.update-equivalence fieldset.scheduler-border {
  border: 1px groove var(--table-stroke-primary) !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 0.5em 0 !important;
  box-shadow: var(--elevation-400);
}

.update-equivalence legend.scheduler-border {
  font-size: 1.0em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
  color: var(--text-primary);
  margin-bottom: 0px;
}

.update-equivalence .search-securities {
  background: var(--gray-100);
  padding: 0px 0px;
  margin-bottom: 10px;
}

.remainPercentage {
  margin-left: 5%;
}

#ui-datepicker-div{
  z-index: 9999 !important;
}

.grid-bottom-readText{
  position: fixed;
  bottom: 0px;
  margin: 0px;
  padding: 0px;
}

/** Multi select preferences scroll bar */
.mulitilevel-pref-group{
  height: 315px
}

.modal-table table tr td{
  height: 44px;
}
.form-group > p{
  word-wrap: break-word;
}

.fullWidth-grid-parent .grid-control{
  width: 100%;
}

.trade-parameters table{
  word-wrap: break-word;
  table-layout: fixed;
  width: 100%;
}

.trade-parameters table tr td p{
  word-wrap: break-word;
  white-space: inherit;
}

.trade-parameters table tr td{
  word-wrap: break-word;
  white-space: inherit;
}

.connect-msg{
  position: absolute;
  margin: 0px auto;
  left: 260px;
  right: 0px;
  width: 230px;
  padding: 0px;
  top: 19px;
  text-align: center;
}

.table-fixed-header{
  width: 100%;
}
.table-fixed-header tbody{
  height:200px;
  overflow-y:auto;
  width: 100%;
}
.table-fixed-header thead, .table-fixed-header tbody, .table-fixed-header tr, .table-fixed-header td, .table-fixed-header th{
  display:block;
}
.table-fixed-header tbody td{
  float:left;

}
.table-fixed-header thead tr th{
  float:left;
  background: var(--table-background-header);
}
.equivalence-group-main{
  overflow-y: hidden !important;
  display: inline-block;
  z-index: 999999;
  position: static;
  overflow-x: hidden !important;
  top: 20px !important;
}
.equivalence-group{
  overflow-y: scroll !important;
  display: inline-block;
  z-index: 999999;
  overflow-x: hidden !important;
  height: 450px;
  width: 100%;
  position: relative;
  padding-right: 12px;
}
.equivalence-group-button{
  margin: 0px;
  width: 100%;
  display: inline-block;
  padding: 0 20px;
  overflow: hidden;
}
.equivalence-add-new{
  padding-top: 14px;
}
.equivalence-group-main{
  overflow-y: hidden !important;
  display: inline-block;
  z-index: 999999;
  position: static;
  overflow-x: hidden !important;
  top: 20px !important;
}
.equivalence-group{
  overflow-y: scroll !important;
  display: inline-block;
  overflow-x: hidden !important;
  height: 450px;
  width: 100%;
  position: relative;
  padding-right: 12px;
}
.equivalence-group-button{
  margin: 0px;
  width: 100%;
  display: inline-block;
  padding: 0 20px;
  overflow: hidden;
}
.equivalence-add-new{
  text-align: right;
}

.btn-arrow{
  padding: 5px;
  width: 45px;
}

.form-control-100{
  width: 100% !important;
}

.compress-container{
  width:70% !important;
}

/*TLH Alternative*/

.tlh-footer{
  border-top: solid 1px var(--table-stroke-primary);
  text-align: right;
}

/*Trade Setting By Account Type*/
.trade-account-type{padding: 7px 17px;}

/*Models detail using submodel*/
.model-list{
  padding: 27px;
  overflow: auto;
}
.model-list-table th, .model-list-table td {
  padding-left: 17px !important;
  border-right: 1px solid var(--table-stroke-primary);
  border-left: 1px solid var(--table-stroke-primary);
}
.breakWord{
  word-wrap: break-word;
}
/*Tactical Trade*/
.right-toggl-panel-heading {
  display: inline-block;
  width: 100%;
}

.right-toggl-panel-heading h6{    font-size: 12px;
  display: table;
  padding: 0 0 6px;
  margin: 0;
  margin: 2px 0 0 2px;
  letter-spacing: .5px;
  line-height: 14px;
  font-weight: 200;}

.category tr td {
  background: var(--blue-50) !important;
}
.class tr td {
  background: var(--blue-100) !important;
}
.sub-class tr td {
  background: var(--blue-200) !important;
}
.security-set tr td {
  background: var(--blue-300)  !important;
}

.category tr td.ht3, .class tr td.ht3, .sub-class tr td.ht3, .security-set tr td.ht3 {
  background: var(--blue-400)  !important;
}

.p-0{padding: 0 !important}

.unassigned-table{ margin-bottom: 5px;}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td{ vertical-align: middle;}

tr.tradebar-level2 > th {
  background: var(--table-background-header);
  color: var(--table-text-primary);
}
tr.tradebar-level3 > td{
  background: var(--table-background-base);
  color: var(--table-text-primary);
}

body{overflow-x:hidden !important; }

.model-name{    display: inline-block;
  float: left;
  padding: 5px 15px;}

table tr th.no-border {
  border: none !important;
}

table.table.tactical-custom-table.table-step-1.unassigned-table tr.un-Assigned td:last-child{text-align: right;}

.sleevelabel { font-size: 12px; padding-top: 5px;}
.sleevelabeldata{ font-size: 12px;padding-bottom: 5px;}
.shortlabeldata{  width: 98px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  position: relative;
  vertical-align: middle;
}

.security-select{width: 58px}
.ui-tooltip-content{font-size: 12px;
  font-weight: 500;
  padding: 5px;}

.tactical-custom-table > thead > tr.sleeve-row > td, .tactical-custom-table > tbody > tr.sleeve-row > td, .tactical-custom-table > tfoot > tr.sleeve-row > td{ border-bottom: solid 3px var(--table-stroke-primary) !important;}

.instance-select label{padding-top: 35px}
.instance-button {
  background: var(--button-background-primary);
  color: var(--button-text-primary);
  border: 0;}

.approve-trade{
  width: 100%;
  height: 250px;
  margin-top: 25px !important;
}

@media (max-width: 5000px) {

  .table-responsive {    width: 100%;    margin-bottom: 15px;    overflow-y: hidden;    overflow-x: auto;    -ms-overflow-style: -ms-autohiding-scrollbar;  -webkit-overflow-scrolling: touch;  }  .table-responsive > .table {    margin-bottom: 0;  }  .table-responsive > .table > thead > tr > th,  .table-responsive > .table > tbody > tr > th,  .table-responsive > .table > tfoot > tr > th,  .table-responsive > .table > thead > tr > td,  .table-responsive > .table > tbody > tr > td,  .table-responsive > .table > tfoot > tr > td {    white-space: nowrap;  }  .table-responsive > .table-bordered {    border: 0;  }  .table-responsive > .table-bordered > thead > tr > th:first-child,  .table-responsive > .table-bordered > tbody > tr > th:first-child,  .table-responsive > .table-bordered > tfoot > tr > th:first-child,  .table-responsive > .table-bordered > thead > tr > td:first-child,  .table-responsive > .table-bordered > tbody > tr > td:first-child,  .table-responsive > .table-bordered > tfoot > tr > td:first-child {    border-left: 0;  }  .table-responsive > .table-bordered > thead > tr > th:last-child,  .table-responsive > .table-bordered > tbody > tr > th:last-child,  .table-responsive > .table-bordered > tfoot > tr > th:last-child,  .table-responsive > .table-bordered > thead > tr > td:last-child,  .table-responsive > .table-bordered > tbody > tr > td:last-child,  .table-responsive > .table-bordered > tfoot > tr > td:last-child {    border-right: 0;  }  .table-responsive > .table-bordered > tbody > tr:last-child > th,  .table-responsive > .table-bordered > tfoot > tr:last-child > th,  .table-responsive > .table-bordered > tbody > tr:last-child > td,  .table-responsive > .table-bordered > tfoot > tr:last-child > td {    border-bottom: 0;  }}
  .table-responsive.table-responsive-scrolly{
      overflow-y:scroll;
  }

.model-security-label label{
  color: var(--textfield-text-label);
  padding: 10px;
}

/* Profile page setting */

.tab-content.tab-content-margin-top .tab-content{margin-top: 40px;}
.tab-content.tab-content-margin-top .tab-pane label.primar-label{margin: 0}

.tab-content.tab-content-margin-top .account-realized-gain-box {
  padding: 10px;
  margin: 0px;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}
.tab-content.tab-content-margin-top .account-realized-gain-box h3{margin: 0 0 10px}
.tab-content.tab-content-margin-top .panel-details-heading {
  font-size: 16px;
  margin: 0;
  font-weight: 300;
  padding: 4px 10px;
  border-radius: 0;
}

.tab-content .panel-details-heading {
  font-size: 16px;
  color: var(--accordion-text-primary);
  margin: 0 0 0px 0px;
  font-weight: 300;
  background: var(--accordion-background-base);
  padding: 4px 10px;
  border-radius: 0;
}

.portfolio-model-table .tab-pane.fade.sm_tabs { max-width: 1058px; width: 100%; margin: 0 auto; float: initial; }
.portfolio-model-table .tab-content{margin: 20px 0 !important; background: var(--tab-horizontal-background-base);}

div#portfolio-analyse {
  max-width: 100%;
  margin: 20px auto;
}
.tab-content.tab-content-margin-top .tab-content .tab-pane {
  padding: 15px 15px 10px 15px;
  background: none;
}

table.table.sub-model-security-set {
  margin: 0;
}
table.table.sub-model-security-set th {
  font-weight: 500;
  font-size: 16px;
  border-bottom:none !important;
}

table.table.sub-model-security-set td {
  padding: 3px 11px;
  border: none !important;

}
table.table.sub-model-security-set .model-security-set {
  position: relative;
  top: -8px;
}
table.table.sub-model-security-set th:nth-child(2), table.table.sub-model-security-set td:nth-child(2){text-align: center; width:200px;}
table.table.sub-model-security-set th:first-child, table.table.sub-model-security-set td:first-child{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width:500px;}
table.table.sub-model-security-set .model-security-set{width: 100% !important}

/* Import CSV file*/
.import-csv .p-button {
  width: 156px;
}
.choose-file-small-box.file-import {
  width: 100%;
  max-width: 100%;
  height: 159px;
}

.element-ui {
  padding: 10px 20px;
  box-shadow: var(--elevation-400);
  min-height: 150px;
}

/* Dropdown */
.custom-scroll{overflow: auto; max-height: 300px}
td.actionicon-setting .tactical-wrap-header .dropdown-menu {
  left: 100% !important;
}

.status-col{ text-align: left !important; display: block; }

/* table header Fixed */
table.fixed-header {
  max-width:100%;
  table-layout:fixed;
  margin:auto;
}
.fixed-header thead, .fixed-header tfoot {
  display:table;
  width:100%;
  width:calc(100% - 0px);
}
.fixed-header tbody {
  height:250px;
  overflow:auto;
  overflow-x:hidden;
  display:block;
  width:100%;
}
.fixed-header tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
}

.status-col{ text-align: left !important; display: block; }

/*Security Set Alternative*/
.security-set-alternative table{margin-top: 12px}
.security-set-alternative table tr td select{
  border: none;
  border-bottom: 1px solid var(--textfield-stroke-primary) !important;
  height: 29px;
  width: 150px;
}

.security-preference-alternative tr td button{padding-top: 11px;}

.alternative-error{
  height: 30px
}

tr.dataheading.datasecurity .form-control {
  border-bottom: none;
}
button.alternative-btn-add{background: none; border: none; width: 100%;}
button.alternative-btn-add i{color: var(--textfield-icon-primary); font-size: 25px;}
.alernative-table{width: 100%;}
.alternative-error-security{margin: 0; padding: 2px 0;}

/*Analysis Chnages*/

.analytics-toggle:hover, .analytics-toggle:focus{text-decoration: none;}
.analytic-count{top: 3px !important;}

.order-holduntil-calender input{width: 130px !important;}

.team-tabs-data select{width: 100% !important;}

/* Toggle */
.sub-toggle-inner{display: none !important;}
.sub-toggle-hover:hover .sub-toggle-inner{display: block !important;}
span.dropdown-menu.sub-toggle-inner {
  height: 90px;
  overflow-y: scroll;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.btn.model-analytic-btn{
  display: inline-block;
  border-radius: 3px;
  background: var(--button-background-primary);
  color: var(--button-text-primary);
  margin: 0;
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
}

.analytic-analyzer-status .run-now-button.p-button {
  height: 1.23rem;
}

.analytic-status {
  font-size: 12px;
  color: var(--text-primary);
  padding: 3px 15px;
  position: relative;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
}

.analytic-status.onprogress {
  font-size: 12px;
  color: var(--text-secondary);
  padding: 0 5px 3px 15px;
  position: relative;
  border-radius: 4px;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
}

span.analytic-status.duration {
  background: var(--gray-400);
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  top: 1.5px;
  left: -5px;
  box-shadow: none;
}

span.analytic-status.duration:after {
  display: none;
}

.tom-right-tab-box  .analytic-status::after {
  border-left: 10px solid var(--gray-100);
}

#ModelAnalysis .analytic-status::after {
  border-left: 10px solid var(--grid-stroke-primary);
}

/*.analytic-status.onprogress::after{display: none;}*/
.analytic-status.onprogress{
  background: var(--alert-background-info);
  padding-right: 20px;
}
.analytic-status.done{
  color: var(--alert-text-success-message);
  background: var(--green-400);
}
.analytic-status.failed{
  background: var(--alert-background-error);
}
.analytic-status.need{
  background: var(--alert-background-warning);
}

.analytic-status.dropdown .dropdown-item{width: 100%; display: inline-block; color: var(--list-text-primary) !important; font-size: 13px !important;}
.analytic-status .dropdown-menu{background: var(--list-background-base);
  padding: 8px 10px 10px;
  min-width: 360px;
  margin-top: 1px;
  right: 0 !important;
  left: auto;}

.analytic-status .dropdown-menu.model-analyzer-table{
  width: 150px;
  min-width: 200px;
}

.analytic-status .dropdown-menu.model-analyzer-table table {
  height: 150px;
  overflow-y: auto;
  display: inline-block;
  margin: 0;
}

.analytic-status .dropdown-menu.model-analyzer-table table th, .analytic-status .dropdown-menu.model-analyzer-table table td{padding: 5px 5px;}

.analytic-status .dropdown-menu .btn.model-analytic-btn{margin-top: 10px;
  float: right;
  padding: 4px 10px 6px;
  font-size: 12px;
  border: 1px solid var(--textfield-stroke-primary);}

.analytic-status.dropdown .dropdown-menu::after {
  content: '';
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--select-stroke-primary);
  position: absolute;
  top: -6px;
  right: 12px;}

.btn.model-analytic-btn.runBtn{padding: 2px 10px; position: relative; top: -2px; font-size: 11px;}

@-webkit-keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
    position: absolute;
  }
}
@keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
    position: absolute;
  }
}

.tradetool-analytics-status .analytic-status, .tradetool-analytics-status .runBtn {right: 25px;}
.tradetool-analytics-status .onprogress{right: 0px;}

.tradetool-ttt-status .analytic-status, .tradetool-ttt-status .runBtn {right: 25px; top: 24px;}
.tradetool-ttt-status .onprogress{right: 0px; top: 0px;}

span.failed.analytic-status{padding-right: 20px;}
span.failed.analytic-status i {
  position: absolute;
  right: 4px;
  width: 100%;
  top: 4px;
}
span.dropdown-menu.model-analyzer-table i {
  position: static;
}
.tlh-analytics-status{float: right; display: inline-block; width: 700px;}

/* Tactical analytics popup */
.tactical-analytics-popup i { font-size: 28px}
.tactical-analytics-popup span { font-size: 16px}

span.text-error {
  color: var(--textfield-text-error);
  padding: 10px 0;
  display: inline-block;
  font-size: 12px;
  white-space: normal;
}

#equivalent-dis-modal div.ui-dialog
{
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  top: 30% !important;
}

/* TLH Wash Sale on Sell OE-3447*/
.tlh-allow-wash-sale {
  margin-top: 17px;
}
.tlh-allow-wash-sale span {
  font-size: 16px;
}

/* OE-3441 Location Optimization*/
table.table.table-bordered.tabel-bg-white.location-table tr th:first-child select,
table.table.table-bordered.tabel-bg-white.location-table tr:nth-child(2) td:first-child select {
    width: 200px !important;
}
table.location-table tr td {
  padding: 8px 5px;
  text-align: center;
}

/* OE-3441 set dialog and tabl width*/
.dialog-width div.ui-dialog
{
    width: 50%;
}

.table-width {
    width: 100% !important;
}

.location-popup table.table.table-bordered.tabel-bg-white.location-table {
  overflow-y: auto;
  height: 500px !important;
  display: inline-block;
}

a.plus-icon i {
  top: -5px !important;
  right: -12px !important;
}

.v-scroll {
  overflow: auto;
}

.pad-left {
  padding-left: 7%;
}

.fixed-income-table {
  overflow-y: auto;
  height: 275px;
}

.dynamic-right-slide, .block-right-panel, .allocate-orders-right-panel {
  width: 0px;
  right: 0;
}
.dynamic-right-slide.in {
  width: 650px;
}
.block-right-panel.in {
  width: 600px;
}
.allocate-orders-right-panel.in {
  width: 350px;
}

.label-left-margin {
  margin-left: -15px;
}

.model-assign button.btn {
  position: relative;
  top: -10px;
  margin-right: 10px;
}
.model-pending-icon {
  top: 0px !important;
}

.advisor-portfolio {
  font-size: 15px;
  padding: 11px 0 4px;
}

table.model-approval.table tr th {
  font-weight: bold;
  color: var(--table-stroke-primary);
  padding: 10px 20px;
}
table.model-approval.table tr td {
  padding: 10px 20px;
}
.category-fixed-income{
  padding-top: 10px;
}

.cash-need-label{
  padding: 30px 0 0 0;
  width: 100%;
}

.delete-substitute {
  text-align: center;
  font-size: 17px;
}

.tactical-rebalance-textarea{
  max-height: 80px;
  max-width: 830px;
  margin: 0px 17.0486px 6.99653px 0px;
  width: 857px;
  height: 80px !important;
  min-height: 80px;
}

.tactical-rebalance-popup-footer{
  padding-top: 20px;
}


div.info_popup_message {
  font-size: 1.1em;
}

.fix-setup {
  display: inline-block;
  width: 100%;
  padding: 20px;
  border: 1px solid var(--table-stroke-primary);
  margin: 20px 0px 25px;
}
div#Fix-setup {
  min-height: auto;
  float: left;
  width: 100%;
}
.custodian-detail-col .col-sm-3, .custodian-detail-col .col-sm-4 {
  padding: 15px 30px;
}
.custodian-detail-col .col-sm-3 label, .custodian-detail-col .col-sm-4 label{font-size: 14px;}

span.doNotTLH {
    position: relative;
    top: 12px;
}

span.doNotTLH input#donottlh {
  margin-right: 5px;
  position: relative;
  top: 2px;
}

span.doNotTLH label {
  margin: 0;
}

.location-level-label{
  margin: auto;
    vertical-align: middle;
    position: relative;
    top: 20px;
    font-weight: 600 !important;
}
div.info_popup_message {
  font-size: 1.1em;
}

.custodian-detail-col .col-sm-3 label, .custodian-detail-col .col-sm-4 label{font-size: 14px;}

.custodian-tag-input {
  background: transparent;
  border-width: 1px;
  border-radius: 0;
  margin-right: 10px;
  padding: 5px;
}

.botton-margin {
  margin-bottom: 10px;
}

.bottom-border {
  border-bottom: 1px solid var(--table-stroke-primary);
}

.custodian-algo-popup-body {
  overflow-x: hidden;
  overflow-y: auto;
  height: 620px;
}

.trade-analysis-report input{
  display: inline-block;
  width: auto;
  position: relative;
  top: -9px;
  left: 10px;}
  .trade-analysis-report select {
    border-bottom: 1px solid;
}

.trade-analysis-report .level-grouping-label{
  padding-top: 9px;
}

/* Edit Dashboard */

.edit-dashboard-setting{
  padding: 0 25px 0 25px;
}

.dashboard-setting b {
  margin-left: 2px;
  display: inline-block;
  margin: 20px 0 0;
  width: 100%;
}

.dashboard-setting h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 30px 0 0 0;
}

.dashboard-setting-wrap footer {
  padding-top: 10px;
  border-top: 1px solid var(--modal-stroke-primary);
  margin-top: 20px;
}

.edit-dashboard-setting {
  height: 395px;
  overflow-y: auto;
}

.dashboard-setting-wrap .ui-dialog .ui-dialog-titlebar span {
  font-size: 22px;
  font-weight: 600;
}

.dashboard-setting ul {
  list-style: none;
  margin: 5px 0 0 0;
  padding: 0;
}

.dashboard-setting ul li {
  float: left;
  margin: 0 35px 0 0;
}

.dashboard-setting ul li .ui-state-default {
  background-color: var(--black);
}

.dashboard-setting ul li .ui-state-default.ui-state-active {
  background-color: var(--menu-background-hover) !important;
}

.dashboard-setting ul li .ui-state-default.ui-state-active .pi-circle-on:before {
  content: "\e911";
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 2px auto;
}

.dashboard-filter-edit > i {
  padding: 3px 0px 3px 10px;
  font-size: 21px;
}

.dashboard-setting-edit-portfolio > i {
  padding: 15px 13px;
  font-size: 21px;
  position: absolute;
}

.submodel-model-list{
  padding: 17px 20px 0px 20px;
}

.exclude-filter .button .ui-state-default.ui-state-active {
    background-color: var(--button-background-primary-active) !important;
}

.exclude-filter .button .ui-state-default {
    background-color: var(--button-background-secondary) !important;
}

.exclude-filter .button .ui-state-default.ui-state-active .pi-circle-on:before {
    content: "\e911";
    font-size: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 2px auto;
}

.calender input {
  width: 100px;
}

.astrisk-font-size {
  font-size: 21px;
}

a.astrisk-font-size {
  text-decoration: none;
}

.radio.purple_radio input[type=radio]:checked~ span.circle {
  border-color: var(--radio-stroke-primary) !important;
  background-color: var(--radio-background-primary);
}
.radio.purple_radio input[type=radio]:checked~ span.check {
  background: var(--radio-background-active);
  left: 10px;
  top: 4px;
}
.radio.purple_radio label::before{
  border: none;
  background: none;
}
li.radio.purple_radio label .circle {
  border: none;
  background: var(--radio-background-primary);
}

.dashboard-setting ul li.radio.purple_radio {
  margin: 0 25px 0 0;
}

.team-radio .radio input[type=radio][disabled]~.circle {
  background-color: var(--radio-background-disabled);
}

.model-team .ui-state-default {
  background: transparent;
}

.model-team .edit-model-team .ui-multiselect {
  width: 210px;
}

.model-security-type .ui-state-default {
  background: transparent;
}

.model-security-type .edit-model-security-type .ui-multiselect {
  width: 210px;
}

.overflow-visible {
  overflow: visible !important;
}

.portfolio-team .ui-multiselect-open, .security-set-teams .ui-multiselect-open {
    z-index: 1;
}

.break-word {
  overflow-wrap: break-word;
}

.ui-dialog-new-leaves .ui-dialog {
  width: 445px;
}

.ui-dialog-new-leaves .ui-dialog .ui-dialog-title {
  font-weight: 600;
  color: var(--modal-text-primary);
}

.new-block-with-leaves {
  font-size: 14px;
  padding: 3px;
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.multi-selection a {
  background: var(--menu-text-active);
}

.rebalance-dialog {
  width: 60%;
  height: 90% !important;
  overflow: auto !important;
}

.raise-cash-dialog {
  width: 60%;
  height: 75% !important;
  overflow: auto !important;
}

.set-aside-dialog-width {
  width: 80% !important;
}

.tlh-dialog {
  width: 70%;
  height: 80% !important;
}

.liquidate-dialog {
  width: 60%;
  height: 70% !important;
  overflow: auto !important;
}

.process-sleeves-popup > div {
  z-index: 9999 !important;
}

.height-70 {
  height: 70% !important;
}

.model-update-message {
  font-size: 15px;
  color: var(--alert-text-warning-message);
  position: relative;
  right: 15%;
  top: 3px;
}

.liquidate-equivalents {
  margin-top: 5px;
  min-height: 110px;
}

.raise-cash-checkbox {
  margin-top: 30px;
}

.removed-node-table {
  display: block;
  overflow: scroll;
  max-height: 350px;
}

.width-40 {
  width: 40%;
}

.preference-tooltip {
  max-width: 50% !important;
}

.tlh-batch-count {
  background-color: var(--grid-background-base);
  padding: 15px;
  color: var(--grid-text-primary);
  font-size: 15px;
  font-weight: 500;
}

.manual-tlh-message {
  padding: 0 100px 0 0;
}

.security-set-tlh-button {
  margin: 30px 0 0 70px !important;
}

.ui-datepicker-month,
.ui-datepicker-year {
  background-color: transparent;
}

.tlh-select-search select {
  display: inline-block;
  width: 300px;
}

.tlh-select-search a {
  display: inline-block;
  padding: 0 0 0 15px;
}

.rebalance-dialog .securities-grid {
  margin-left: 4px;
}

.equivalent-not-targeted {
  left: 0;
  right: 0;
  font-weight: 500;
  font-size: 13px;
  color: var(--text-secondary);
}

.preference-action .ripple-container {
  display: none;
}

.account-pref-container {
  margin-top: 100px;
  padding-bottom: 50px;
  overflow-y: auto;
  height: calc(100vh - 100px) !important;
}
