.p-tabview .p-tabview-panels {
  padding-left: .25rem;
  padding-right: .25rem;
}

p-tabview .tabs-only {
  & .p-tabview-panels {
    padding: 0;
    background: unset;
  }
  /* Custom tab header content should fill the space rather than the nav-link container.
  Otherwise you can click between an anchor tag and the bottom of the tab and it'll switch
  tabs but won't actually click the anchor.*/
  & .p-tabview-nav li .p-tabview-nav-link {
    padding: 0;
    & .tab-header-link, & > a, & > .p-tabview-title {
      padding: .625rem 0 .5rem 0;
    }
  }
}

p-tabview .tab-icon {
  width: 50px;
  text-align: center;
}

p-tabview .readonlytabs .p-disabled {
  opacity: 1;
}

a.p-tabview-nav-link:hover, a.p-tabview-nav-link > a:hover {
  text-decoration: none;
}

p-tabview .hide-tabs .p-tabview-nav-container {
  display: none;
}

/* Remove the solid background of tabs */
.p-tabview .p-tabview-nav,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: transparent;
}

p-tabview .show-tabs .p-tabview-nav-container {
  display: block;
}

p-tabview .p-tabview-no-bottom-border .p-tabview-nav {
  border-color: transparent;
}
