.p-panel {
  border: 1px solid var(--accordion-stroke-primary);
  border-radius: var(--border-radius);

  & .p-panel-header,
  & .p-panel-content {
    background-color: var(--accordion-background-base);
    border: 1px solid var(--accordion-border-base);
  }

  &.p-panel-no-header .p-panel-header {
    display: none;
  }

  &:has(.h-full) .p-toggleable-content, &:has(.h-full) .p-panel-content {
    height: 100%;
  }
}
