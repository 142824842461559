.fa-astro {
  &:before {
    content: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.7909 6.15739C14.4752 6.56469 14.0939 7.01728 13.6719 7.50612C9.89868 11.8764 4.77288 15.9994 2.20784 15.9994C1.26457 15.9994 1 15.5357 1 14.7485C1 11.5754 5.65426 0 7.94185 0C9.24633 0 11.1893 3.18423 12.8097 7.03952C14.0054 5.96116 14.8255 5.10662 14.8255 5.10662L14.9181 5.38337C14.9615 5.51307 14.9726 5.65236 14.9504 5.78789C14.9281 5.92343 14.8732 6.05066 14.7909 6.15739ZM7.99125 3.42103C6.84452 3.42103 4.74633 8.05714 4.74633 10.3603C4.74633 11.1164 5.01539 11.3981 5.53847 11.3972C7.00287 11.3972 9.02531 10.1261 10.8103 8.72697C10.01 5.91565 9.02414 3.42103 7.99125 3.42103Z" fill="%2318191B"/><path d="M11.8702 12.9612C11.4816 11.347 12.191 9.97999 13.4306 8.59234C14.41 11.1325 15.1365 13.629 15.5118 15.8273H12.4911C12.337 14.9944 12.1796 14.2471 11.8702 12.9612Z" fill="%2318191B"/></svg>')
  }

  .ag-menu-option-icon & {
    filter: brightness(0) saturate(100%) invert(30%) sepia(17%) saturate(386%) hue-rotate(180deg) brightness(92%) contrast(89%);
  }

  .dropdown-menu a & {
    filter: brightness(0) saturate(100%) invert(0) sepia(0) saturate(386%) hue-rotate(180deg) brightness(92%) contrast(89%);
  }
  .dropdown-menu a:hover & {
    filter: invert(28%) sepia(66%) saturate(1275%) hue-rotate(183deg) brightness(96%) contrast(92%);
  }
  .dark .dropdown-menu a & {
    filter: invert(31%) sepia(4%) saturate(1570%) hue-rotate(180deg) brightness(88%) contrast(86%);
  }
  .dark .dropdown-menu a:hover & {
    filter: invert(89%) sepia(27%) saturate(5021%) hue-rotate(175deg) brightness(97%) contrast(98%);
  }
}
