@import '@oat/stylesheets/dist/oat-components.css';

.p-button-label {
  text-transform: initial; /* default style set all buttons to uppercase */
}

p-dropdown > .p-dropdown {
  display: flex !important;
}

p-multiselect > .p-multiselect {
  display: flex;
}

p-autocomplete > .p-autocomplete {
  display: flex;
}

/* Overlay overrides */
.p-component-overlay {
  backdrop-filter: none !important;
  -webkit-backdrop-filter: none !important;
  transition-duration: 0ms;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--scrim-background-base);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--scrim-background-base);
  }
  to {
    background-color: transparent;
  }
}
