.p-picklist .p-picklist-header,
.p-picklist .p-picklist-filter-container {
  background: var(--accordion-background-base);
  color: var(--accordion-text-primary);
  border-color: var(--accordion-stroke-primary);
  padding: .5rem;
}

.p-picklist .p-picklist-list {
  background: var(--list-background-base);
  border-color: var(--accordion-stroke-primary);
}

.p-picklist.p-picklist-compact .p-picklist-list .p-picklist-item {
  padding-top: .15rem;
  padding-bottom: .15rem;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: var(--list-text-primary) !important;
}
