body {
    background-color: #EEE
}

body.inverse {
    background: #333
}

body.inverse,
body.inverse .form-control {
    color: rgba(255, 255, 255, .84)
}

body.inverse .card,
body.inverse .card .form-control,
body.inverse .modal,
body.inverse .modal .form-control,
body.inverse .panel-default,
body.inverse .panel-default .form-control {
    background-color: initial;
    color: initial
}

.h1,
.h2,
.h3,
.h4,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300
}

h4,
h5,
h6 {
    font-weight: 400
}

a,
a:focus,
a:hover {
    color: #01acef
}

a .material-icons,
a:focus .material-icons,
a:hover .material-icons {
    vertical-align: middle
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
    padding-top: 0
}

.form-horizontal .radio {
    margin-bottom: 10px
}

.form-horizontal label {
    /*text-align: right;*/
}

.form-horizontal label.control-label {
    margin: 0
}

body .container .well.well-sm,
body .container-fluid .well.well-sm {
    padding: 10px
}

body .container .well.well-lg,
body .container-fluid .well.well-lg {
    padding: 26px
}

body .container .jumbotron,
body .container .well,
body .container-fluid .jumbotron,
body .container-fluid .well {
    background-color: #fff;
    padding: 19px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    border-radius: 2px;
    border: 0
}

body .container .jumbotron p,
body .container .well p,
body .container-fluid .jumbotron p,
body .container-fluid .well p {
    font-weight: 300
}

body .container .jumbotron,
body .container .jumbotron-default,
body .container .well,
body .container .well-default,
body .container-fluid .jumbotron,
body .container-fluid .jumbotron-default,
body .container-fluid .well,
body .container-fluid .well-default {
    background-color: #fff
}

body .container .jumbotron-inverse,
body .container .well-inverse,
body .container-fluid .jumbotron-inverse,
body .container-fluid .well-inverse {
    background-color: #3f51b5
}

body .container .jumbotron-primary,
body .container .well-primary,
body .container-fluid .jumbotron-primary,
body .container-fluid .well-primary {
    background-color: #01acef
}

body .container .jumbotron-success,
body .container .well-success,
body .container-fluid .jumbotron-success,
body .container-fluid .well-success {
    background-color: #4caf50
}

body .container .jumbotron-info,
body .container .well-info,
body .container-fluid .jumbotron-info,
body .container-fluid .well-info {
    background-color: #03a9f4
}

body .container .jumbotron-warning,
body .container .well-warning,
body .container-fluid .jumbotron-warning,
body .container-fluid .well-warning {
    background-color: #ff5722
}

body .container .jumbotron-danger,
body .container .well-danger,
body .container-fluid .jumbotron-danger,
body .container-fluid .well-danger {
    background-color: #f44336
}

.btn,
.input-group-btn .btn {
    border: none;
    border-radius: 2px;
    position: relative;
    padding: 8px 30px;
    margin: 10px 1px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    will-change: box-shadow, transform;
    -webkit-transition: -webkit-box-shadow .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, .2, 1), color .2s cubic-bezier(.4, 0, .2, 1);
    -o-transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, .2, 1), color .2s cubic-bezier(.4, 0, .2, 1);
    transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, .2, 1), color .2s cubic-bezier(.4, 0, .2, 1);
    outline: 0;
    cursor: pointer;
    text-decoration: none;
    background: 0 0
}

.btn::-moz-focus-inner,
.input-group-btn .btn::-moz-focus-inner {
    border: 0
}

.btn:not(.btn-raised),
.input-group-btn .btn:not(.btn-raised) {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn:not(.btn-raised),
.btn:not(.btn-raised).btn-default,
.input-group-btn .btn:not(.btn-raised),
.input-group-btn .btn:not(.btn-raised).btn-default {
    color: rgba(0, 0, 0, .87)
}

.btn:not(.btn-raised).btn-inverse,
.input-group-btn .btn:not(.btn-raised).btn-inverse {
    color: #3f51b5
}

.btn:not(.btn-raised).btn-primary,
.input-group-btn .btn:not(.btn-raised).btn-primary {
    color: #01acef
}

.btn:not(.btn-raised).btn-success,
.input-group-btn .btn:not(.btn-raised).btn-success {
    color: #4caf50
}

.btn:not(.btn-raised).btn-info,
.input-group-btn .btn:not(.btn-raised).btn-info {
    color: #03a9f4 !important;
}

.btn:not(.btn-raised).btn-warning,
.input-group-btn .btn:not(.btn-raised).btn-warning {
    color: #ff5722
}

.btn:not(.btn-raised).btn-danger,
.input-group-btn .btn:not(.btn-raised).btn-danger {
    color: #f44336
}

.btn:not(.btn-raised):not(.btn-link):focus,
.btn:not(.btn-raised):not(.btn-link):hover,
.input-group-btn .btn:not(.btn-raised):not(.btn-link):focus,
.input-group-btn .btn:not(.btn-raised):not(.btn-link):hover {
    background-color: rgba(153, 153, 153, .2)
}

.theme-dark .btn:not(.btn-raised):not(.btn-link):focus,
.theme-dark .btn:not(.btn-raised):not(.btn-link):hover,
.theme-dark .input-group-btn .btn:not(.btn-raised):not(.btn-link):focus,
.theme-dark .input-group-btn .btn:not(.btn-raised):not(.btn-link):hover {
    background-color: rgba(204, 204, 204, .15)
}

.btn-group-raised .btn,
.btn-group-raised .btn.btn-default,
.btn-group-raised .input-group-btn .btn,
.btn-group-raised .input-group-btn .btn.btn-default,
.btn.btn-fab,
.btn.btn-fab.btn-default,
.btn.btn-raised,
.btn.btn-raised.btn-default,
.input-group-btn .btn.btn-fab,
.input-group-btn .btn.btn-fab.btn-default,
.input-group-btn .btn.btn-raised,
.input-group-btn .btn.btn-raised.btn-default {
    background: #EEE;
    color: rgba(0, 0, 0, .87)
}

.btn-group-raised .btn.btn-inverse,
.btn-group-raised .input-group-btn .btn.btn-inverse,
.btn.btn-fab.btn-inverse,
.btn.btn-raised.btn-inverse,
.input-group-btn .btn.btn-fab.btn-inverse,
.input-group-btn .btn.btn-raised.btn-inverse {
    background-color: #3f51b5;
    color: #fff
}

.btn-group-raised .btn.btn-primary,
.btn-group-raised .input-group-btn .btn.btn-primary,
.btn.btn-fab.btn-primary,
.btn.btn-raised.btn-primary,
.input-group-btn .btn.btn-fab.btn-primary,
.input-group-btn .btn.btn-raised.btn-primary {
    background-color: #01acef;
    color: rgba(255, 255, 255, .84)
}

.btn-group-raised .btn.btn-success,
.btn-group-raised .input-group-btn .btn.btn-success,
.btn.btn-fab.btn-success,
.btn.btn-raised.btn-success,
.input-group-btn .btn.btn-fab.btn-success,
.input-group-btn .btn.btn-raised.btn-success {
    background-color: #4caf50;
    color: rgba(255, 255, 255, .84)
}

.btn-group-raised .btn.btn-info,
.btn-group-raised .input-group-btn .btn.btn-info,
.btn.btn-fab.btn-info,
.btn.btn-raised.btn-info,
.input-group-btn .btn.btn-fab.btn-info,
.input-group-btn .btn.btn-raised.btn-info {
    background: #03a9f4;
    color: rgba(255, 255, 255, .84)
}

.btn-group-raised .btn.btn-warning,
.btn-group-raised .input-group-btn .btn.btn-warning,
.btn.btn-fab.btn-warning,
.btn.btn-raised.btn-warning,
.input-group-btn .btn.btn-fab.btn-warning,
.input-group-btn .btn.btn-raised.btn-warning {
    background-color: #ff5722;
    color: rgba(255, 255, 255, .84)
}

.btn-group-raised .btn.btn-danger,
.btn-group-raised .input-group-btn .btn.btn-danger,
.btn.btn-fab.btn-danger,
.btn.btn-raised.btn-danger,
.input-group-btn .btn.btn-fab.btn-danger,
.input-group-btn .btn.btn-raised.btn-danger {
    background-color: #f44336;
    color: rgba(255, 255, 255, .84)
}

.btn-group-raised .btn:not(.btn-link),
.btn-group-raised .input-group-btn .btn:not(.btn-link),
.btn.btn-raised:not(.btn-link),
.input-group-btn .btn.btn-raised:not(.btn-link) {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn-group-raised .btn:not(.btn-link).active,
.btn-group-raised .btn:not(.btn-link):active,
.btn-group-raised .btn:not(.btn-link):focus,
.btn-group-raised .btn:not(.btn-link):hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover,
.btn.btn-raised:not(.btn-link).active,
.btn.btn-raised:not(.btn-link):active,
.btn.btn-raised:not(.btn-link):focus,
.btn.btn-raised:not(.btn-link):hover,
.input-group-btn .btn.btn-raised:not(.btn-link).active,
.input-group-btn .btn.btn-raised:not(.btn-link):active,
.input-group-btn .btn.btn-raised:not(.btn-link):focus,
.input-group-btn .btn.btn-raised:not(.btn-link):hover {
    outline: 0
}

.btn-group-raised .btn:not(.btn-link).active,
.btn-group-raised .btn:not(.btn-link).active.btn-default,
.btn-group-raised .btn:not(.btn-link):active,
.btn-group-raised .btn:not(.btn-link):active.btn-default,
.btn-group-raised .btn:not(.btn-link):focus,
.btn-group-raised .btn:not(.btn-link):focus.btn-default,
.btn-group-raised .btn:not(.btn-link):hover,
.btn-group-raised .btn:not(.btn-link):hover.btn-default,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-default,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-default,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-default,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-default,
.btn.btn-raised:not(.btn-link).active,
.btn.btn-raised:not(.btn-link).active.btn-default,
.btn.btn-raised:not(.btn-link):active,
.btn.btn-raised:not(.btn-link):active.btn-default,
.btn.btn-raised:not(.btn-link):focus,
.btn.btn-raised:not(.btn-link):focus.btn-default,
.btn.btn-raised:not(.btn-link):hover,
.btn.btn-raised:not(.btn-link):hover.btn-default,
.input-group-btn .btn.btn-raised:not(.btn-link).active,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-default,
.input-group-btn .btn.btn-raised:not(.btn-link):active,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-default,
.input-group-btn .btn.btn-raised:not(.btn-link):focus,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-default,
.input-group-btn .btn.btn-raised:not(.btn-link):hover,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-default {
    background: #e4e4e4;
}

.btn-group-raised .btn:not(.btn-link).active.btn-inverse,
.btn-group-raised .btn:not(.btn-link):active.btn-inverse,
.btn-group-raised .btn:not(.btn-link):focus.btn-inverse,
.btn-group-raised .btn:not(.btn-link):hover.btn-inverse,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-inverse,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-inverse,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-inverse,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-inverse,
.btn.btn-raised:not(.btn-link).active.btn-inverse,
.btn.btn-raised:not(.btn-link):active.btn-inverse,
.btn.btn-raised:not(.btn-link):focus.btn-inverse,
.btn.btn-raised:not(.btn-link):hover.btn-inverse,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-inverse,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-inverse,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-inverse,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-inverse {
    background-color: #495bc0
}

.btn-group-raised .btn:not(.btn-link).active.btn-primary,
.btn-group-raised .btn:not(.btn-link):active.btn-primary,
.btn-group-raised .btn:not(.btn-link):focus.btn-primary,
.btn-group-raised .btn:not(.btn-link):hover.btn-primary,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-primary,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-primary,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-primary,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-primary,
.btn.btn-raised:not(.btn-link).active.btn-primary,
.btn.btn-raised:not(.btn-link):active.btn-primary,
.btn.btn-raised:not(.btn-link):focus.btn-primary,
.btn.btn-raised:not(.btn-link):hover.btn-primary,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-primary,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-primary,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-primary,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-primary {
    background-color: #00aa9a
}

.btn-group-raised .btn:not(.btn-link).active.btn-success,
.btn-group-raised .btn:not(.btn-link):active.btn-success,
.btn-group-raised .btn:not(.btn-link):focus.btn-success,
.btn-group-raised .btn:not(.btn-link):hover.btn-success,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-success,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-success,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-success,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-success,
.btn.btn-raised:not(.btn-link).active.btn-success,
.btn.btn-raised:not(.btn-link):active.btn-success,
.btn.btn-raised:not(.btn-link):focus.btn-success,
.btn.btn-raised:not(.btn-link):hover.btn-success,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-success,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-success,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-success,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-success {
    background-color: #59b75c
}

.btn-group-raised .btn:not(.btn-link).active.btn-info,
.btn-group-raised .btn:not(.btn-link):active.btn-info,
.btn-group-raised .btn:not(.btn-link):focus.btn-info,
.btn-group-raised .btn:not(.btn-link):hover.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-info,
.btn.btn-raised:not(.btn-link).active.btn-info,
.btn.btn-raised:not(.btn-link):active.btn-info,
.btn.btn-raised:not(.btn-link):focus.btn-info,
.btn.btn-raised:not(.btn-link):hover.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-info {
    background: #0fb2fc !important;
}

.btn-group-raised .btn:not(.btn-link).active.btn-warning,
.btn-group-raised .btn:not(.btn-link):active.btn-warning,
.btn-group-raised .btn:not(.btn-link):focus.btn-warning,
.btn-group-raised .btn:not(.btn-link):hover.btn-warning,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-warning,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-warning,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-warning,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-warning,
.btn.btn-raised:not(.btn-link).active.btn-warning,
.btn.btn-raised:not(.btn-link):active.btn-warning,
.btn.btn-raised:not(.btn-link):focus.btn-warning,
.btn.btn-raised:not(.btn-link):hover.btn-warning,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-warning,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-warning,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-warning,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-warning {
    background-color: #ff6736
}

.btn-group-raised .btn:not(.btn-link).active.btn-danger,
.btn-group-raised .btn:not(.btn-link):active.btn-danger,
.btn-group-raised .btn:not(.btn-link):focus.btn-danger,
.btn-group-raised .btn:not(.btn-link):hover.btn-danger,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-danger,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-danger,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-danger,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-danger,
.btn.btn-raised:not(.btn-link).active.btn-danger,
.btn.btn-raised:not(.btn-link):active.btn-danger,
.btn.btn-raised:not(.btn-link):focus.btn-danger,
.btn.btn-raised:not(.btn-link):hover.btn-danger,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-danger,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-danger,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-danger,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-danger {
    background-color: #f55549
}

.btn-group-raised .btn:not(.btn-link).active,
.btn-group-raised .btn:not(.btn-link).active:hover,
.btn-group-raised .btn:not(.btn-link):active,
.btn-group-raised .btn:not(.btn-link):active:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active:hover,
.btn.btn-raised:not(.btn-link).active,
.btn.btn-raised:not(.btn-link).active:hover,
.btn.btn-raised:not(.btn-link):active,
.btn.btn-raised:not(.btn-link):active:hover,
.input-group-btn .btn.btn-raised:not(.btn-link).active,
.input-group-btn .btn.btn-raised:not(.btn-link).active:hover,
.input-group-btn .btn.btn-raised:not(.btn-link):active,
.input-group-btn .btn.btn-raised:not(.btn-link):active:hover {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2)
}

.btn-group-raised .btn:not(.btn-link):focus,
.btn-group-raised .btn:not(.btn-link):focus.active,
.btn-group-raised .btn:not(.btn-link):focus.active:hover,
.btn-group-raised .btn:not(.btn-link):focus:active,
.btn-group-raised .btn:not(.btn-link):focus:active:hover,
.btn-group-raised .btn:not(.btn-link):focus:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.active:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus:active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus:active:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus:hover,
.btn.btn-raised:not(.btn-link):focus,
.btn.btn-raised:not(.btn-link):focus.active,
.btn.btn-raised:not(.btn-link):focus.active:hover,
.btn.btn-raised:not(.btn-link):focus:active,
.btn.btn-raised:not(.btn-link):focus:active:hover,
.btn.btn-raised:not(.btn-link):focus:hover,
.input-group-btn .btn.btn-raised:not(.btn-link):focus,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.active,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.active:hover,
.input-group-btn .btn.btn-raised:not(.btn-link):focus:active,
.input-group-btn .btn.btn-raised:not(.btn-link):focus:active:hover,
.input-group-btn .btn.btn-raised:not(.btn-link):focus:hover {
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .18), 0 8px 16px rgba(0, 0, 0, .36);
    box-shadow: 0 0 8px rgba(0, 0, 0, .18), 0 8px 16px rgba(0, 0, 0, .36)
}

.btn.btn-fab,
.input-group-btn .btn.btn-fab {
    border-radius: 50%;
    font-size: 24px;
    height: 56px;
    margin: auto;
    min-width: 56px;
    width: 56px;
    padding: 0;
    overflow: hidden;
    -webkit-box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, .12), 0 1px 1px 0 rgba(0, 0, 0, .24);
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, .12), 0 1px 1px 0 rgba(0, 0, 0, .24);
    position: relative;
    line-height: normal
}

.btn.btn-fab .ripple-container,
.input-group-btn .btn.btn-fab .ripple-container {
    border-radius: 50%
}

.btn-group-sm .btn.btn-fab,
.btn-group-sm .input-group-btn .btn.btn-fab,
.btn.btn-fab.btn-fab-mini,
.input-group-btn .btn.btn-fab.btn-fab-mini {
    height: 40px;
    min-width: 40px;
    width: 40px
}

.btn-group-sm .btn.btn-fab.material-icons,
.btn-group-sm .input-group-btn .btn.btn-fab.material-icons,
.btn.btn-fab.btn-fab-mini.material-icons,
.input-group-btn .btn.btn-fab.btn-fab-mini.material-icons {
    top: 0;
    left: 0
}

.btn.btn-fab i.material-icons,
.input-group-btn .btn.btn-fab i.material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-12px, -12px);
    -ms-transform: translate(-12px, -12px);
    -o-transform: translate(-12px, -12px);
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px
}

.btn i.material-icons,
.input-group-btn .btn i.material-icons {
    vertical-align: middle
}

.btn-group-lg .btn,
.btn-group-lg .input-group-btn .btn,
.btn.btn-lg,
.input-group-btn .btn.btn-lg {
    font-size: 16px
}

.btn-group-sm .btn,
.btn-group-sm .input-group-btn .btn,
.btn.btn-sm,
.input-group-btn .btn.btn-sm {
    padding: 5px 12px;
    font-size: 12px
}

.btn-group-xs .btn,
.btn-group-xs .input-group-btn .btn,
.btn.btn-xs,
.input-group-btn .btn.btn-xs {
    padding: 4px 15px;
    font-size: 10px
}

.btn-group-vertical.disabled,
.btn-group-vertical:disabled,
.btn-group-vertical[disabled][disabled],
.btn-group.disabled,
.btn-group:disabled,
.btn-group[disabled][disabled],
.btn.disabled,
.btn:disabled,
.btn[disabled][disabled],
.input-group-btn .btn.disabled,
.input-group-btn .btn:disabled,
.input-group-btn .btn[disabled][disabled],
fieldset[disabled][disabled] .btn,
fieldset[disabled][disabled] .btn-group,
fieldset[disabled][disabled] .btn-group-vertical,
fieldset[disabled][disabled] .input-group-btn .btn {
    color: rgba(0, 0, 0, .26);
    background: 0 0
}

.theme-dark .btn-group-vertical.disabled,
.theme-dark .btn-group-vertical:disabled,
.theme-dark .btn-group-vertical[disabled][disabled],
.theme-dark .btn-group.disabled,
.theme-dark .btn-group:disabled,
.theme-dark .btn-group[disabled][disabled],
.theme-dark .btn.disabled,
.theme-dark .btn:disabled,
.theme-dark .btn[disabled][disabled],
.theme-dark .input-group-btn .btn.disabled,
.theme-dark .input-group-btn .btn:disabled,
.theme-dark .input-group-btn .btn[disabled][disabled],
.theme-dark fieldset[disabled][disabled] .btn,
.theme-dark fieldset[disabled][disabled] .btn-group,
.theme-dark fieldset[disabled][disabled] .btn-group-vertical,
.theme-dark fieldset[disabled][disabled] .input-group-btn .btn {
    color: rgba(255, 255, 255, .3)
}

.btn-group-vertical.disabled.btn-group-raised,
.btn-group-vertical.disabled.btn-group-raised.active,
.btn-group-vertical.disabled.btn-group-raised:active,
.btn-group-vertical.disabled.btn-group-raised:focus:not(:active),
.btn-group-vertical.disabled.btn-raised,
.btn-group-vertical.disabled.btn-raised.active,
.btn-group-vertical.disabled.btn-raised:active,
.btn-group-vertical.disabled.btn-raised:focus:not(:active),
.btn-group-vertical:disabled.btn-group-raised,
.btn-group-vertical:disabled.btn-group-raised.active,
.btn-group-vertical:disabled.btn-group-raised:active,
.btn-group-vertical:disabled.btn-group-raised:focus:not(:active),
.btn-group-vertical:disabled.btn-raised,
.btn-group-vertical:disabled.btn-raised.active,
.btn-group-vertical:disabled.btn-raised:active,
.btn-group-vertical:disabled.btn-raised:focus:not(:active),
.btn-group-vertical[disabled][disabled].btn-group-raised,
.btn-group-vertical[disabled][disabled].btn-group-raised.active,
.btn-group-vertical[disabled][disabled].btn-group-raised:active,
.btn-group-vertical[disabled][disabled].btn-group-raised:focus:not(:active),
.btn-group-vertical[disabled][disabled].btn-raised,
.btn-group-vertical[disabled][disabled].btn-raised.active,
.btn-group-vertical[disabled][disabled].btn-raised:active,
.btn-group-vertical[disabled][disabled].btn-raised:focus:not(:active),
.btn-group.disabled.btn-group-raised,
.btn-group.disabled.btn-group-raised.active,
.btn-group.disabled.btn-group-raised:active,
.btn-group.disabled.btn-group-raised:focus:not(:active),
.btn-group.disabled.btn-raised,
.btn-group.disabled.btn-raised.active,
.btn-group.disabled.btn-raised:active,
.btn-group.disabled.btn-raised:focus:not(:active),
.btn-group:disabled.btn-group-raised,
.btn-group:disabled.btn-group-raised.active,
.btn-group:disabled.btn-group-raised:active,
.btn-group:disabled.btn-group-raised:focus:not(:active),
.btn-group:disabled.btn-raised,
.btn-group:disabled.btn-raised.active,
.btn-group:disabled.btn-raised:active,
.btn-group:disabled.btn-raised:focus:not(:active),
.btn-group[disabled][disabled].btn-group-raised,
.btn-group[disabled][disabled].btn-group-raised.active,
.btn-group[disabled][disabled].btn-group-raised:active,
.btn-group[disabled][disabled].btn-group-raised:focus:not(:active),
.btn-group[disabled][disabled].btn-raised,
.btn-group[disabled][disabled].btn-raised.active,
.btn-group[disabled][disabled].btn-raised:active,
.btn-group[disabled][disabled].btn-raised:focus:not(:active),
.btn.disabled.btn-group-raised,
.btn.disabled.btn-group-raised.active,
.btn.disabled.btn-group-raised:active,
.btn.disabled.btn-group-raised:focus:not(:active),
.btn.disabled.btn-raised,
.btn.disabled.btn-raised.active,
.btn.disabled.btn-raised:active,
.btn.disabled.btn-raised:focus:not(:active),
.btn:disabled.btn-group-raised,
.btn:disabled.btn-group-raised.active,
.btn:disabled.btn-group-raised:active,
.btn:disabled.btn-group-raised:focus:not(:active),
.btn:disabled.btn-raised,
.btn:disabled.btn-raised.active,
.btn:disabled.btn-raised:active,
.btn:disabled.btn-raised:focus:not(:active),
.btn[disabled][disabled].btn-group-raised,
.btn[disabled][disabled].btn-group-raised.active,
.btn[disabled][disabled].btn-group-raised:active,
.btn[disabled][disabled].btn-group-raised:focus:not(:active),
.btn[disabled][disabled].btn-raised,
.btn[disabled][disabled].btn-raised.active,
.btn[disabled][disabled].btn-raised:active,
.btn[disabled][disabled].btn-raised:focus:not(:active),
.input-group-btn .btn.disabled.btn-group-raised,
.input-group-btn .btn.disabled.btn-group-raised.active,
.input-group-btn .btn.disabled.btn-group-raised:active,
.input-group-btn .btn.disabled.btn-group-raised:focus:not(:active),
.input-group-btn .btn.disabled.btn-raised,
.input-group-btn .btn.disabled.btn-raised.active,
.input-group-btn .btn.disabled.btn-raised:active,
.input-group-btn .btn.disabled.btn-raised:focus:not(:active),
.input-group-btn .btn:disabled.btn-group-raised,
.input-group-btn .btn:disabled.btn-group-raised.active,
.input-group-btn .btn:disabled.btn-group-raised:active,
.input-group-btn .btn:disabled.btn-group-raised:focus:not(:active),
.input-group-btn .btn:disabled.btn-raised,
.input-group-btn .btn:disabled.btn-raised.active,
.input-group-btn .btn:disabled.btn-raised:active,
.input-group-btn .btn:disabled.btn-raised:focus:not(:active),
.input-group-btn .btn[disabled][disabled].btn-group-raised,
.input-group-btn .btn[disabled][disabled].btn-group-raised.active,
.input-group-btn .btn[disabled][disabled].btn-group-raised:active,
.input-group-btn .btn[disabled][disabled].btn-group-raised:focus:not(:active),
.input-group-btn .btn[disabled][disabled].btn-raised,
.input-group-btn .btn[disabled][disabled].btn-raised.active,
.input-group-btn .btn[disabled][disabled].btn-raised:active,
.input-group-btn .btn[disabled][disabled].btn-raised:focus:not(:active),
fieldset[disabled][disabled] .btn-group-vertical.btn-group-raised,
fieldset[disabled][disabled] .btn-group-vertical.btn-group-raised.active,
fieldset[disabled][disabled] .btn-group-vertical.btn-group-raised:active,
fieldset[disabled][disabled] .btn-group-vertical.btn-group-raised:focus:not(:active),
fieldset[disabled][disabled] .btn-group-vertical.btn-raised,
fieldset[disabled][disabled] .btn-group-vertical.btn-raised.active,
fieldset[disabled][disabled] .btn-group-vertical.btn-raised:active,
fieldset[disabled][disabled] .btn-group-vertical.btn-raised:focus:not(:active),
fieldset[disabled][disabled] .btn-group.btn-group-raised,
fieldset[disabled][disabled] .btn-group.btn-group-raised.active,
fieldset[disabled][disabled] .btn-group.btn-group-raised:active,
fieldset[disabled][disabled] .btn-group.btn-group-raised:focus:not(:active),
fieldset[disabled][disabled] .btn-group.btn-raised,
fieldset[disabled][disabled] .btn-group.btn-raised.active,
fieldset[disabled][disabled] .btn-group.btn-raised:active,
fieldset[disabled][disabled] .btn-group.btn-raised:focus:not(:active),
fieldset[disabled][disabled] .btn.btn-group-raised,
fieldset[disabled][disabled] .btn.btn-group-raised.active,
fieldset[disabled][disabled] .btn.btn-group-raised:active,
fieldset[disabled][disabled] .btn.btn-group-raised:focus:not(:active),
fieldset[disabled][disabled] .btn.btn-raised,
fieldset[disabled][disabled] .btn.btn-raised.active,
fieldset[disabled][disabled] .btn.btn-raised:active,
fieldset[disabled][disabled] .btn.btn-raised:focus:not(:active),
fieldset[disabled][disabled] .input-group-btn .btn.btn-group-raised,
fieldset[disabled][disabled] .input-group-btn .btn.btn-group-raised.active,
fieldset[disabled][disabled] .input-group-btn .btn.btn-group-raised:active,
fieldset[disabled][disabled] .input-group-btn .btn.btn-group-raised:focus:not(:active),
fieldset[disabled][disabled] .input-group-btn .btn.btn-raised,
fieldset[disabled][disabled] .input-group-btn .btn.btn-raised.active,
fieldset[disabled][disabled] .input-group-btn .btn.btn-raised:active,
fieldset[disabled][disabled] .input-group-btn .btn.btn-raised:focus:not(:active) {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-group,
.btn-group-vertical {
    position: relative;
    margin: 10px 1px
}

.btn-group-vertical.open>.dropdown-toggle.btn,
.btn-group-vertical.open>.dropdown-toggle.btn.btn-default,
.btn-group.open>.dropdown-toggle.btn,
.btn-group.open>.dropdown-toggle.btn.btn-default {
    background: #EEE
}

.btn-group-vertical.open>.dropdown-toggle.btn.btn-inverse,
.btn-group.open>.dropdown-toggle.btn.btn-inverse {
    background-color: #3f51b5
}

.btn-group-vertical.open>.dropdown-toggle.btn.btn-primary,
.btn-group.open>.dropdown-toggle.btn.btn-primary {
    background-color: #2d4f5d
}

.btn-group-vertical.open>.dropdown-toggle.btn.btn-success,
.btn-group.open>.dropdown-toggle.btn.btn-success {
    background-color: #4caf50
}

.btn-group-vertical.open>.dropdown-toggle.btn.btn-info,
.btn-group.open>.dropdown-toggle.btn.btn-info {
    background: #03a9f4;
}

.btn-group-vertical.open>.dropdown-toggle.btn.btn-warning,
.btn-group.open>.dropdown-toggle.btn.btn-warning {
    background-color: #ff5722
}

.btn-group-vertical.open>.dropdown-toggle.btn.btn-danger,
.btn-group.open>.dropdown-toggle.btn.btn-danger {
    background-color: #f44336
}

.btn-group .dropdown-menu,
.btn-group-vertical .dropdown-menu {
    border-radius: 0 0 2px 2px
}

.btn-group-vertical.btn-group-raised,
.btn-group.btn-group-raised {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn-group .btn,
.btn-group .btn+.btn,
.btn-group .btn-group,
.btn-group .btn:active,
.btn-group-vertical .btn,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn-group,
.btn-group-vertical .btn:active {
    margin: 0
}

.checkbox label {
    cursor: pointer;
    padding-left: 0;
    color: rgba(0, 0, 0, .26)
}

.form-group.is-focused .checkbox label {
    color: rgba(0, 0, 0, .26)
}

.form-group.is-focused .checkbox label:focus,
.form-group.is-focused .checkbox label:hover {
    color: rgba(0, 0, 0, .54)
}

fieldset[disabled] .form-group.is-focused .checkbox label {
    color: rgba(0, 0, 0, .26)
}

.checkbox input[type=checkbox] {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
    pointer-events: none
}

.checkbox .checkbox-material {
    vertical-align: middle;
    position: relative;
    top: 3px
}

.checkbox .checkbox-material:before {
    display: block;
    position: absolute;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, .84);
    height: 5px;
    width: 5px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    -webkit-transform: scale3d(2.3, 2.3, 1);
    transform: scale3d(2.3, 2.3, 1)
}

.checkbox .checkbox-material .check {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid rgb(210, 210, 210);
    border-radius: 2px;
    overflow: hidden;
    /*z-index: 1*/
}

.checkbox .checkbox-material .check:before {
    position: absolute;
    content: "";
    -webkit-transform: rotate(55deg);
    -ms-transform: rotate(55deg);
    -o-transform: rotate(55deg);
    transform: rotate(55deg);
    display: block;
    margin-top: -7px;
    margin-left: 6px;
    width: 0;
    height: 0;
    -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    -webkit-animation: checkbox-off;
    -o-animation: checkbox-off;
    animation: checkbox-off
}

.checkbox label::before {
    display: none !important;
}

.checkbox input[type=checkbox]:focus+.checkbox-material .check:after {
    opacity: .2
}

.checkbox input[type=checkbox]:checked+.checkbox-material .check {
    color: #01acef;
    border-color: #01acef
}

.checkbox input[type=checkbox]:checked+.checkbox-material .check:before {
    color: #01acef;
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    -webkit-animation: checkbox-on .3s forwards;
    -o-animation: checkbox-on .3s forwards;
    animation: checkbox-on .3s forwards
}

.checkbox input[type=checkbox]:checked+.checkbox-material:before {
    -webkit-animation: rippleOn;
    -o-animation: rippleOn;
    animation: rippleOn
}

.checkbox input[type=checkbox]:checked+.checkbox-material .check:after {
    -webkit-animation: rippleOn .5s forwards;
    -o-animation: rippleOn .5s forwards;
    animation: rippleOn .5s forwards
}

.checkbox input[type=checkbox]:not(:checked)+.checkbox-material:before {
    -webkit-animation: rippleOff;
    -o-animation: rippleOff;
    animation: rippleOff
}

.checkbox input[type=checkbox]:not(:checked)+.checkbox-material .check:after {
    -webkit-animation: rippleOff .5s forwards;
    -o-animation: rippleOff .5s forwards;
    animation: rippleOff .5s forwards
}

.checkbox input[type=checkbox][disabled]+.circle,
.checkbox input[type=checkbox][disabled]:not(:checked)~.checkbox-material .check,
.checkbox input[type=checkbox][disabled]:not(:checked)~.checkbox-material .check:before,
fieldset[disabled] .checkbox,
fieldset[disabled] .checkbox input[type=checkbox] {
    opacity: .5
}

.checkbox input[type=checkbox][disabled]+.checkbox-material .check:after {
    background-color: rgba(0, 0, 0, .87);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.is-focused .checkbox .checkbox-material .check:before {
    -webkit-animation: checkbox-off .3s forwards;
    -o-animation: checkbox-off .3s forwards;
    animation: checkbox-off .3s forwards
}

.is-focused .checkbox input[type=checkbox]:checked+.checkbox-material:before {
    -webkit-animation: rippleOn .5s;
    -o-animation: rippleOn .5s;
    animation: rippleOn .5s
}

.is-focused .checkbox input[type=checkbox]:not(:checked)+.checkbox-material:before {
    -webkit-animation: rippleOff .5s;
    -o-animation: rippleOff .5s;
    animation: rippleOff .5s
}

@-webkit-keyframes checkbox-on {
    0% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px
    }
    50% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px
    }
}

@-o-keyframes checkbox-on {
    0% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px
    }
    50% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px
    }
    100% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px
    }
}

@keyframes checkbox-on {
    0% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px
    }
    50% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px
    }
}

@-webkit-keyframes checkbox-off {
    0% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset
    }
    50% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: -4px;
        margin-left: 6px;
        width: 0;
        height: 0;
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset
    }
    51% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset
    }
}

@-o-keyframes checkbox-off {
    0% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset
    }
    25% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset
    }
    50% {
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: -4px;
        margin-left: 6px;
        width: 0;
        height: 0;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset
    }
    51% {
        -o-transform: rotate(0);
        transform: rotate(0);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset
    }
    100% {
        -o-transform: rotate(0);
        transform: rotate(0);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset
    }
}

@keyframes checkbox-off {
    0% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset
    }
    50% {
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: -4px;
        margin-left: 6px;
        width: 0;
        height: 0;
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset
    }
    51% {
        -webkit-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset
    }
    100% {
        -webkit-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset
    }
}

@-webkit-keyframes rippleOn {
    0% {
        opacity: 0
    }
    50% {
        opacity: .2
    }
    100% {
        opacity: 0
    }
}

@-o-keyframes rippleOn {
    0% {
        opacity: 0
    }
    50% {
        opacity: .2
    }
    100% {
        opacity: 0
    }
}

@keyframes rippleOn {
    0% {
        opacity: 0
    }
    50% {
        opacity: .2
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes rippleOff {
    0% {
        opacity: 0
    }
    50% {
        opacity: .2
    }
    100% {
        opacity: 0
    }
}

@-o-keyframes rippleOff {
    0% {
        opacity: 0
    }
    50% {
        opacity: .2
    }
    100% {
        opacity: 0
    }
}

@keyframes rippleOff {
    0% {
        opacity: 0
    }
    50% {
        opacity: .2
    }
    100% {
        opacity: 0
    }
}

.togglebutton {
    vertical-align: middle
}

.togglebutton,
.togglebutton .toggle,
.togglebutton input,
.togglebutton label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.togglebutton label {
    cursor: pointer;
    color: rgba(0, 0, 0, .26)
}

.form-group.is-focused .togglebutton label {
    color: rgba(0, 0, 0, .26)
}

.form-group.is-focused .togglebutton label:focus,
.form-group.is-focused .togglebutton label:hover {
    color: rgba(0, 0, 0, .54)
}

fieldset[disabled] .form-group.is-focused .togglebutton label {
    color: rgba(0, 0, 0, .26)
}

.togglebutton label input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0
}

.togglebutton label .toggle {
    text-align: left
}

.togglebutton label .toggle,
.togglebutton label input[type=checkbox][disabled]+.toggle {
    content: "";
    display: inline-block;
    width: 30px;
    height: 15px;
    background-color: rgba(80, 80, 80, .7);
    border-radius: 15px;
    margin-right: 15px;
    -webkit-transition: background .3s ease;
    -o-transition: background .3s ease;
    transition: background .3s ease;
    vertical-align: middle
}

.togglebutton label .toggle:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #F1F1F1;
    border-radius: 20px;
    position: relative;
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4);
    left: -5px;
    top: -2px;
    -webkit-transition: left .3s ease, background .3s ease, -webkit-box-shadow .1s ease;
    -o-transition: left .3s ease, background .3s ease, box-shadow .1s ease;
    transition: left .3s ease, background .3s ease, box-shadow .1s ease
}

.togglebutton label input[type=checkbox][disabled]+.toggle:after,
.togglebutton label input[type=checkbox][disabled]:checked+.toggle:after {
    background-color: #BDBDBD
}

.togglebutton label input[type=checkbox]+.toggle:active:after,
.togglebutton label input[type=checkbox][disabled]+.toggle:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px rgba(0, 0, 0, .1)
}

.togglebutton label input[type=checkbox]:checked+.toggle:after {
    left: 15px
}

.togglebutton label input[type=checkbox]:checked+.toggle {
    background-color: rgba(0, 150, 136, .5)
}

.togglebutton label input[type=checkbox]:checked+.toggle:after {
    background-color: #01acef
}

.togglebutton label input[type=checkbox]:checked+.toggle:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px rgba(0, 150, 136, .1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px rgba(0, 150, 136, .1)
}

.radio label {
    cursor: pointer;
    padding-left: 45px;
    position: relative;
    color: rgba(0, 0, 0, .26)
}

.form-group.is-focused .radio label {
    color: rgba(0, 0, 0, .26)
}

.form-group.is-focused .radio label:focus,
.form-group.is-focused .radio label:hover {
    color: rgba(0, 0, 0, .54)
}

fieldset[disabled] .form-group.is-focused .radio label {
    color: rgba(0, 0, 0, .26)
}

.radio label span {
    display: block;
    position: absolute;
    left: 10px;
    top: 2px;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
    transition-duration: .2s
}

.radio label .circle {
    border: 2px solid rgba(0, 0, 0, .54);
    height: 15px;
    width: 15px;
    border-radius: 100%
}

.radio label .check {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background-color: #01acef;
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0)
}

.radio label .check:after {
    display: block;
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, .87);
    left: -18px;
    top: -18px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1);
    transform: scale3d(1.5, 1.5, 1)
}

.radio label input[type=radio]:not(:checked)~.check:after {
    -webkit-animation: rippleOff .5s;
    -o-animation: rippleOff .5s;
    animation: rippleOff .5s
}

.radio label input[type=radio]:checked~.check:after {
    -webkit-animation: rippleOn;
    -o-animation: rippleOn;
    animation: rippleOn
}

.radio input[type=radio] {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden
}

.radio input[type=radio]:checked~.check,
.radio input[type=radio]:checked~.circle {
    opacity: 1
}

.radio input[type=radio]:checked~.check {
    background-color: #01acef
}

.radio input[type=radio]:checked~.circle {
    border-color: #01acef
}

.radio input[type=radio]:checked~.check {
    -webkit-transform: scale3d(.55, .55, 1);
    transform: scale3d(.55, .55, 1)
}

.radio input[type=radio][disabled]~.check,
.radio input[type=radio][disabled]~.circle {
    opacity: .26
}

.radio input[type=radio][disabled]~.check {
    background-color: #000
}

.radio input[type=radio][disabled]~.circle {
    border-color: #000
}

.theme-dark .radio input[type=radio][disabled]~.check,
.theme-dark .radio input[type=radio][disabled]~.circle {
    opacity: .3
}

.theme-dark .radio input[type=radio][disabled]~.check {
    background-color: #fff
}

.theme-dark .radio input[type=radio][disabled]~.circle {
    border-color: #fff
}

.is-focused .radio input[type=radio]:checked~.check:after {
    -webkit-animation: rippleOn .5s;
    -o-animation: rippleOn .5s;
    animation: rippleOn .5s
}

@keyframes rippleOn {
    0% {
        opacity: 0
    }
    50% {
        opacity: .2
    }
    100% {
        opacity: 0
    }
}

@keyframes rippleOff {
    0% {
        opacity: 0
    }
    50% {
        opacity: .2
    }
    100% {
        opacity: 0
    }
}

legend {
    margin-bottom: 22px;
    font-size: 24px
}

output {
    padding-top: 8px;
    font-size: 16px;
    line-height: 1.42857143
}

.form-control {
    height: 38px;
    padding: 7px 0;
    font-size: 16px;
    line-height: 1.42857143
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type=date].form-control,
    input[type=time].form-control,
    input[type=datetime-local].form-control,
    input[type=month].form-control {
        line-height: 38px
    }
    .input-group-sm input[type=date],
    .input-group-sm input[type=time],
    .input-group-sm input[type=datetime-local],
    .input-group-sm input[type=month],
    input[type=date].input-sm,
    input[type=time].input-sm,
    input[type=datetime-local].input-sm,
    input[type=month].input-sm {
        line-height: 24px
    }
    .input-group-lg input[type=date],
    .input-group-lg input[type=time],
    .input-group-lg input[type=datetime-local],
    .input-group-lg input[type=month],
    input[type=date].input-lg,
    input[type=time].input-lg,
    input[type=datetime-local].input-lg,
    input[type=month].input-lg {
        line-height: 44px
    }
}

.checkbox label,
.radio label {
    min-height: 22px
}

.form-control-static {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 38px
}

.input-sm .input-sm {
    height: 24px;
    padding: 3px 0;
    font-size: 11px;
    line-height: 1.5;
    border-radius: 0
}

.input-sm select.input-sm {
    height: 24px;
    line-height: 24px
}

.input-sm select[multiple].input-sm,
.input-sm textarea.input-sm {
    height: auto
}

.form-group-sm .form-control {
    height: 24px;
    padding: 3px 0;
    font-size: 14px;
    line-height: 1.5
}

.form-group-sm select.form-control {
    height: 24px;
    line-height: 24px
}

.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control {
    height: auto
}

.form-group-sm .form-control-static {
    height: 24px;
    min-height: 33px;
    padding: 4px 0;
    font-size: 11px;
    line-height: 1.5
}

.input-lg .input-lg {
    height: 44px;
    padding: 9px 0;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 0
}

.input-lg select.input-lg {
    height: 44px;
    line-height: 44px
}

.input-lg select[multiple].input-lg,
.input-lg textarea.input-lg {
    height: auto
}

.form-group-lg .form-control {
    height: 44px;
    padding: 9px 0;
    font-size: 18px;
    line-height: 1.3333333
}

.form-group-lg select.form-control {
    height: 44px;
    line-height: 44px
}

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
    height: auto
}

.form-group-lg .form-control-static {
    height: 44px;
    min-height: 40px;
    padding: 10px 0;
    font-size: 18px;
    line-height: 1.3333333
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
    padding-top: 8px
}

.form-horizontal .checkbox,
.form-horizontal .radio {
    min-height: 30px
}

@media (min-width:768px) {
    .form-horizontal .control-label {
        padding-top: 8px
    }
}

@media (min-width:768px) {
    .form-horizontal .form-group-lg .control-label {
        padding-top: 13px;
        font-size: 18px
    }
}

@media (min-width:768px) {
    .form-horizontal .form-group-sm .control-label {
        padding-top: 0px;
        font-size: 14px
    }
}

.label {
    border-radius: 1px
}

.label,
.label.label-default {
    background-color: #9e9e9e
}

.label.label-inverse {
    background-color: #3f51b5
}

.label.label-primary {
    background-color: #01acef
}

.label.label-success {
    background-color: #4caf50
}

.label.label-info {
    background-color: #03a9f4
}

.label.label-warning {
    background-color: #ff5722
}

.label.label-danger {
    background-color: #f44336
}

.form-control,
.form-group .form-control {
    border: 0;
    /*background-image: -webkit-gradient(linear, left top, left bottom, from(#01acef), to(#01acef)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#01acef, #01acef), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#01acef, #01acef), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#01acef, #01acef), linear-gradient(#D2D2D2, #D2D2D2);
    -webkit-background-size: 0 2px, 100% 1px;*/
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center -webkit-calc(100% - 1px);
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: background 0s ease-out;
    -o-transition: background 0s ease-out;
    transition: background 0s ease-out;
    float: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #c5c5c5;
}

.form-control::-moz-placeholder,
.form-group .form-control::-moz-placeholder {
    color: #BDBDBD;
    font-weight: 400
}

.form-control:-ms-input-placeholder,
.form-group .form-control:-ms-input-placeholder {
    color: #BDBDBD;
    font-weight: 400
}

.form-control::-webkit-input-placeholder,
.form-group .form-control::-webkit-input-placeholder {
    color: #BDBDBD;
    font-weight: 400
}

.form-control[disabled],
.form-control[readonly],
.form-group .form-control[disabled],
.form-group .form-control[readonly],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
    background-color: rgba(0, 0, 0, 0)
}

.form-control[disabled],
.form-group .form-control[disabled],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
    background-image: none;
    border-bottom: 1px dotted #D2D2D2
}

.form-group {
    position: relative
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-static label.control-label {
    position: absolute;
    pointer-events: none;
    -webkit-transition: .3s ease all;
    -o-transition: .3s ease all;
    transition: .3s ease all
}

.form-group.label-floating label.control-label {
    will-change: left, top, contents
}

.form-group.label-placeholder:not(.is-empty) label.control-label {
    display: none
}

.form-group .help-block {
    position: absolute;
    display: none
}

.form-group.is-focused .form-control {
    outline: 0;
    /*background-image: -webkit-gradient(linear, left top, left bottom, from(#01acef), to(#01acef)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#01acef, #01acef), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#01acef, #01acef), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#01acef, #01acef), linear-gradient(#D2D2D2, #D2D2D2);
    -webkit-background-size: 100% 2px, 100% 1px;
    background-size: 100% 2px, 100% 1px;*/
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
    border-bottom: 1px solid #01acef;
}

.form-group.is-focused .form-control .material-input:after {
    background-color: #01acef
}

.form-group.is-focused label,
.form-group.is-focused label.control-label {
    color: #01acef
}

.form-group.is-focused.label-placeholder label,
.form-group.is-focused.label-placeholder label.control-label {
    color: #BDBDBD
}

.form-group.is-focused .help-block {
    display: block
}

.form-group.has-warning .form-control {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-group.has-warning.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff5722), to(#ff5722)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#ff5722, #ff5722), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#ff5722, #ff5722), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#ff5722, #ff5722), linear-gradient(#D2D2D2, #D2D2D2)
}

.form-group.has-warning .help-block,
.form-group.has-warning label.control-label {
    color: #ff5722
}

.form-group.has-error .form-control {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-group.has-error.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f44336), to(#f44336)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#f44336, #f44336), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#f44336, #f44336), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#f44336, #f44336), linear-gradient(#D2D2D2, #D2D2D2)
}

.form-group.has-error .help-block,
.form-group.has-error label.control-label {
    color: #f44336
}

.form-group.has-success .form-control {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-group.has-success.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4caf50), to(#4caf50)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#4caf50, #4caf50), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#4caf50, #4caf50), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2)
}

.form-group.has-success .help-block,
.form-group.has-success label.control-label {
    color: #4caf50
}

.form-group.has-info .form-control {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-group.has-info.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#03a9f4), to(#03a9f4)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#03a9f4, #03a9f4), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#03a9f4, #03a9f4), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#03a9f4, #03a9f4), linear-gradient(#D2D2D2, #D2D2D2)
}

.form-group.has-info .help-block,
.form-group.has-info label.control-label {
    color: #03a9f4
}

.form-group textarea {
    /*resize: none*/
}

.form-group textarea~.form-control-highlight {
    margin-top: -11px
}


/*.form-group select{-webkit-appearance:none;-moz-appearance:none;appearance:none} */

.form-group select~.material-input:after {
    display: none
}

.form-control {
    margin-bottom: 7px
}

.form-control::-moz-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400
}

.form-control:-ms-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400
}

.form-control::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400
}

.checkbox label,
.radio label,
label {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400
}

label.control-label {
    font-size: 12px;
    line-height: 1.07142857;
    color: #BDBDBD;
    font-weight: 400;
    margin: 16px 0 0 0
}

.help-block {
    margin-top: 0;
    font-size: 12px
}

.form-group {
    padding-bottom: 7px;
    margin: 28px 0 0 0
}

.form-group .form-control {
    margin-bottom: 7px
}

.form-group .form-control::-moz-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400
}

.form-group .form-control:-ms-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400
}

.form-group .form-control::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400
}

.form-group .checkbox label,
.form-group .radio label,
.form-group label {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400
}

.form-group label.control-label {
    font-size: 16px;
    line-height: 1.07142857;
    color: #BDBDBD;
    font-weight: 400;
    margin: 16px 0 0 0
}

.form-group .help-block {
    margin-top: 0;
    font-size: 12px
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
    top: -7px;
    font-size: 16px;
    line-height: 1.42857143
}

.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label,
.form-group.label-static label.control-label {
    top: -30px;
    left: 0;
    font-size: 12px;
    line-height: 1.07142857
}

.form-group.label-floating input.form-control:-webkit-autofill~label.control-label label.control-label {
    top: -30px;
    left: 0;
    font-size: 12px;
    line-height: 1.07142857
}

.form-group.form-group-sm {
    padding-bottom: 3px;
    margin: 21px 0 0 0
}

.form-group.form-group-sm .form-control {
    margin-bottom: 3px
}

.form-group.form-group-sm .form-control::-moz-placeholder {
    font-size: 11px;
    line-height: 1.5;
    color: #BDBDBD;
    font-weight: 400
}

.form-group.form-group-sm .form-control:-ms-input-placeholder {
    font-size: 11px;
    line-height: 1.5;
    color: #BDBDBD;
    font-weight: 400
}

.form-group.form-group-sm .form-control::-webkit-input-placeholder {
    font-size: 11px;
    line-height: 1.5;
    color: #BDBDBD;
    font-weight: 400
}

.form-group.form-group-sm .checkbox label,
.form-group.form-group-sm .radio label,
.form-group.form-group-sm label {
    font-size: 11px;
    line-height: 1.5;
    color: #BDBDBD;
    font-weight: 400
}

.form-group.form-group-sm label.control-label {
    font-size: 9px;
    line-height: 1.125;
    color: #BDBDBD;
    font-weight: 400;
    margin: 16px 0 0 0
}

.form-group.form-group-sm .help-block {
    margin-top: 0;
    font-size: 9px
}

.form-group.form-group-sm.label-floating label.control-label,
.form-group.form-group-sm.label-placeholder label.control-label {
    top: -11px;
    font-size: 11px;
    line-height: 1.5
}

.form-group.form-group-sm.label-floating.is-focused label.control-label,
.form-group.form-group-sm.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-sm.label-static label.control-label {
    top: -25px;
    left: 0;
    font-size: 9px;
    line-height: 1.125
}

.form-group.form-group-sm.label-floating input.form-control:-webkit-autofill~label.control-label label.control-label {
    top: -25px;
    left: 0;
    font-size: 9px;
    line-height: 1.125
}

.form-group.form-group-lg {
    padding-bottom: 9px;
    margin: 30px 0 0 0
}

.form-group.form-group-lg .form-control {
    margin-bottom: 9px
}

.form-group.form-group-lg .form-control::-moz-placeholder {
    font-size: 18px;
    line-height: 1.3333333;
    color: #BDBDBD;
    font-weight: 400
}

.form-group.form-group-lg .form-control:-ms-input-placeholder {
    font-size: 18px;
    line-height: 1.3333333;
    color: #BDBDBD;
    font-weight: 400
}

.form-group.form-group-lg .form-control::-webkit-input-placeholder {
    font-size: 18px;
    line-height: 1.3333333;
    color: #BDBDBD;
    font-weight: 400
}

.form-group.form-group-lg .checkbox label,
.form-group.form-group-lg .radio label,
.form-group.form-group-lg label {
    font-size: 18px;
    line-height: 1.3333333;
    color: #BDBDBD;
    font-weight: 400
}

.form-group.form-group-lg label.control-label {
    font-size: 14px;
    line-height: .99999998;
    color: #BDBDBD;
    font-weight: 400;
    margin: 16px 0 0 0
}

.form-group.form-group-lg .help-block {
    margin-top: 0;
    font-size: 14px
}

.form-group.form-group-lg.label-floating label.control-label,
.form-group.form-group-lg.label-placeholder label.control-label {
    top: -5px;
    font-size: 18px;
    line-height: 1.3333333
}

.form-group.form-group-lg.label-floating.is-focused label.control-label,
.form-group.form-group-lg.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-lg.label-static label.control-label {
    top: -32px;
    left: 0;
    font-size: 14px;
    line-height: .99999998
}

.form-group.form-group-lg.label-floating input.form-control:-webkit-autofill~label.control-label label.control-label {
    top: -32px;
    left: 0;
    font-size: 14px;
    line-height: .99999998
}

select.form-control {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0
}

.form-group.is-focused select.form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #D2D2D2
}

.form-group.is-focused select.form-control[multiple],
select.form-control[multiple] {
    height: 85px
}

.input-group-btn .btn {
    margin: 0 0 7px 0
}

.form-group.form-group-sm .input-group-btn .btn {
    margin: 0 0 3px 0
}

.form-group.form-group-lg .input-group-btn .btn {
    margin: 0 0 9px 0
}

.input-group .input-group-btn {
    padding: 0 12px
}

.input-group .input-group-addon {
    border: 0;
    background: 0 0
}

.form-group input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100
}

legend {
    border-bottom: 0
}

.list-group {
    border-radius: 0
}

.list-group .list-group-item {
    background-color: transparent;
    overflow: hidden;
    border: 0;
    border-radius: 0;
    padding: 0 16px
}

.list-group .list-group-item.baseline {
    border-bottom: 1px solid #cecece
}

.list-group .list-group-item.baseline:last-child {
    border-bottom: none
}

.list-group .list-group-item .row-action-primary,
.list-group .list-group-item .row-picture {
    display: inline-block;
    padding-right: 16px
}

.list-group .list-group-item .row-action-primary i,
.list-group .list-group-item .row-action-primary img,
.list-group .list-group-item .row-action-primary label,
.list-group .list-group-item .row-picture i,
.list-group .list-group-item .row-picture img,
.list-group .list-group-item .row-picture label {
    display: block;
    width: 56px;
    height: 56px
}

.list-group .list-group-item .row-action-primary img,
.list-group .list-group-item .row-picture img {
    background: rgba(0, 0, 0, .1);
    padding: 1px
}

.list-group .list-group-item .row-action-primary img.circle,
.list-group .list-group-item .row-picture img.circle {
    border-radius: 100%
}

.list-group .list-group-item .row-action-primary i,
.list-group .list-group-item .row-picture i {
    background: rgba(0, 0, 0, .25);
    border-radius: 100%;
    text-align: center;
    line-height: 56px;
    font-size: 20px;
    color: #fff
}

.list-group .list-group-item .row-action-primary label,
.list-group .list-group-item .row-picture label {
    margin-left: 7px;
    margin-right: -7px;
    margin-top: 5px;
    margin-bottom: -5px
}

.list-group .list-group-item .row-action-primary label .checkbox-material,
.list-group .list-group-item .row-picture label .checkbox-material {
    left: -10px
}

.list-group .list-group-item .row-content {
    display: inline-block;
    width: -webkit-calc(100% - 92px);
    width: calc(100% - 92px);
    min-height: 66px
}

.list-group .list-group-item .row-content .action-secondary {
    position: absolute;
    right: 16px;
    top: 16px
}

.list-group .list-group-item .row-content .action-secondary i {
    font-size: 20px;
    color: rgba(0, 0, 0, .25);
    cursor: pointer
}

.list-group .list-group-item .row-content .action-secondary~* {
    max-width: -webkit-calc(100% - 30px);
    max-width: calc(100% - 30px)
}

.list-group .list-group-item .row-content .least-content {
    position: absolute;
    right: 16px;
    top: 0;
    color: rgba(0, 0, 0, .54);
    font-size: 14px
}

.list-group .list-group-item .list-group-item-heading {
    color: rgba(0, 0, 0, .77);
    font-size: 20px;
    line-height: 29px
}

.list-group .list-group-item.active:focus,
.list-group .list-group-item.active:hover {
    background: rgba(0, 0, 0, .15);
    outline: 10px solid rgba(0, 0, 0, .15)
}

.list-group .list-group-item.active .list-group-item-heading,
.list-group .list-group-item.active .list-group-item-text {
    color: rgba(0, 0, 0, .87)
}

.list-group .list-group-separator {
    clear: both;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px
}

.list-group .list-group-separator:before {
    content: "";
    width: -webkit-calc(100% - 90px);
    width: calc(100% - 90px);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    float: right
}

.navbar {
    background-color: #01acef;
    border: 0;
    border-radius: 0
}

.navbar .navbar-brand {
    position: relative;
    height: 60px;
    line-height: 30px;
    color: inherit
}

.navbar .navbar-brand:focus,
.navbar .navbar-brand:hover {
    color: inherit;
    background-color: transparent
}

.navbar .navbar-text {
    color: inherit;
    margin-top: 20px;
    margin-bottom: 20px
}

.navbar .navbar-nav>li>a {
    color: inherit;
    padding-top: 20px;
    padding-bottom: 20px
}

.navbar .navbar-nav>li>a:focus,
.navbar .navbar-nav>li>a:hover {
    color: inherit;
    background-color: transparent
}

.navbar .navbar-nav>.active>a,
.navbar .navbar-nav>.active>a:focus,
.navbar .navbar-nav>.active>a:hover {
    color: inherit;
    background-color: rgba(255, 255, 255, .1)
}

.navbar .navbar-nav>.disabled>a,
.navbar .navbar-nav>.disabled>a:focus,
.navbar .navbar-nav>.disabled>a:hover {
    color: inherit;
    background-color: transparent;
    opacity: .9
}

.navbar .navbar-toggle {
    border: 0
}

.navbar .navbar-toggle:focus,
.navbar .navbar-toggle:hover {
    background-color: transparent
}

.navbar .navbar-toggle .icon-bar {
    background-color: inherit;
    border: 1px solid
}

.navbar .navbar-default .navbar-toggle,
.navbar .navbar-inverse .navbar-toggle {
    border-color: transparent
}

.navbar .navbar-collapse,
.navbar .navbar-form {
    border-color: rgba(0, 0, 0, .1)
}

.navbar .navbar-nav>.open>a,
.navbar .navbar-nav>.open>a:focus,
.navbar .navbar-nav>.open>a:hover {
    background-color: transparent;
    color: inherit
}

@media (max-width:767px) {
    .navbar .navbar-nav .navbar-text {
        color: inherit;
        margin-top: 15px;
        margin-bottom: 15px
    }
    .navbar .navbar-nav .open .dropdown-menu>.dropdown-header {
        border: 0;
        color: inherit
    }
    .navbar .navbar-nav .open .dropdown-menu .divider {
        border-bottom: 1px solid;
        opacity: .08
    }
    .navbar .navbar-nav .open .dropdown-menu>li>a {
        color: inherit
    }
    .navbar .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar .navbar-nav .open .dropdown-menu>li>a:hover {
        color: inherit;
        background-color: transparent
    }
    .navbar .navbar-nav .open .dropdown-menu>.active>a,
    .navbar .navbar-nav .open .dropdown-menu>.active>a:focus,
    .navbar .navbar-nav .open .dropdown-menu>.active>a:hover {
        color: inherit;
        background-color: transparent
    }
    .navbar .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar .navbar-nav .open .dropdown-menu>.disabled>a:focus,
    .navbar .navbar-nav .open .dropdown-menu>.disabled>a:hover {
        color: inherit;
        background-color: transparent
    }
}

.navbar .navbar-link {
    color: inherit
}

.navbar .navbar-link:hover {
    color: inherit
}

.navbar .btn-link {
    color: inherit
}

.navbar .btn-link:focus,
.navbar .btn-link:hover {
    color: inherit
}

.navbar .btn-link[disabled]:focus,
.navbar .btn-link[disabled]:hover,
fieldset[disabled] .navbar .btn-link:focus,
fieldset[disabled] .navbar .btn-link:hover {
    color: inherit
}

.navbar .navbar-form {
    margin-top: 16px
}

.navbar .navbar-form .form-group {
    margin: 0;
    padding: 0
}

.navbar .navbar-form .form-group .material-input:before,
.navbar .navbar-form .form-group.is-focused .material-input:after {
    background-color: inherit
}

.navbar .navbar-form .form-control,
.navbar .navbar-form .form-group .form-control {
    border-color: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
    height: 28px;
    font-size: 14px;
    line-height: 1.42857143
}

.navbar,
.navbar.navbar-default {
    background-color: #01acef;
    color: rgba(255, 255, 255, .84)
}

.navbar .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar .navbar-form input.form-control::-moz-placeholder,
.navbar.navbar-default .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar.navbar-default .navbar-form input.form-control::-moz-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar .navbar-form input.form-control:-ms-input-placeholder,
.navbar.navbar-default .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar.navbar-default .navbar-form input.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar .navbar-form input.form-control::-webkit-input-placeholder,
.navbar.navbar-default .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar.navbar-default .navbar-form input.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar .dropdown-menu,
.navbar.navbar-default .dropdown-menu {
    /*border-radius: 2px*/
}

.navbar .dropdown-menu li>a,
.navbar.navbar-default .dropdown-menu li>a {
    font-size: 16px;
    padding: 13px 16px
}

.navbar .dropdown-menu li>a:focus,
.navbar .dropdown-menu li>a:hover,
.navbar.navbar-default .dropdown-menu li>a:focus,
.navbar.navbar-default .dropdown-menu li>a:hover {
    color: #01acef;
    background-color: #eee
}

.navbar .dropdown-menu .active>a,
.navbar.navbar-default .dropdown-menu .active>a {
    background-color: #01acef;
    color: rgba(255, 255, 255, .84)
}

.navbar .dropdown-menu .active>a:focus,
.navbar .dropdown-menu .active>a:hover,
.navbar.navbar-default .dropdown-menu .active>a:focus,
.navbar.navbar-default .dropdown-menu .active>a:hover {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-inverse {
    background-color: #3f51b5;
    color: #fff
}

.navbar.navbar-inverse .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar.navbar-inverse .navbar-form input.form-control::-moz-placeholder {
    color: #fff
}

.navbar.navbar-inverse .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar.navbar-inverse .navbar-form input.form-control:-ms-input-placeholder {
    color: #fff
}

.navbar.navbar-inverse .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar.navbar-inverse .navbar-form input.form-control::-webkit-input-placeholder {
    color: #fff
}

.navbar.navbar-inverse .dropdown-menu {
    border-radius: 2px
}

.navbar.navbar-inverse .dropdown-menu li>a {
    font-size: 16px;
    padding: 13px 16px
}

.navbar.navbar-inverse .dropdown-menu li>a:focus,
.navbar.navbar-inverse .dropdown-menu li>a:hover {
    color: #3f51b5;
    background-color: #eee
}

.navbar.navbar-inverse .dropdown-menu .active>a {
    background-color: #3f51b5;
    color: #fff
}

.navbar.navbar-inverse .dropdown-menu .active>a:focus,
.navbar.navbar-inverse .dropdown-menu .active>a:hover {
    color: #fff
}

.navbar.navbar-primary {
    background-color: #01acef;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-primary .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar.navbar-primary .navbar-form input.form-control::-moz-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-primary .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar.navbar-primary .navbar-form input.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-primary .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar.navbar-primary .navbar-form input.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-primary .dropdown-menu {
    border-radius: 2px
}

.navbar.navbar-primary .dropdown-menu li>a {
    font-size: 16px;
    padding: 13px 16px
}

.navbar.navbar-primary .dropdown-menu li>a:focus,
.navbar.navbar-primary .dropdown-menu li>a:hover {
    color: #01acef;
    background-color: #eee
}

.navbar.navbar-primary .dropdown-menu .active>a {
    background-color: #01acef;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-primary .dropdown-menu .active>a:focus,
.navbar.navbar-primary .dropdown-menu .active>a:hover {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-success {
    background-color: #4caf50;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-success .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar.navbar-success .navbar-form input.form-control::-moz-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-success .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar.navbar-success .navbar-form input.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-success .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar.navbar-success .navbar-form input.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-success .dropdown-menu {
    border-radius: 2px
}

.navbar.navbar-success .dropdown-menu li>a {
    font-size: 16px;
    padding: 13px 16px
}

.navbar.navbar-success .dropdown-menu li>a:focus,
.navbar.navbar-success .dropdown-menu li>a:hover {
    color: #4caf50;
    background-color: #eee
}

.navbar.navbar-success .dropdown-menu .active>a {
    background-color: #4caf50;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-success .dropdown-menu .active>a:focus,
.navbar.navbar-success .dropdown-menu .active>a:hover {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-info {
    background-color: #03a9f4;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-info .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar.navbar-info .navbar-form input.form-control::-moz-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-info .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar.navbar-info .navbar-form input.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-info .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar.navbar-info .navbar-form input.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-info .dropdown-menu {
    border-radius: 2px
}

.navbar.navbar-info .dropdown-menu li>a {
    font-size: 16px;
    padding: 13px 16px
}

.navbar.navbar-info .dropdown-menu li>a:focus,
.navbar.navbar-info .dropdown-menu li>a:hover {
    color: #03a9f4;
    background-color: #eee
}

.navbar.navbar-info .dropdown-menu .active>a {
    background-color: #03a9f4;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-info .dropdown-menu .active>a:focus,
.navbar.navbar-info .dropdown-menu .active>a:hover {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-warning {
    background-color: #ff5722;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-warning .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar.navbar-warning .navbar-form input.form-control::-moz-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-warning .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar.navbar-warning .navbar-form input.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-warning .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar.navbar-warning .navbar-form input.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-warning .dropdown-menu {
    border-radius: 2px
}

.navbar.navbar-warning .dropdown-menu li>a {
    font-size: 16px;
    padding: 13px 16px
}

.navbar.navbar-warning .dropdown-menu li>a:focus,
.navbar.navbar-warning .dropdown-menu li>a:hover {
    color: #ff5722;
    background-color: #eee
}

.navbar.navbar-warning .dropdown-menu .active>a {
    background-color: #ff5722;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-warning .dropdown-menu .active>a:focus,
.navbar.navbar-warning .dropdown-menu .active>a:hover {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-danger {
    background-color: #f44336;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-danger .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar.navbar-danger .navbar-form input.form-control::-moz-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-danger .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar.navbar-danger .navbar-form input.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-danger .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar.navbar-danger .navbar-form input.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-danger .dropdown-menu {
    border-radius: 2px
}

.navbar.navbar-danger .dropdown-menu li>a {
    font-size: 16px;
    padding: 13px 16px
}

.navbar.navbar-danger .dropdown-menu li>a:focus,
.navbar.navbar-danger .dropdown-menu li>a:hover {
    color: #f44336;
    background-color: #eee
}

.navbar.navbar-danger .dropdown-menu .active>a {
    background-color: #f44336;
    color: rgba(255, 255, 255, .84)
}

.navbar.navbar-danger .dropdown-menu .active>a:focus,
.navbar.navbar-danger .dropdown-menu .active>a:hover {
    color: rgba(255, 255, 255, .84)
}

.navbar-inverse {
    background-color: #3f51b5
}

@media (max-width:1199px) {
    .navbar .navbar-brand {
        height: 50px;
        padding: 10px 15px
    }
    .navbar .navbar-form {
        margin-top: 10px
    }
    .navbar .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px
    }
}

.dropdown-menu {
    border: 0;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26)
}

.dropdown-menu .divider {
    background-color: rgba(0, 0, 0, .12)
}

.dropdown-menu li {
    /*overflow: hidden;
    position: relative*/
}

.dropdown-menu li a:hover {
    background-color: transparent;
    color: #01acef
}

.alert {
    border: 0;
    border-radius: 0
}

.alert,
.alert.alert-default {
    background-color: rgba(255, 255, 255, .84);
    color: rgba(255, 255, 255, .84)
}

.alert .alert-link,
.alert a,
.alert.alert-default .alert-link,
.alert.alert-default a {
    color: rgba(255, 255, 255, .84)
}

.alert.alert-inverse {
    background-color: #3f51b5;
    color: #fff
}

.alert.alert-inverse .alert-link,
.alert.alert-inverse a {
    color: #fff
}

.alert.alert-primary {
    background-color: #01acef;
    color: rgba(255, 255, 255, .84)
}

.alert.alert-primary .alert-link,
.alert.alert-primary a {
    color: rgba(255, 255, 255, .84)
}

.alert.alert-success {
    background-color: #4caf50;
    color: rgba(255, 255, 255, .84)
}

.alert.alert-success .alert-link,
.alert.alert-success a {
    color: rgba(255, 255, 255, .84)
}

.alert.alert-info {
    background-color: #03a9f4;
    color: rgba(255, 255, 255, .84)
}

.alert.alert-info .alert-link,
.alert.alert-info a {
    color: rgba(255, 255, 255, .84)
}

.alert.alert-warning {
    background-color: #b97429;
    color: rgba(255, 255, 255, .84)
}

.alert.alert-warning .alert-link,
.alert.alert-warning a {
    color: rgba(255, 255, 255, .84)
}

.alert.alert-danger {
    background-color: #f44336;
    color: rgba(255, 255, 255, .84)
}

.alert.alert-danger .alert-link,
.alert.alert-danger a {
    color: rgba(255, 255, 255, .84)
}

.alert-danger,
.alert-info,
.alert-success,
.alert-warning {
    color: rgba(255, 255, 255, .84)
}

.alert-default .alert-link,
.alert-default a {
    color: rgba(0, 0, 0, .87)
}

.progress {
    height: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #c8c8c8
}

.progress .progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none
}

.progress .progress-bar,
.progress .progress-bar.progress-bar-default {
    background-color: #01acef
}

.progress .progress-bar.progress-bar-inverse {
    background-color: #3f51b5
}

.progress .progress-bar.progress-bar-primary {
    background-color: #01acef
}

.progress .progress-bar.progress-bar-success {
    background-color: #4caf50
}

.progress .progress-bar.progress-bar-info {
    background-color: #03a9f4
}

.progress .progress-bar.progress-bar-warning {
    background-color: #ff5722
}

.progress .progress-bar.progress-bar-danger {
    background-color: #f44336
}

.text-warning {
    color: #edcb3e
}

.text-primary {
    color: #01acef
}

.text-danger {
    color: #f44336
}

.text-success {
    color: #4caf50
}

.text-info {
    color: #03a9f4
}

.nav-tabs {
    background: #01acef
}

.nav-tabs>li>a {
    color: #FFF;
    margin: 0
}

.nav-tabs>li>a:hover {
    background-color: transparent;
    border: 0
}

.nav-tabs>li>a,
.nav-tabs>li>a:focus,
.nav-tabs>li>a:hover {
    background-color: transparent;
    border: 0;
    color: #FFF;
    font-weight: 500
}

.nav-tabs>li.disabled>a,
.nav-tabs>li.disabled>a:hover {
    color: rgba(255, 255, 255, .5)
}

.popover,
.tooltip-inner {
    color: #ececec;
    line-height: 1em;
    background: rgba(101, 101, 101, .9);
    border: none;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .12), 0 1px 6px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .12), 0 1px 6px 0 rgba(0, 0, 0, .12)
}

.tooltip,
.tooltip.in {
    opacity: 1
}

.popover .arrow,
.popover .tooltip-arrow,
.tooltip .arrow,
.tooltip .tooltip-arrow {
    display: none
}

.card {
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 2px;
    color: rgba(0, 0, 0, .87);
    background: #fff;
    -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19)
}

.card .card-height-indicator {
    margin-top: 100%
}

.card .card-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.card .card-image {
    height: 60%;
    position: relative;
    overflow: hidden
}

.card .card-image img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    pointer-events: none
}

.card .card-image .card-image-headline {
    position: absolute;
    bottom: 16px;
    left: 18px;
    color: #fff;
    font-size: 2em
}

.card .card-body {
    height: 30%;
    padding: 18px
}

.card .card-footer {
    height: 10%;
    padding: 18px
}

.card .card-footer a,
.card .card-footer button {
    margin: 0!important;
    position: relative;
    bottom: 25px;
    width: auto
}

.card .card-footer a:first-child,
.card .card-footer button:first-child {
    left: -15px
}

.modal-content {
    -webkit-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, .2), 0 40px 77px 0 rgba(0, 0, 0, .22);
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, .2), 0 40px 77px 0 rgba(0, 0, 0, .22);
    border-radius: 2px;
    border: none
}

.modal-content .modal-header {
    border-bottom: none;
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 0;
    padding-left: 24px
}

.modal-content .modal-body {
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px
}

.modal-content .modal-footer {
    border-top: none;
    padding: 7px
}

.modal-content .modal-footer button {
    margin: 0;
    padding-left: 16px;
    padding-right: 16px;
    width: auto
}

.modal-content .modal-footer button.pull-left {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    left: -5px
}

.modal-content .modal-footer button+button {
    margin-bottom: 16px
}

.modal-content .modal-body+.modal-footer {
    padding-top: 0
}

.modal-backdrop {
    background: rgba(0, 0, 0, .3)
}

.panel {
    border-radius: 2px;
    border: 0;
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .12), 0 1px 6px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .12), 0 1px 6px 0 rgba(0, 0, 0, .12)
}

.panel.panel-default>.panel-heading,
.panel>.panel-heading {
    background-color: #eee
}

.panel.panel-inverse>.panel-heading {
    background-color: #3f51b5
}

.panel.panel-primary>.panel-heading {
    background-color: #01acef
}

.panel.panel-success>.panel-heading {
    background-color: #4caf50
}

.panel.panel-info>.panel-heading {
    background-color: #03a9f4
}

.panel.panel-warning>.panel-heading {
    background-color: #ff5722
}

.panel.panel-danger>.panel-heading {
    background-color: #f44336
}

[class*=panel-]>.panel-heading {
    color: rgba(255, 255, 255, .84);
    border: 0
}

.panel-default>.panel-heading,
.panel:not([class*=panel-])>.panel-heading {
    color: rgba(0, 0, 0, .87)
}

.panel-footer {
    background-color: #eee
}

hr.on-dark {
    color: #1a1a1a
}

hr.on-light {
    color: #fff
}

@media (-webkit-min-device-pixel-ratio:0.75),
(min--moz-device-pixel-ratio:0.75),
(-o-device-pixel-ratio:3/4),
(min-device-pixel-ratio:0.75),
(-o-min-device-pixel-ratio:3/4),
(min-resolution:0.75dppx),
(-webkit-min-device-pixel-ratio:1.25),
(-o-min-device-pixel-ratio:5/4),
(min-resolution:120dpi) {
    hr {
        height: .75px
    }
}

@media (-webkit-min-device-pixel-ratio:1),
(min--moz-device-pixel-ratio:1),
(-o-device-pixel-ratio:1),
(min-device-pixel-ratio:1),
(-o-min-device-pixel-ratio:1/1),
(min-resolution:1dppx),
(-webkit-min-device-pixel-ratio:1.6666666666666667),
(-o-min-device-pixel-ratio:5/3),
(min-resolution:160dpi) {
    hr {
        height: 1px
    }
}

@media (-webkit-min-device-pixel-ratio:1.33),
(min--moz-device-pixel-ratio:1.33),
(-o-device-pixel-ratio:133/100),
(min-device-pixel-ratio:1.33),
(-o-min-device-pixel-ratio:133/100),
(min-resolution:1.33dppx),
(-webkit-min-device-pixel-ratio:2.21875),
(-o-min-device-pixel-ratio:71/32),
(min-resolution:213dpi) {
    hr {
        height: 1.33px
    }
}

@media (-webkit-min-device-pixel-ratio:1.5),
(min--moz-device-pixel-ratio:1.5),
(-o-device-pixel-ratio:3/2),
(min-device-pixel-ratio:1.5),
(-o-min-device-pixel-ratio:3/2),
(min-resolution:1.5dppx),
(-webkit-min-device-pixel-ratio:2.5),
(-o-min-device-pixel-ratio:5/2),
(min-resolution:240dpi) {
    hr {
        height: 1.5px
    }
}

@media (-webkit-min-device-pixel-ratio:2),
(min--moz-device-pixel-ratio:2),
(-o-device-pixel-ratio:2/1),
(min-device-pixel-ratio:2),
(-o-min-device-pixel-ratio:2/1),
(min-resolution:2dppx),
(-webkit-min-device-pixel-ratio:3.9583333333333335),
(-o-min-device-pixel-ratio:95/24),
(min-resolution:380dpi) {
    hr {
        height: 2px
    }
}

@media (-webkit-min-device-pixel-ratio:3),
(min--moz-device-pixel-ratio:3),
(-o-device-pixel-ratio:3/1),
(min-device-pixel-ratio:3),
(-o-min-device-pixel-ratio:3/1),
(min-resolution:3dppx),
(-webkit-min-device-pixel-ratio:5),
(-o-min-device-pixel-ratio:5/1),
(min-resolution:480dpi) {
    hr {
        height: 3px
    }
}

@media (-webkit-min-device-pixel-ratio:4),
(min--moz-device-pixel-ratio:4),
(-o-device-pixel-ratio:4/1),
(min-device-pixel-ratio:3),
(-o-min-device-pixel-ratio:4/1),
(min-resolution:4dppx),
(-webkit-min-device-pixel-ratio:6.666666666666667),
(-o-min-device-pixel-ratio:20/3),
(min-resolution:640dpi) {
    hr {
        height: 4px
    }
}

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent
}

:focus {
    outline: 0
}

.snackbar {
    background-color: #323232;
    color: rgba(255, 255, 255, .84);
    font-size: 14px;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .12), 0 1px 6px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .12), 0 1px 6px 0 rgba(0, 0, 0, .12);
    height: 0;
    -webkit-transition: -webkit-transform .2s ease-in-out, opacity .2s ease-in, height 0s linear .2s, padding 0s linear .2s, height 0s linear .2s;
    -o-transition: -o-transform .2s ease-in-out, opacity .2s ease-in, height 0s linear .2s, padding 0s linear .2s, height 0s linear .2s;
    transition: transform .2s ease-in-out, opacity .2s ease-in, height 0s linear .2s, padding 0s linear .2s, height 0s linear .2s;
    -webkit-transform: translateY(200%);
    -ms-transform: translateY(200%);
    -o-transform: translateY(200%);
    transform: translateY(200%)
}

.snackbar.snackbar-opened {
    padding: 14px 15px;
    margin-bottom: 20px;
    height: auto;
    -webkit-transition: -webkit-transform .2s ease-in-out, opacity .2s ease-in, height 0s linear .2s, height 0s linear .2s;
    -o-transition: -o-transform .2s ease-in-out, opacity .2s ease-in, height 0s linear .2s, height 0s linear .2s;
    transition: transform .2s ease-in-out, opacity .2s ease-in, height 0s linear .2s, height 0s linear .2s;
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none
}

.snackbar.toast {
    border-radius: 200px
}

.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -ms-touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.noUi-base {
    width: 100%;
    height: 100%;
    position: relative
}

.noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0
}

.noUi-handle {
    position: relative;
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.noUi-stacking .noUi-handle {
    z-index: 10
}

.noUi-state-tap .noUi-origin {
    -webkit-transition: left .3s, top .3s;
    -o-transition: left .3s, top .3s;
    transition: left .3s, top .3s
}

.noUi-state-drag * {
    cursor: inherit!important
}

.noUi-horizontal {
    height: 10px
}

.noUi-handle {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    left: -10px;
    top: -5px;
    cursor: ew-resize;
    border-radius: 100%;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
    border: 1px solid
}

.noUi-vertical .noUi-handle {
    margin-left: 5px;
    cursor: ns-resize
}

.noUi-horizontal.noUi-extended {
    padding: 0 15px
}

.noUi-horizontal.noUi-extended .noUi-origin {
    right: -15px
}

.noUi-background {
    height: 2px;
    margin: 20px 0
}

.noUi-origin {
    margin: 0;
    border-radius: 0;
    height: 2px;
    background: #c8c8c8
}

.noUi-origin[style^="left: 0"] .noUi-handle {
    background-color: #fff;
    border: 2px solid #c8c8c8
}

.noUi-origin[style^="left: 0"] .noUi-handle.noUi-active {
    border-width: 1px
}

.noUi-target {
    border-radius: 2px
}

.noUi-horizontal {
    height: 2px;
    margin: 15px 0
}

.noUi-vertical {
    height: 100%;
    width: 2px;
    margin: 0 15px;
    display: inline-block
}

.noUi-handle.noUi-active {
    -webkit-transform: scale3d(2.5, 2.5, 1);
    transform: scale3d(2.5, 2.5, 1)
}

[disabled].noUi-slider {
    opacity: .5
}

[disabled] .noUi-handle {
    cursor: not-allowed
}

.slider {
    background: #c8c8c8
}

.slider.noUi-connect,
.slider.slider-default.noUi-connect {
    background-color: #01acef
}

.slider.slider-inverse.noUi-connect {
    background-color: #3f51b5
}

.slider.slider-primary.noUi-connect {
    background-color: #01acef
}

.slider.slider-success.noUi-connect {
    background-color: #4caf50
}

.slider.slider-info.noUi-connect {
    background-color: #03a9f4
}

.slider.slider-warning.noUi-connect {
    background-color: #ff5722
}

.slider.slider-danger.noUi-connect {
    background-color: #f44336
}

.slider .noUi-connect,
.slider.slider-default .noUi-connect {
    background-color: #01acef
}

.slider.slider-inverse .noUi-connect {
    background-color: #3f51b5
}

.slider.slider-primary .noUi-connect {
    background-color: #01acef
}

.slider.slider-success .noUi-connect {
    background-color: #4caf50
}

.slider.slider-info .noUi-connect {
    background-color: #03a9f4
}

.slider.slider-warning .noUi-connect {
    background-color: #ff5722
}

.slider.slider-danger .noUi-connect {
    background-color: #f44336
}

.slider .noUi-handle,
.slider.slider-default .noUi-handle {
    background-color: #01acef
}

.slider.slider-inverse .noUi-handle {
    background-color: #3f51b5
}

.slider.slider-primary .noUi-handle {
    background-color: #01acef
}

.slider.slider-success .noUi-handle {
    background-color: #4caf50
}

.slider.slider-info .noUi-handle {
    background-color: #03a9f4
}

.slider.slider-warning .noUi-handle {
    background-color: #ff5722
}

.slider.slider-danger .noUi-handle {
    background-color: #f44336
}

.slider .noUi-handle,
.slider.slider-default .noUi-handle {
    border-color: #01acef
}

.slider.slider-inverse .noUi-handle {
    border-color: #3f51b5
}

.slider.slider-primary .noUi-handle {
    border-color: #01acef
}

.slider.slider-success .noUi-handle {
    border-color: #4caf50
}

.slider.slider-info .noUi-handle {
    border-color: #03a9f4
}

.slider.slider-warning .noUi-handle {
    border-color: #ff5722
}

.slider.slider-danger .noUi-handle {
    border-color: #f44336
}

.selectize-control.multi,
.selectize-control.single {
    padding: 0
}

.selectize-control.multi .selectize-input,
.selectize-control.multi .selectize-input.input-active,
.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input.input-active {
    cursor: text;
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    padding: 0;
    height: 100%;
    font-size: 14px;
    line-height: 30px
}

.selectize-control.multi .selectize-input .has-items,
.selectize-control.multi .selectize-input.input-active .has-items,
.selectize-control.single .selectize-input .has-items,
.selectize-control.single .selectize-input.input-active .has-items {
    padding: 0
}

.selectize-control.multi .selectize-input.input-active:after,
.selectize-control.multi .selectize-input:after,
.selectize-control.single .selectize-input.input-active:after,
.selectize-control.single .selectize-input:after {
    right: 5px;
    position: absolute;
    font-size: 25px;
    content: "\e5c5";
    font-family: 'Material Icons';
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.selectize-control.multi .selectize-input input,
.selectize-control.multi .selectize-input.input-active input,
.selectize-control.single .selectize-input input,
.selectize-control.single .selectize-input.input-active input {
    font-size: 14px;
    outline: 0;
    border: 0;
    background: 0 0
}

.selectize-control.multi .selectize-input.input-active.label-floating-fix input,
.selectize-control.multi .selectize-input.label-floating-fix input,
.selectize-control.single .selectize-input.input-active.label-floating-fix input,
.selectize-control.single .selectize-input.label-floating-fix input {
    opacity: 0
}

.selectize-control.multi .selectize-input.input-active>.item,
.selectize-control.multi .selectize-input.input-active>div,
.selectize-control.multi .selectize-input>.item,
.selectize-control.multi .selectize-input>div,
.selectize-control.single .selectize-input.input-active>.item,
.selectize-control.single .selectize-input.input-active>div,
.selectize-control.single .selectize-input>.item,
.selectize-control.single .selectize-input>div {
    display: inline-block;
    margin: 0 8px 3px 0;
    padding: 0;
    background: 0 0;
    border: 0
}

.selectize-control.multi .selectize-input.input-active>.item:after,
.selectize-control.multi .selectize-input.input-active>div:after,
.selectize-control.multi .selectize-input>.item:after,
.selectize-control.multi .selectize-input>div:after,
.selectize-control.single .selectize-input.input-active>.item:after,
.selectize-control.single .selectize-input.input-active>div:after,
.selectize-control.single .selectize-input>.item:after,
.selectize-control.single .selectize-input>div:after {
    content: ","
}

.selectize-control.multi .selectize-input.input-active>.item:last-of-type:after,
.selectize-control.multi .selectize-input.input-active>div:last-of-type:after,
.selectize-control.multi .selectize-input>.item:last-of-type:after,
.selectize-control.multi .selectize-input>div:last-of-type:after,
.selectize-control.single .selectize-input.input-active>.item:last-of-type:after,
.selectize-control.single .selectize-input.input-active>div:last-of-type:after,
.selectize-control.single .selectize-input>.item:last-of-type:after,
.selectize-control.single .selectize-input>div:last-of-type:after {
    content: ""
}

.selectize-control.multi .selectize-input.input-active>.item.active,
.selectize-control.multi .selectize-input.input-active>div.active,
.selectize-control.multi .selectize-input>.item.active,
.selectize-control.multi .selectize-input>div.active,
.selectize-control.single .selectize-input.input-active>.item.active,
.selectize-control.single .selectize-input.input-active>div.active,
.selectize-control.single .selectize-input>.item.active,
.selectize-control.single .selectize-input>div.active {
    font-weight: 700;
    background: 0 0;
    border: 0
}

.selectize-control.multi .selectize-dropdown,
.selectize-control.single .selectize-dropdown {
    position: absolute;
    z-index: 1000;
    border: 0;
    width: 100%!important;
    left: 0!important;
    height: auto;
    background-color: #FFF;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    border-radius: 2px;
    padding: 0;
    margin-top: 3px
}

.selectize-control.multi .selectize-dropdown .active,
.selectize-control.single .selectize-dropdown .active {
    background-color: inherit
}

.selectize-control.multi .selectize-dropdown .highlight,
.selectize-control.single .selectize-dropdown .highlight {
    background-color: #d5d8ff
}

.selectize-control.multi .selectize-dropdown .selected,
.selectize-control.multi .selectize-dropdown .selected.active,
.selectize-control.single .selectize-dropdown .selected,
.selectize-control.single .selectize-dropdown .selected.active {
    background-color: #EEE
}

.selectize-control.multi .selectize-dropdown .optgroup-header,
.selectize-control.multi .selectize-dropdown [data-selectable],
.selectize-control.single .selectize-dropdown .optgroup-header,
.selectize-control.single .selectize-dropdown [data-selectable] {
    padding: 10px 20px;
    cursor: pointer
}

.selectize-control.multi .dropdown-active~.selectize-dropdown,
.selectize-control.single .dropdown-active~.selectize-dropdown {
    display: block
}

.dropdownjs::after {
    right: 5px;
    top: 3px;
    font-size: 25px;
    position: absolute;
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    content: "\e5c5";
    pointer-events: none;
    color: #757575
}


/*# sourceMappingURL=bootstrap-material-design.min.css.map */
