p-checkbox.truncated {
  display: flex;

  .p-checkbox-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 4px; /* Top margin to vertically center label with checkbox */
  }
}

.p-checkbox-label {
  &:hover {
    cursor: pointer;
  }
}
