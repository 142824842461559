.p-chip.p-chip-analytics {
  margin: 0;
}

.small.p-chip{
  .p-chip-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
  }
}

.p-chip.p-chip-no-wrap {
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  text-align: left;
  padding: .2rem;
  .p-chip-text {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
