.tool-tip {
    position: absolute;
    display: none;
    width: auto;
    height: auto;
    background: var(--tooltip-background-base);
    border: none;
    border-radius: var(--border-radius);
    box-shadow: var(--elevation-400);
    color: var(--tooltip-text-primary);
    font-size: 12px;
    padding: 5px;
    font-weight: bold;
    z-index: 10099;
}

.response-image {
    max-width: 100%;
    height: auto;
    width: 100%
}

/*Tool tip for pie arc styles*/

.toolTipTreePie, .circleHover {
    position: absolute;
    width: auto;
    height: auto;
    color: var(--text-primary);
    font-weight: bold;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-shadow: var(--elevation-400);
    z-index: 1;
}

/*Context menu styles*/

#contextmenu {
    display: none;
    position: absolute;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 0px 5px #888;
}

#contextmenu hr {
    border: 1px solid #ccc;
}

.list-disabled {
    pointer-events: none;
    opacity: 0.5;
}


/*Static dummy data */

.tabs {
    /*width:100%;
    display:inline-block;*/
    width: 21%;
    display: inline-block;
    position: absolute;
    left: 0%;
}

.tab {
    display: none;
}

.tab.active {
    display: block;
}


/*Tree chart styles*/

.node {
    cursor: pointer;
}

.node circle {
    fill: #fff;
}

.node text {
    font: 12px sans-serif;
}

.link {
    fill: none;
    stroke: var(--gray-800);
    stroke-width: 2px;
}


/* drop down level styles*/


.label_select {
    position: absolute;
    top: 60px;
}

.tooltip_mcompare {
    display: none;
    position: absolute;
    background: var(--tooltip-background-base);
    padding: 10px;
}

.label_tooltip {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--tooltip-text-primary);
}

.current_value, .pending_value {
    color: var(--gray-400);
}

.current_tooltip, .pending_tooltip {
    color: var(--tooltip-text-primary);
    font-size: 13px;
}

.compareModel .axis .tick line {
    opacity: 0.3;
}

.axisCompare line{
  stroke: #c8c8c8;
}

.axisCompare path{
  stroke: #c8c8c8;
}

.axisCompare text{
  fill: #c8c8c8;
}

.modal-body {
    background-color: var(--modal-background-base);
}

#update {
    color: white;
    background-color: blue;
    position: relative;
    left: 70px;
}

#gettingId, #gettingName {
    color: black;
    font-weight: bold;
    position: relative;
    left: 104px;
    top: -30px;
}

.update_tree {
    position: absolute;
    left: 100%;
    top: 45%;
}

.label_id {
    left: -70px;
    position: relative;
}

.label_name {
    position: relative;
    left: -53px;
}

.select_dragClass a {
    padding: 9px 47px;
    position: relative;
}

.tree_content {
    position: relative;
    top: -75px;
}

#dialog-model-header {
    background: var(--modal-background-base);
    border: none;
}
/*
.info_popup, .confirm_Modelpopup, .confirm_subModelpopup, .info_lowestLevelpopup, .balanced_popup {
    position: relative;
    top: 30px;
    font-size: 20px;
}*/

.info_topLevelpopup {
    position: relative;
    top: 0px;
    font-size: 20px;
}

.copy_model {
    font-size: 20px;
    text-align: center;
    top: 30px;
    position: relative;
}

.copy_text {
    color: black;
}

.popup_headerpercent {
    width: 10%;
}

.popup_input_body {
    width: 20%;
}

.custom_input {
    width: 20%;
}

.circleHover {
    display: none;
    background-color: var(--modal-background-base);
}

.closeChild:hover {
    cursor: pointer;
}


/*Group Chart*/

.tooltip_trade {
  font-size: 12px;
  position: relative;
  padding: 4px;
  width: 150px;
  background-color: var(--tooltip-background-base);
  color: var(--tooltip-text-primary);
  z-index: 8;
  border: 1px solid var(--modal-stroke-primary);
}

.GroupLegendonClick {
    color: var(--black);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    fill: var(--black);
}

.rectClik {
    fill: var(--black) !important;
}


/*Mouse hover on node shows the available children ...Styles for showing them*/

.table_nodeVal td, .table_nodeVal th {
    text-align: center;
    padding: 5px !important;
}


/*Dashed path styles */
.link_dashed {
    fill: none;
    stroke: var(--gray-800);
    stroke-dasharray: 5, 5;
}

.legendsText {
    color: var(--table-text-primary);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    fill: var(--table-text-primary);
}

.legendClickText {
    color: var(--text-primary);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    fill: var(--list-icon-primary);
}

.legendClickrect {
    fill: transparent !important;
}

.no_pending {
    font-size: 22px;
    text-align: center;
    position: relative;
    top: 150px;
    left: 0;
}


.node_label{
    text-align:left !important;
}

.groupsvg {
  color: var(--table-text-secondary);
  background-color: var(--table-background-base);
}

.graph-title-portfolio-name {
  fill: var(--table-text-primary);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
